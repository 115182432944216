export const ACCOUNT_TYPE = {
    data: {
        allAccountTypeProfiles: []
    },
    editData: {
        editDataId: '',
        editDataAccountType: '',
        editDataAccountGroup: ''
    },
    forms: {
        AccountTypeForm: {
            fields: {
                AccountType: {
                    label: "Account Type",
                    name: "AccountType",
                    placeholder: "Account Type",
                    value: [],
                    options: []
                },
                AccountGroup: {
                    label: "Account Group",
                    name: "AccountGroup",
                    placeholder: "Account Group",
                    value: ""
                }
            }
        },
        biddingIncrementForm: {
            fields: {
                ProfileId: {
                    label: "Bidding Profile Id",
                    name: "ProfileId",
                    placeholder: "Bidding Profile Id",
                    value: [],
                    options: []
                },
                EndBiddingPrice: {
                    label: "End Bidding Price",
                    name: "EndBiddingPrice",
                    placeholder: "End Bidding Price",
                    value: ""
                },
                FirstIncrement: {
                    label: "First Bidding Increment",
                    name: "FirstIncrement",
                    placeholder: "First Bidding Increment",
                    value: ""
                },
                SecondIncrement: {
                    label: "Second Bidding Increment",
                    name: "SecondIncrement",
                    placeholder: "Second Bidding Increment",
                    value: ""
                },
                StartBiddingPrice: {
                    label: "Start Bidding Price",
                    name: "StartBiddingPrice",
                    placeholder: "Start Bidding Price",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        
    }
}
export default ACCOUNT_TYPE;