import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';
class CreateSeller extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
    }

    SubmitHandler = () => {
        this.props.submitSellerProfile();
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            fields,
            addSellerResponseMsg,
            sellerAddStatus,
            isAllowed
        } = this.props;
        return (
            ls.get('AclGetSellerAlloweddata') === true &&
            isAllowed === 'allowed' ? <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Add a New Seller</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.SellerUsernameCreate.label}</span>
                                <CustomTextValidator
                                    id={fields.SellerUsernameCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.SellerUsernameCreate.placeholder}
                                    name={fields.SellerUsernameCreate.name}
                                    value={fields.SellerUsernameCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.SellerUsernameCreate.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.SellerEmailCreate.label}</span>
                                <CustomTextValidator
                                    id={fields.SellerEmailCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="email"
                                    placeholder={fields.SellerEmailCreate.placeholder}
                                    name={fields.SellerEmailCreate.name}
                                    value={fields.SellerEmailCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.SellerEmailCreate.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                {addSellerResponseMsg?
                                <Button variant="contained" color="primary" className="submitbtn" type="submit"  disabled>Add</Button>
                                :
                                <Button variant="contained" color="primary" className="submitbtn" type="submit"  >Add</Button>
                                 }

                            </Grid>
                        </Grid>
                        {addSellerResponseMsg ? (
                            <p className={classes.emailsentseller}>{sellerAddStatus}</p>
                        )
                        :
                        null
                         }
                    </ValidatorForm>
                </div>
            </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(CreateSeller);