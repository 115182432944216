import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ls from 'local-storage';

class EditSeller extends Component {
    SubmitHandler = () => {
        const {editFields } = this.props;
        ls.set('EditSellerID', editFields.editDataId)

        this.props.submitSellerEdit();
    }

    render() {

        const {
            classes,
            textFieldChangeHandler,
            sellerFields
        } = this.props;
        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Edit Seller</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{sellerFields.SellerFirstNameCreate.label}</span>
                                <CustomTextValidator
                                    id={sellerFields.SellerFirstNameCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={sellerFields.SellerFirstNameCreate.name}
                                    value={sellerFields.SellerFirstNameCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            sellerFields.SellerFirstNameCreate.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{sellerFields.SellerLastNameCreate.label}</span>
                                <CustomTextValidator
                                    id={sellerFields.SellerLastNameCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={sellerFields.SellerLastNameCreate.name}
                                    value={sellerFields.SellerLastNameCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            sellerFields.SellerLastNameCreate.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{sellerFields.SellerUsernameCreate.label}</span>
                                <CustomTextValidator
                                    id={sellerFields.SellerUsernameCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={sellerFields.SellerUsernameCreate.name}
                                    value={sellerFields.SellerUsernameCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            sellerFields.SellerUsernameCreate.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{sellerFields.SellerEmailCreate.label}</span>
                                <CustomTextValidator
                                    id={sellerFields.SellerEmailCreate.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={sellerFields.SellerEmailCreate.name}
                                    value={sellerFields.SellerEmailCreate.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            sellerFields.SellerEmailCreate.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditSeller);