import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import Alert from '@material-ui/lab/Alert';

class EditViewBidding extends Component {
    state={
        editstartBiddingPrice:'',
        editendBiddingPrice:"",
        editfirstBiddingIncrement:"",
        editsecondBiddingIncrement:""
    }
   

    SubmitHandler = () => {
        const { editstartBiddingPrice ,editendBiddingPrice,editfirstBiddingIncrement,editsecondBiddingIncrement}= this.state;
        const {
            editViewData
        } = this.props;
        
        let data ={
            startBiddingPrice :editstartBiddingPrice,
            endBiddingPrice :editendBiddingPrice,
            firstBiddingIncrement: editfirstBiddingIncrement,
            secondBiddingIncrement : editsecondBiddingIncrement,

        }

        this.props.submitViewBiddingIncrements(editViewData.editDataId,editViewData.editCurrentDataId, data);
    }

    componentDidMount = () => {
        const { editViewData, fields } = this.props;
        this.setState({
            editstartBiddingPrice: fields.StartBiddingPrice ? fields.StartBiddingPrice.value : '',
            editendBiddingPrice: fields.EndBiddingPrice ? fields.EndBiddingPrice.value : '',
            editfirstBiddingIncrement: fields.FirstIncrement ? fields.FirstIncrement.value : '',
            editsecondBiddingIncrement: fields.SecondIncrement ?  fields.SecondIncrement.value : '',
        })
    }

    textChangeHandler = (name, value) => {
        this.setState({
            [name]: value ? value.value : ""
        })
    }

    render() {

        const {
            classes,
            editViewData,
            updateViewBiddingResponseMsg,
            biddingViewUpdateStatus
        } = this.props;

        const {
            editstartBiddingPrice,
            editendBiddingPrice,
            editfirstBiddingIncrement,
            editsecondBiddingIncrement,
        } = this.state;
        
        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Edit View Bidding {editViewData.editCurrentDataId}</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>StartBiddingPrice</span>
                                <CustomTextValidator
                                    id={editstartBiddingPrice}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={editstartBiddingPrice}
                                    value={editstartBiddingPrice}
                                    onChange={value => {
                                        this.textChangeHandler('editstartBiddingPrice', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>EndBiddingPrice</span>
                                <CustomTextValidator
                                    id={editendBiddingPrice}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={editendBiddingPrice}
                                    value={editendBiddingPrice}
                                    onChange={value => {
                                        this.textChangeHandler('editendBiddingPrice', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>FirstBiddingIncrement</span>
                                <CustomTextValidator
                                    id={editfirstBiddingIncrement}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={editfirstBiddingIncrement}
                                    value={editfirstBiddingIncrement}
                                    onChange={value => {
                                        this.textChangeHandler('editfirstBiddingIncrement', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>SecondBiddingIncrement</span>
                                <CustomTextValidator
                                    id={editsecondBiddingIncrement}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    name={editsecondBiddingIncrement}
                                    value={editsecondBiddingIncrement}
                                    onChange={value => {
                                        this.textChangeHandler('editsecondBiddingIncrement', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>

                            {updateViewBiddingResponseMsg ? 
                            <Alert severity="error">{biddingViewUpdateStatus}</Alert>
                            :null}
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditViewBidding);