import { connect } from "react-redux";
import ViewAllSeller from "./component";
import instance, {
  BUYER_SELLER_URL,
  CURRENT_ENV,
} from "../../../../../api/config";
import {
  saveALLSellerProfiles,
  editSellerChangeHandler,
  saveAddSellerResponse,
} from "../actions";
import ls from "local-storage";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  allSellers: state.SELLER.data.allSellers,
  sellerAddStatus: state.SELLER.data.sellerAddStatus,
  addSellerResponseMsg: state.SELLER.data.addSellerResponseMsg,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: () => {
    instance
      .get(BUYER_SELLER_URL + "auction-seller/auction-sellers")
      .then((res) => {
        dispatch(saveALLSellerProfiles(res.data.data));
      })
      .catch((err) => {});
  },
  deleteSellerHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Seller Profile",
        "small"
      )
    );
    instance
      .delete(BUYER_SELLER_URL + "auction-seller/delete/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editSellerChangeHandler: (value, name) => {
    dispatch(editSellerChangeHandler(value, name));
  },

  resendInvitation: (username, email) => {
    let data = {
      eBayUserName: username,
      email: email,
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Resend Invitation", "small")
    );
    //todo STAGING, DEV, PROTOTYPE, LIVE
    let ENV_PROP = "LIVE";
    const ENV_PROP_LOOKUP = {
      development: "DEV",
      prototype: "PROTOTYPE",
      staging: "STAGING",
      live: "LIVE",
      production: "LIVE"
    };

    ENV_PROP = ENV_PROP_LOOKUP[CURRENT_ENV.toLowerCase()];

    instance
      .post(
        BUYER_SELLER_URL +
          `auction-seller/addSeller?env=${ENV_PROP.toUpperCase()}`,
        data
      )
      .then((res) => {
        ls.set("addsellerToken", res.data.data.sessionId);
        ls.set("addsellerEmail", res.data.data.userId);
        dispatch(saveAddSellerResponse(res.data, res.data.description));
        // window.location.replace('/user/view-seller')
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/auction-seller/auction-sellers"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllSeller);
