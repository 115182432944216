import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import EditSeller from "../EditSeller/container"
import Alert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';

toast.configure()


class ViewAllSeller extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        resendebayUsername:"",
        resendebayEmail:""
    }

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id,
            showEdit:value
        })
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue,
            editData:rowData,
            resendebayUsername:rowData[3],
            resendebayEmail:rowData[4],

        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteSellerHandler(this.state.currentId)
    }

    editHandler = (show,id,editData) => {
        this.props.editSellerChangeHandler(id,editData)
        this.setState({
            showEdit:show
        })
    }

    showEditSellerDialog = (value) => {
        this.setState({
            showEdit: value
        })
    }

    resendInvitationHandler = (username, email) => {
        this.props.resendInvitation(username,email)
    }

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    }

    render() {

        const {
            classes,
            allSellers,
            sellerAddStatus,
            addSellerResponseMsg,
            isAllowed
        } = this.props;
        const {currentId,editData,resendebayUsername,resendebayEmail}= this.state;
        const open = Boolean(this.state.anchorEl);

        const id = open ? 'simple-popover' : undefined;
        
        const columns = ["ID", "Firstname","Lastname","eBay username","Invitation Email", "eBay Email", "is Authorised", "InvitationDate","ModifiedDate",
        {
            name: "Action",
            label: "Action",
            options: {
                customBodyRender: (props, data) => {
                    let rowDataValue = data.rowData[0]
                    let rowData = data.rowData

                    return (
                        <div>
                            <Button aria-describedby={id} variant="contained" color="primary" onClick={(e)=>this.handleClick(e,rowDataValue,rowData)} >
                                Actions
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                                }}
                                transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                                }}
                            >
                                <div className={classes.actions}>
                                    <Button color="primary" variant="outlined" onClick={()=>this.editHandler(true, currentId, editData)}>Edit</Button>
                                    <Button color="primary" variant="outlined" onClick={() => this.showAlertDialogDelete(true, currentId)}>Delete</Button>
                                    <Button color="primary" variant="outlined" onClick={()=>this.resendInvitationHandler(resendebayUsername,resendebayEmail)}>Resend Invitation</Button>

                                </div>
                            </Popover>
                        </div>
                    )
                },
            }
        }
        ];

        const options = {
        // filterType: 'checkbox',
        downloadOptions: { filename: 'AllSellers.csv', separator: ";", filterOptions: { useDisplayedRowsOnly: true, useDisplayedColumnsOnly: true } }
        };

        return (
            ls.get('AclGetSellerAlloweddata') === true &&
            isAllowed === 'allowed' ? <>

            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Seller"}
                    message={"Are you sure want to delete this seller?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
             {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit Seller"}
                    message={<EditSeller />}
                    hideApplyButton={true}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showEditSellerDialog(false)}
                    closeAlertDialog={() => this.showEditSellerDialog(false)}
                    applyAlertDialog={() => this.showEditSellerDialog(false)}

                />
            }

                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                    {addSellerResponseMsg ? 
                    <Alert severity="success">{sellerAddStatus}</Alert>
                    :null}

                        <p>View all Sellers</p>
                        <MUIDataTable 
                            title={"Sellers Profiles"} 
                            data={allSellers} 
                            columns={columns} 
                            options={options}
                        />
                    </div>
                </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}
export default withStyles(styles)(ViewAllSeller);