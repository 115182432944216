import {
    EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
    EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
    BUYER_MANAGER_SAVE_ALL_HANDLER,
    EVENT_MANAGER_SAVE_ID_HANDLER,
    EVENT_BIDDING_INC_SAVE_ID_HANDLER,
    EVENT_TIMLINE_SAVE_ID_HANDLER
} from "./actions";

export const buyersReducer = (state = [], action) => {
    switch (action.type) {

        case EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            [action.key]: {
                                ...state.forms.auctionEventForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            [action.key]: {
                                ...state.forms.auctionEventForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case BUYER_MANAGER_SAVE_ALL_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allBuyers: [...action.payload]
                }
            }
        }

        case EVENT_MANAGER_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            EventTimelineProfileId: {
                                ...state.forms.auctionEventForm.fields.EventTimelineProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }


        case EVENT_TIMLINE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            EventTimelineProfileId: {
                                ...state.forms.auctionEventForm.fields.EventTimelineProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case EVENT_BIDDING_INC_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            BiddingIncProfileId: {
                                ...state.forms.auctionEventForm.fields.BiddingIncProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}