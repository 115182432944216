import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator"; 
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";


class EditTimeline extends Component {

    SubmitHandler = () => {
        const {editFields } = this.props;
        this.props.submitTimelineProfile(editFields.editDataId)
    }

    render() {
        const {
            classes,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            editFields,
            fields
        } = this.props;

        return (
            <>
                <div className={classes.editmainrapper}>
                <div className={classes.editfieldswrapper}>
                    <p>Edit Timeline Profile</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>

                       <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.TimelineProfileName.label}</span>
                                <CustomTextValidator
                                    id={fields.TimelineProfileName.name}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    placeholder={fields.TimelineProfileName.placeholder}
                                    name={fields.TimelineProfileName.name}
                                    value={fields.TimelineProfileName.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.TimelineProfileName.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.IsDynamic.label}</span>
                                <ReactSelectValidator
                                    id={fields.IsDynamic.name}
                                    name={fields.IsDynamic.name}
                                    value={fields.IsDynamic.value}
                                    placeholder={fields.IsDynamic.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.IsDynamic.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    options={fields.IsDynamic.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>
                           
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.BidsCountForPrevious.label}</span>
                                <CustomTextValidator
                                    id={fields.BidsCountForPrevious.name}
                                    fullWidth={true}
                                    type="number"
                                    placeholder={fields.BidsCountForPrevious.placeholder}
                                    name={fields.BidsCountForPrevious.name}
                                    value={fields.BidsCountForPrevious.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.BidsCountForPrevious.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.BidsCountForToNext.label}</span>
                                <CustomTextValidator
                                    id={fields.BidsCountForToNext.name}
                                    fullWidth={true}
                                    type="number"
                                    placeholder={fields.BidsCountForToNext.placeholder}
                                    name={fields.BidsCountForToNext.name}
                                    value={fields.BidsCountForToNext.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.BidsCountForToNext.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditTimeline);