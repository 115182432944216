import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import instance, {BASE_URL} from "../../../../../../api/config";
import MUIDataTable from "mui-datatables";
import ls from 'local-storage';

class ViewItemsDetail extends Component {
      state={
      }
    componentDidMount = () => {
        
    }

    textChangeHandler = (name, value) => {
        this.setState({
            [name]: value ? value.value : ""
        })
    }

    render() {
        const {
            classes,
            allViewTransactionDetails
        } = this.props;


        return (
                <div className={classes.editmainrapper}>
                <div className={classes.editfieldswrapper}>
                    <p>View Transaction Detail</p>

                    {
                        allViewTransactionDetails.map(val => {
                            return(

                       <Grid container>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Title ID</span>
                                <div className={classes.fieldsdata}>{val.event}</div>
                                </div>
                            </Grid>

                            {/* <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { paramCode === "de" ? itemsGermanfields.ItemEbayIdentifier.label : paramCode === "fr" ? itemsFrenchfields.ItemEbayIdentifier.label : "Item ebay Identifier" }</span>
                                <div className={classes.fieldsdata}>{edititemEbayIdentifier}</div>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span> { paramCode === "de" ? itemsGermanfields.TopItem.label : paramCode === "fr" ? itemsFrenchfields.TopItem.label : "Top Item" }</span>
                                <div className={classes.fieldsdata}>{edittopItem ? "true" : "false"}</div>
                                </div>
                            </Grid> */}

                            {/* new fields */}
                            {/* <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ paramCode === "de" ? itemsGermanfields.Title.label : paramCode === "fr" ? itemsFrenchfields.Title.label : "Title" }</span>
                                <div className={classes.fieldsdata}>{title}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ paramCode === "de" ? itemsGermanfields.ItemCondition.label : paramCode === "fr" ? itemsFrenchfields.ItemCondition.label : "Item Condition" }</span>
                                <div className={classes.fieldsdata}>{itemCondition}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ paramCode === "de" ? itemsGermanfields.ShortDescription.label : paramCode === "fr" ? itemsFrenchfields.ShortDescription.label : "Short Description" }</span>
                                <div className={classes.fieldsdata}>{shortDescription}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ paramCode === "de" ? itemsGermanfields.Color.label : paramCode === "fr" ? itemsFrenchfields.Color.label : "Color" }</span>
                                <div className={classes.fieldsdata}>{color}</div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{ paramCode === "de" ? itemsGermanfields.Brand.label : paramCode === "fr" ? itemsFrenchfields.Brand.label : "Brand" }</span>
                                <div className={classes.fieldsdata}>{brand}</div>
                                </div>
                            </Grid> */}
                        </Grid>

                        )
                    })
                }
                </div>

                {/* <div>
                    <MUIDataTable 
                        title={ paramCode === "de" ? itemsGermanfields.ShippingLocations.label : paramCode === "fr" ? itemsFrenchfields.ShippingLocations.label : "Shipping Locations" } 
                        data={this.state.shipLocations} 
                        columns={["ID", "Region Name"]}
                    />

                    <MUIDataTable 
                        title={ paramCode === "de" ? itemsGermanfields.ShippingOptions.label : paramCode === "fr" ? itemsFrenchfields.ShippingOptions.label : "Shipping Options" }
                        data={this.state.shippingOptions} 
                        columns={["ID", "Type"]}
                    />
                </div> */}
            </div>
            
        )
    }
}

export default withStyles(styles)(ViewItemsDetail);