import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, { USER_URL } from "../../../../../../api/config";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../../common/ReduxBased/confirmationDialog/container";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

class ViewAllIncrements extends Component {
  state = {
    allIncrements: [],
    show: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  deleteProfileHandler = (id) => {
    this.setState({
      showDelete: false,
    });

    const stateCurrentId = this.state.currentId;
    const propsCurrentId = this.props.currentId;

    handleLoadingSpinnerOpenWithOptions(
      true,
      "Deleting Timeline Profile",
      "small"
    );
    instance
      .delete(
        USER_URL +
          "timeline-profile/timeline/remove/" +
          stateCurrentId +
          "/" +
          propsCurrentId
      )
      .then((res) => {
        handleLoadingSpinnerClose();
        window.location.reload();
      })
      .catch((err) => {
        handleLoadingSpinnerClose();
      });
  };

  componentDidMount = () => {
    instance
      .get(
        USER_URL +
          "bidding-increment-profile/bidding-increment-profiles/" +
          this.props.currentId +
          "/biddingIncrements"
      )
      .then((res) => {
        let newArray = res.data.data.map((item) => {
          return [
            item.id,
            item.startBiddingPrice,
            item.endBiddingPrice,
            item.firstBiddingIncrement,
            item.secondBiddingIncrement,
          ];
        });
        this.setState({
          allIncrements: [...newArray],
        });
      })
      .catch((err) => {});
  };

  viewTimelines = (value) => {};

  render() {
    const { classes, currentId } = this.props;

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    const columns = [
      "ID",
      "Starting Price",
      "Ending Price",
      "First Increment",
      "Second Increment",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={this.handleClick}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showAlertDialogDelete(true, data.rowData[0])
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {};

    return (
      <>
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Profile"}
            message={"Are you sure want to delete this profile?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View all Timeline for profile id: {currentId}</p>
            <NavLink
              to={"/user/create-biddinginc"}
              className={classes.createlink}
            >
              Create Increment
            </NavLink>
            <MUIDataTable
              title={"Timeline Profiles"}
              data={this.state.allIncrements}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ViewAllIncrements);
