export const USER_LANGUAGES = {
    data: {
        langauges: [],
    },
    forms: {
        languageForm: {
            fields: {
                Language: {
                    label: "Language Name",
                    name: "Language",
                    placeholder: "Language Name",
                    value: ""
                },
                LanguageCode: {
                    label: "Language Code",
                    name: "LanguageCode",
                    placeholder: "Language Code",
                    value: ""
                },
            }
        },
    },
    UISettings: {
    }
}
export default USER_LANGUAGES;