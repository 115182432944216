export const ACCOUNTS = {
    data: {
        allAccountsProfiles: []
    },
    editData: {
        editDataId: '',
        editDataFirstName: '',
        editDataLastName: '',
        editDataActivationToken: ''
    },
    forms: {
        AccountForm: {
            fields: {
                FirstName: {
                    label: "First Name",
                    name: "FirstName",
                    placeholder: "First Name",
                    value: ""
                },
                LastName: {
                    label: "Last Name",
                    name: "LastName",
                    placeholder: "Last Name",
                    value: ""
                },
                ActivationToken: {
                    label: "Activation Token",
                    name: "ActivationToken",
                    placeholder: "Activation Token",
                    value: ""
                },
                AccountType: {
                    label: "Account Type",
                    name: "AccountType",
                    placeholder: "Account Type",
                    value: [],
                    options: []
                },
                IsActive: {
                    label: "Is Active",
                    name: "IsActive",
                    placeholder: "Is Active",
                    value: [],
                    options: [
                        {value: 1, label: "true"},
                        {value: 0, label: "false"}
                    ]
                },
                IsDeleted: {
                    label: "Is Deleted",
                    name: "IsDeleted",
                    placeholder: "Is Deleted",
                    value: [],
                    options: [
                        {value: 1, label: "true"},
                        {value: 0, label: "false"}
                    ]
                }
            }
        },
        biddingIncrementForm: {
            fields: {
                ProfileId: {
                    label: "Bidding Profile Id",
                    name: "ProfileId",
                    placeholder: "Bidding Profile Id",
                    value: [],
                    options: []
                },
                EndBiddingPrice: {
                    label: "End Bidding Price",
                    name: "EndBiddingPrice",
                    placeholder: "End Bidding Price",
                    value: ""
                },
                FirstIncrement: {
                    label: "First Bidding Increment",
                    name: "FirstIncrement",
                    placeholder: "First Bidding Increment",
                    value: ""
                },
                SecondIncrement: {
                    label: "Second Bidding Increment",
                    name: "SecondIncrement",
                    placeholder: "Second Bidding Increment",
                    value: ""
                },
                StartBiddingPrice: {
                    label: "Start Bidding Price",
                    name: "StartBiddingPrice",
                    placeholder: "Start Bidding Price",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        
    }
}
export default ACCOUNTS;