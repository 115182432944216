import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ViewItems from "./ViewItems/container"
import ErrorMessage from "../../common/ErrorMessage/component";

class ViewAllBuyers extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null
    }

    handleClick = (event,dataValue) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteProfileHandler(this.state.currentId)
    }

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    }

    render() {

        const {
            classes,
            allBuyers,
            isAllowed
        } = this.props;
        
        const columns = ["eBay User Name", "First Name", "Last Name", "Email", "Identifier",];

        const options = {
        // filterType: 'checkbox',
        downloadOptions: { filename: 'Buyers.csv', separator: ";", filterOptions: { useDisplayedRowsOnly: true, useDisplayedColumnsOnly: true } }
        };

        return (
            isAllowed === 'allowed' ? <>

            {
                this.state.show &&
                <AlertDialog
                    maxWidth={"lg"}
                    open={this.state.show}
                    showTitle={false}
                    hideApplyButton={true}
                    message={<ViewItems currentId={this.state.currentId} showAlertDialog={this.showAlertDialog} />}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialog(false)}
                    closeAlertDialog={() => this.showAlertDialog(false)}
                    applyAlertDialog={() => this.showAlertDialog(false)}
                />
            }
            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Profile"}
                    message={"Are you sure want to delete this profile?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>

                        {/* <NavLink
                            to={'/user/create-items'}
                            className={classes.createlink}
                        >
                            Add Items
                        </NavLink> */}

                        <p>View all Buyers</p>
                        <MUIDataTable 
                            title={"All Buyers"} 
                            data={allBuyers} 
                            columns={columns} 
                            options={options}
                        />
                    </div>
                </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(ViewAllBuyers);