import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';

class CreateAccountType extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.getAccountTypeIDs();
    }

    SubmitHandler = () => {
        this.props.submitAccountType();
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            fields,
            isAllowed
        } = this.props;
        
        return (
            ls.get('AclGetUserAlloweddata') === true &&
            isAllowed === 'allowed' ? <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Create a New Account Type</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>

                       <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountType.label}</span>
                                <CustomTextValidator
                                    id={fields.AccountType.name}
                                    name={fields.AccountType.name}
                                    value={fields.AccountType.value}
                                    placeholder={fields.AccountType.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.AccountType.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountGroup.label}</span>
                                <CustomTextValidator
                                    id={fields.AccountGroup.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.AccountGroup.placeholder}
                                    name={fields.AccountGroup.name}
                                    value={fields.AccountGroup.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.AccountGroup.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Add</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(CreateAccountType);