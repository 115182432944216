import { connect } from "react-redux";
import CreateControllerProfile from "./component";
import {
  textFieldChangeHandler,
  selectFieldChangeHandler,
  saveControllerItemEventIds,
  saveAddResponseMsg,
} from "../actions";
import instance, { EVENT_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import moment from "moment";

const mapStateToProps = (state) => ({
  fields: state.CONTROLLER.forms.ItemsForm.fields,
  addItemResponseMsg: state.CONTROLLER.data.addItemResponseMsg,
  itemAddStatus: state.CONTROLLER.data.itemAddStatus,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler(name, e));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
  getEventIDs: () => {
    let perData = {
      params: {
        languageCode: "en",
      },
    };

    instance
      .get(EVENT_URL + "auction-event/auction-events", perData)
      .then((res) => {
        dispatch(saveControllerItemEventIds(res.data.data));
      })
      .catch((error) => {});
  },
  submitItems: async (
    ItemName,
    EventId,
    TopItem,
    Price,
    PublishTime,
    ItemEbayIdentifier,
    ItemDescription,
    IsActive,
    LosNr,
    MarketValueEstimate,
    MarketValueEstimateFrontend,
    MarketPlace,
    MinimumPrice,
    MinimumPriceCheck,
    AuthenticityGuarantee
  ) => {
    dispatch(handleLoadingSpinnerOpenWithOptions(true, "Saving Item", "small"));
    let dtPublishTimeFormat = moment(PublishTime.value).valueOf();
    let data = {
      itemName: ItemName ? ItemName.value : "",
      eventId: EventId.value[0] ? EventId.value[0].value : "",
      topItem: TopItem.value ? TopItem.value[0].value : 0,
      startingPrice: Price ? Price.value : "",
      publishingTimeStamp: PublishTime ? dtPublishTimeFormat : "",
      itemEbayIdentifier: ItemEbayIdentifier ? ItemEbayIdentifier.value : "",
      additionalItemDescription: ItemDescription ? ItemDescription.value : "",
      isActive: IsActive.value.length ? IsActive.value[0].value : "",
      losNr: LosNr ? LosNr.value : "",
      marketValueEstimate: MarketValueEstimate ? MarketValueEstimate.value : "",
      marketValueEstimateFrontend: MarketValueEstimateFrontend ? MarketValueEstimateFrontend.value : "",
      minimumPrice: MinimumPrice ? MinimumPrice.value : "",
      marketValueEstimateFrontend: MarketValueEstimateFrontend ? MarketValueEstimateFrontend.value : "",
      minimumPriceCheck: MinimumPriceCheck.value.length
        ? MinimumPriceCheck.value[0].value
        : "",
      authenticityGuarantee: AuthenticityGuarantee.value.length
        ? AuthenticityGuarantee.value[0].value
        : "",
      marketPlace: MarketPlace.value.length ? MarketPlace.value[0].value : "",
      languageCode: MarketPlace.value.length ? MarketPlace.value[0].name : "",
    };

    await instance
      .post(EVENT_URL + "auction-event/auction-event-items/create", data)
      .then((res) => {
        window.location.replace("/user/all-events");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(saveAddResponseMsg(error, error.response.data.description));
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitItems: () => {
    dispatchProps.submitItems(
      stateProps.fields.ItemName,
      stateProps.fields.EventId,
      stateProps.fields.TopItem,
      stateProps.fields.Price,
      stateProps.fields.PublishTime,
      stateProps.fields.ItemEbayIdentifier,
      stateProps.fields.ItemDescription,
      stateProps.fields.IsActive,
      stateProps.fields.LosNr,
      stateProps.fields.MarketValueEstimate,
      stateProps.fields.MarketValueEstimateFrontend,
      stateProps.fields.MarketPlace,
      stateProps.fields.MinimumPrice,
      stateProps.fields.MinimumPriceCheck,
      stateProps.fields.AuthenticityGuarantee
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateControllerProfile);
