import { connect } from "react-redux";
import ViewAllLoginReports from "./component";
import instance, { BUYER_SELLER_URL } from "../../../../../api/config";
import { saveAllItemReports } from "../actions";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  allItemReports: state.REPORTS.data.allItemReports,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Loading Report...", "small")
    );

    let data = {
      params: {
        reportType: "ITEM",
        page: 0,
        size: 0,
      },
    };

    instance
      .get(BUYER_SELLER_URL + "reports", data)
      .then((res) => {
        dispatch(saveAllItemReports(res.data.reports));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/auction-event/auction-events"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllLoginReports);
