import { connect } from "react-redux";
import EditAccount from "./component";
import {
    textUpdateAccount,
    UpdateAccountProfile
} from "../actions";

const mapStateToProps = state => ({
    editFields: state.ACCOUNTS.editData,
    fields: state.ACCOUNTS.forms.AccountForm.fields
})

const mapDispatchToProps = (dispatch, props) => ({
    textUpdateTimelineProfile: (name, e) => {
        dispatch(textUpdateAccount(name, e));
    },
  
    UpdateAccountProfile: (id,data) => {
        dispatch(UpdateAccountProfile(id, data));

    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitTimelines: () => {
        dispatchProps.submitTimelines(
            stateProps.fields.FirstName,
            stateProps.fields.LastName,
            stateProps.fields.ActivationToken
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditAccount);