import instance, { USER_URL } from "../../../../api/config";

export const ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER =
  "ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const ACCOUNT_TYPE_PAGE_SELECT_CHANGE_HANDLER =
  "BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER";
export const ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER =
  "ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER";
export const SET_UPDATE_ACCOUNT_TYPE_PROFILE =
  "SET_UPDATE_ACCOUNT_TYPE_PROFILE";
export const ACCOUNT_TYPE_EDIT_TEXT_CHANGE_HANDLER =
  "ACCOUNT_TYPE_EDIT_TEXT_CHANGE_HANDLER";

export const PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES =
  "PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textFieldChangeHandler_I = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: ACCOUNT_TYPE_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const saveAllAccountTypes = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [
      item.id,
      item.name,
      item.accountGroup,
      item.createdDate,
      item.modifiedDate,
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES,
    payload: newArray,
  });
};

export const saveAccountTypeIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.coreAccountType };
  });
  dispatch({
    type: ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER,
    payload: newValue,
  });
};

export const editAccountTypeChangeHandler = (value, name) => (dispatch) => {
  dispatch({
    type: ACCOUNT_TYPE_EDIT_TEXT_CHANGE_HANDLER,
    payloadAccountType: name[1],
    payloadAccountGroup: name[2],
    key: value,
  });
};

export const textUpdateAccountType = (name, value) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_ACCOUNT_TYPE_PROFILE,
    payload: value,
    key: name,
  });
};

export const UpdateAccountTypeProfile = (id, data) => (dispatch) => {
  instance
    .put(USER_URL + "core-account-types/updateAccountType/" + id, data)
    .then((res) => {
      window.location.replace("/user/all-accounttype");
    })
    .catch((error) => {});
};
