import { connect } from "react-redux";
import CreateAccount from "./component";

import {
  textFieldChangeHandler,
  selectFieldChangeHandler,
  saveAccountTypeIds,
} from "../actions";

import instance, { USER_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  fields: state.ACCOUNTS.forms.AccountForm.fields,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler(name, e));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
  getAccountTypeIDs: () => {
    instance
      .get(USER_URL + "core-account-types/getAccountTypes")
      .then((res) => {
        dispatch(saveAccountTypeIds(res.data.data));
      })
      .catch((error) => {});
  },
  submitAccountType: (
    FirstName,
    LastName,
    ActivationToken,
    AccountType,
    IsActive,
    IsDeleted
  ) => {
    let data = {
      firstName: FirstName ? FirstName.value : "",
      lastName: LastName ? LastName.value : "",
      activationToken: ActivationToken ? ActivationToken.value : "",
      coreAccountTypeId: AccountType.value[0] ? AccountType.value[0].value : "",
      coreAccountType: AccountType.value[0] ? AccountType.value[0].label : "",
      isActive: IsActive.value ? IsActive.value[0].label : 0,
      isDeleted: IsDeleted.value ? IsDeleted.value[0].label : 0,
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Saving Account...", "small")
    );

    instance
      .post(USER_URL + "account-management/addAccount", data)
      .then((res) => {
        window.location.replace("/user/all-accounts");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/account-management/addAccount"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitAccountType: () => {
    dispatchProps.submitAccountType(
      stateProps.fields.FirstName,
      stateProps.fields.LastName,
      stateProps.fields.ActivationToken,
      stateProps.fields.AccountType,
      stateProps.fields.IsActive,
      stateProps.fields.IsDeleted
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateAccount);
