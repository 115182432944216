import React, {Component} from 'react';
import { styles } from "./styles";
import { Button, Grid } from "@material-ui/core";
// // import ViewAllIncrements from "./AllIncrements/container";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ls from 'local-storage';

import { withStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
class ViewSeller extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        open: true
    }
    handleClickOpen = () => {
        this.setState({open: true})
    };
    // handleClose = () => {
    //     // this.setState({open: false})
    // };

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue,
            editData:rowData
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
            open: false
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteSellerHandler(this.state.currentId)
    }

    componentDidMount = () => {
        Params: {
            // let queryParams = this.props.location.search
            const username = new URLSearchParams(this.props.location.search).get("username")

            console.log(username, "username")
            ls.set('username', username)
        }
        this.props.fetchToken();
    }
    

    render() {

        const {
            classes,
            fetchTokenStatus
        } = this.props;

        return (
            <>

            {
            fetchTokenStatus === "" ? "" :
            
                <div main_body className={classes.main_body}>
                    <Dialog aria-labelledby="customized-dialog-title" open={this.state.open} hideBackdrop={true}
                    PaperProps={{
                    style: {
                        backgroundColor: '#0B414C',
                        boxShadow: 'none',
                        borderRadius: "40px",
                        padding: "25px",
                        width: "672px",
                        textAlign: "center"
                    },
                    }}
                    >
                        {/* <DialogTitle id="customized-dialog-title">
                        Seller Authorization
                        </DialogTitle> */}
                        <Typography id="customized-dialog-title" gutterBottom className={classes.gutterBottom}>
                            {fetchTokenStatus === "Seller Authorization Successful" ? fetchTokenStatus : <>Something went wrong! <br /> The authorization was not successful.</>}
                        </Typography>
                        <Typography gutterBottom2 className={classes.gutterBottom2}>
                            {fetchTokenStatus === "Seller Authorization Successful" ? "Thank you for registering as a retailer on (Live By eBay). This window can be closed." : "Please contact eBay Support so that a new authorization link can be created."}
                        </Typography>
                    </Dialog>
                </div>
            
            }

    
            
            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Seller"}
                    message={"Are you sure want to delete this seller?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
            </>
        )
    }
}
export default withStyles(styles)(ViewSeller);