import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, { EVENT_URL } from "../../../../../../api/config";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../../common/ReduxBased/confirmationDialog/container";
import EditViewTimeline from "../EditViewTimeline/container";
import ErrorMessage from "../../../common/ErrorMessage/component";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

class ViewAllTimelines extends Component {
  state = {
    alltimelines: [],
    show: false,
    showDelete: false,
    currentID: "",
    anchorEl: null,
    viewCurrentId: "",
  };

  handleClick = (event, dataValue, rowDataViewIDValue) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentID: dataValue,
      editData: rowDataViewIDValue,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  editHandler = (show, id, current_id, editData) => {
    this.props.editViewTimelineChangeHandler(id, current_id, editData);
    this.setState({
      showEdit: show,
    });
  };

  deleteProfileHandler = (id) => {
    this.setState({
      showDelete: false,
    });

    const stateCurrentId = this.state.currentId;
    const propsCurrentId = this.props.currentId;

    handleLoadingSpinnerOpenWithOptions(
      true,
      "Deleting Timeline Profile",
      "small"
    );
    instance
      .delete(
        EVENT_URL +
          "timeline-profile/timeline/remove/" +
          stateCurrentId +
          "/" +
          propsCurrentId
      )
      .then((res) => {
        handleLoadingSpinnerClose();
        window.location.reload();
      })
      .catch((err) => {
        handleLoadingSpinnerClose();
      });
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    instance
      .get(
        EVENT_URL +
          "timeline-profile/timeline-profiles/" +
          this.props.currentId +
          "/timelines"
      )
      .then((res) => {
        let newArray = res.data.data.map((item) => {
          return [
            item.id,
            item.bidStart,
            item.bidEnd,
            item.durationFirstRound,
            item.durationSecondRound,
            item.durationThirdRound,
          ];
        });
        this.setState({
          alltimelines: [...newArray],
        });
      })
      .catch((err) => {});
  };

  viewTimelines = (value) => {};

  showEditViewTimelineDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  render() {
    const { classes, currentId, isAllowed } = this.props;
    const { editData } = this.state;

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    const columns = [
      "Timline ID",
      "Bid Start",
      "Bid End",
      "First Round",
      "Second Round",
      "Third Round",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            // let rowDataViewIDValue = data.rowData[5]
            let rowData = data.rowData;
            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.editHandler(
                          true,
                          currentId,
                          this.state.currentID,
                          editData
                        )
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showAlertDialogDelete(true, this.state.currentID)
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {};

    return isAllowed === "allowed" ? (
      <>
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Timeline"}
            message={"Are you sure want to delete this timeline?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}
        {this.state.showEdit && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showEdit}
            showTitle={true}
            title={"Edit Timeline"}
            message={<EditViewTimeline />}
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={() => this.showEditViewTimelineDialog(false)}
            closeAlertDialog={() => this.showEditViewTimelineDialog(false)}
            applyAlertDialog={() => this.showEditViewTimelineDialog(false)}
          />
        )}
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View all Timeline for profile id: {currentId}</p>
            <NavLink
              to={"/user/create-timeline"}
              className={classes.createlink}
            >
              Create Timeline
            </NavLink>
            <MUIDataTable
              title={"Timeline Profiles"}
              data={this.state.alltimelines}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        {" "}
        {isAllowed === "loading" ? (
          <ErrorMessage message="loading" />
        ) : (
          <ErrorMessage message="You are not allowed to see this page" />
        )}
      </>
    );
  }
}

export default withStyles(styles)(ViewAllTimelines);
