import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from '@material-ui/icons/Person';
import CreateIcon from '@material-ui/icons/Create';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { NavLink } from "react-router-dom";
import ls from 'local-storage';

class DashboardLanding extends Component {

    componentDidMount = () => {
        this.props.fetchDataIfNeeded();
        this.props.dashboardData();
    }
    
    render() {
        const {
            classes,
            dashboardCount,
            dashboardDataCount
        } = this.props;

        return (
            <div className={classes.dashboardwrapper}>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <PeopleIcon />
                            <h1>{dashboardCount.eventsCount && ls.get('AclGetEventAlloweddata') === true ? dashboardCount.eventsCount : '0'}</h1>
                            <span>Total Events</span>
                            <NavLink
                                to={'/user/all-events'}
                            >
                                View All
                            </NavLink>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <BusinessIcon />
                            <h1>{dashboardCount.eventTimelinesCount && ls.get('AclGetEventAlloweddata') === true ? dashboardCount.eventTimelinesCount : '0'}</h1>
                            <span>Timelines</span>
                            <NavLink
                                to={'/user/all-profiletimeline'}
                            >
                                View All
                            </NavLink>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <AccountTreeIcon />
                            <h1>{dashboardCount.biddingProfileCount && ls.get('AclGetEventAlloweddata') === true ? dashboardCount.biddingProfileCount : '0'}</h1>
                            <span>Bidding</span>
                            <NavLink
                                to={'/user/all-profilebidding'}
                            >
                                View All
                            </NavLink>
                        </div>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <PersonIcon />
                            <h1>{dashboardDataCount.liveAuctionEventId && ls.get('AclGetEventAlloweddata') === true ? dashboardDataCount.liveAuctionEventId : '0'}</h1>
                            <span>Latest Event ID</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <CreateIcon />
                            <h1>{dashboardDataCount.eventName && ls.get('AclGetEventAlloweddata') === true ? dashboardDataCount.eventName : '0'}</h1>
                            <span>Latest Event Name</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <ArrowUpwardIcon />
                            <h1>{dashboardDataCount.totalTransaction && ls.get('AclGetEventAlloweddata') === true ? dashboardDataCount.totalTransaction : '0'}</h1>
                            <span>Total Transaction</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <TransferWithinAStationIcon />
                            <h1>{dashboardDataCount.revenueGenerated && ls.get('AclGetEventAlloweddata') === true ? dashboardDataCount.revenueGenerated : '0'}</h1>
                            <span>Revenue Generated</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <ReceiptIcon />
                            <h1>{dashboardDataCount.totalBids && ls.get('AclGetEventAlloweddata') === true ? dashboardDataCount.totalBids : '0'}</h1>
                            <span>Total Bids</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <AccessTimeIcon />
                            <h1>{dashboardDataCount.auctionDuration && ls.get('AclGetEventAlloweddata') === true ? dashboardDataCount.auctionDuration : '0'}</h1>
                            <span>Auction Duration</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <div className={classes.dashboardboxwrapper}>
                            <AccountCircleIcon />
                            <h1>{dashboardDataCount.totalLoggedInUsers && ls.get('AclGetEventAlloweddata') === true ? dashboardDataCount.totalLoggedInUsers : '0'}</h1>
                            <span>Total LoggedIn Users</span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(DashboardLanding);