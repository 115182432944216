import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popover,
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  ListItemText,
  OutlinedInput,
  Grid,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { NavLink } from "react-router-dom";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ErrorMessage from "../../common/ErrorMessage/component";
import ViewItemsDetail from "./ViewItemDetail/container";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";

class ViewAllTransactionReports extends Component {
  state = {
    alltimelines: [],
    show: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    languageField: {
      name: "Language Field",
      placeholder: "Select Language",
      label: "Select Language",
      options: [],
      value: [],
    },
    ageFilterChecked: false,
    purchaseFilterChecked: false,
    dataSize: 10,
    dataPage: 0,
    dropdownStatus: "all",
    allNewTransactionReports: [],
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.fetchDataIfNeeded(
      this.state.dataSize,
      this.state.dataPage,
      this.state.dropdownStatus
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dataSize !== this.state.dataSize ||
      prevState.dataPage !== this.state.dataPage ||
      prevState.dropdownStatus !== this.state.dropdownStatus
    ) {
      this.props.fetchDataIfNeeded(
        this.state.dataSize,
        this.state.dataPage,
        this.state.dropdownStatus
      );
      this.setState({
        allNewTransactionReports: this.props.allTransactionReports,
      });
    }
  }

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentID: dataValue,
      editData: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
    });
  };

  editHandler = (show, id, current_id, editData) => {
    this.props.editViewItemChangeHandler(id, current_id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showItemDetail = (show, id, current_id, editData) => {
    this.props.editViewItemChangeHandler(id, current_id, editData);
    this.setState({
      showItemDetail: show,
    });
  };

  showViewItemDetailDialog = (value) => {
    this.setState({
      showItemDetail: value,
    });
  };

  redoHandler = (item, eventId, data) => {
    const itemId = item.slice(3, 15);
    this.props.editRedoChangeHandler(itemId, eventId);
  };

  transactionstatus = (value) => {
    this.setState({
      dropdownStatus: value ? value.name : "",
    });
  };

  SubmitHandler = (value) => {};

  render() {
    const {
      classes,
      allTransactionReports,
      isAllowed,
      currentId,
      redoResTransaction,
      fields,
      selectFieldChangeHandler,
    } = this.props;

    const { editData, languageField } = this.state;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    const filterWinning = allTransactionReports.filter((val) => val[19]);
    const filterPurchase = allTransactionReports.filter((val) => val[20]);

    const mapWinningDateTime = filterWinning.map((val) => {
      return val[19];
    });
    const sortWinningDateTime = mapWinningDateTime.sort((a, b) => a - b);
    const sortWinningDate = sortWinningDateTime.map((item) => {
      return moment(new Date(item))
        .utcOffset("+0700")
        .format("DD.MM.YYYY HH:mm:ss");
    });

    const mapPurchaseDateTime = filterPurchase.map((val) => {
      return val[20];
    });
    const sortPurchaseDateTime = mapPurchaseDateTime.sort((a, b) => a - b);
    const sortPurchaseDate = sortPurchaseDateTime.map((item) => {
      return moment(new Date(item))
        .utcOffset("+0700")
        .format("DD.MM.YYYY HH:mm:ss");
    });

    const columns = [
      "Item ID",
      "eBay Transaction ID",
      "eBay Buyer ID",
      "Buyer User Name",
      "Checkout Status",
      "Order Status",
      "Cancel Status",
      "Seller Ebay Name",
      "Seller Email",
      "Item Position",
      "LA Event ID",
      "Event Name",
      "LA Buyer ID",
      "Best Offer ID",
      "Best Offer Status",
      "eBay API  Response",
      "Final Auction Price",
      "Currency",
      "User Agent",

      {
        name: "Winning Time MST",
        label: "Winning Time MST",
        options: {
          filter: true,
          filterType: "custom",

          customBodyRender: (value) => {
            let newValue = "";
            if (value) {
              newValue = moment(new Date(value))
                .utcOffset("+0700")
                .format("DD.MM.YYYY HH:mm:ss");
            }
            return newValue;
          },

          // if the below value is set, these values will be used every time the table is rendered.
          // it's best to let the table internally manage the filterList
          //filterList: [25, 50],

          customFilterListOptions: {
            render: (v) => {
              if (v[0]) {
                return `Winning Time MST: ${v[0]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              if (filters[0]) {
                return [date] < filters[0] || [date] > filters[0];
              } else {
                return false;
              }
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Winning Time MST</FormLabel>
                <br />

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth={true}
                  value={
                    filterList[index][0] ? (
                      filterList[index][0]
                    ) : (
                      <div style={{ color: "gray" }}>
                        Select Winning Time MST
                      </div>
                    )
                  }
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  input={<OutlinedInput id="" />}
                  displayEmpty={true}
                  renderValue={(selected) =>
                    selected == "" ? (
                      <div style={{ color: "gray" }}>Winning Time MST</div>
                    ) : (
                      selected
                    )
                  }
                >
                  {sortWinningDate.map((date) => (
                    <MenuItem key={date} value={date}>
                      <ListItemText primary={date} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ),
          },
          print: false,
        },
      },

      {
        name: "Purchase Time MST",
        label: "Purchase Time MST",
        options: {
          filter: true,
          filterType: "custom",

          customBodyRender: (value) => {
            let newValue = "";
            if (value) {
              newValue = moment(new Date(value))
                .utcOffset("+0700")
                .format("DD.MM.YYYY HH:mm:ss");
            }
            return newValue;
          },

          // if the below value is set, these values will be used every time the table is rendered.
          // it's best to let the table internally manage the filterList
          //filterList: [25, 50],

          customFilterListOptions: {
            render: (v) => {
              if (v[0]) {
                return `Purchase Time MST: ${v[0]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              if (filters[0]) {
                return [date] < filters[0] || [date] > filters[0];
              } else {
                return false;
              }
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Purchase Time MST</FormLabel>
                <br />

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth={true}
                  value={
                    filterList[index][0] ? (
                      filterList[index][0]
                    ) : (
                      <div style={{ color: "gray" }}>
                        Select Purchase Time MST
                      </div>
                    )
                  }
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  input={<OutlinedInput id="" />}
                  displayEmpty={true}
                  renderValue={(selected) =>
                    selected == "" ? (
                      <div style={{ color: "gray" }}>Purchase Time MST</div>
                    ) : (
                      selected
                    )
                  }
                >
                  {sortPurchaseDate.map((date) => (
                    <MenuItem key={date} value={date}>
                      <ListItemText primary={date} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ),
          },
          print: false,
        },
      },

      {
        name: "Actions",
        label: "Actions",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            let redoValue = data.rowData[21];
            return (
              <div>
                {redoValue === true ? (
                  <Button
                    aria-describedby={id}
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      this.redoHandler(rowDataValue, data.rowData[10], rowData)
                    }
                  >
                    Redo
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          },
        },
      },
    ];

    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "standard",
      fixedHeader: true,
      fixedSelectColumn: false,
      width: "400px",
      jumpToPage: true,
      serverSide: true,
      count: +this.props.totalCountsTransactions,
      rowsPerPageOptions: [10, 20, 50, 100],
      onChangeRowsPerPage: (num) => {
        this.setState({
          dataSize: num,
        });
      },
      onChangePage: (num) => {
        this.setState({
          dataPage: num,
        });
      },
      downloadOptions: {
        filename: "TransactionReport.csv",
        separator: ";",
        filterOptions: {
          useDisplayedRowsOnly: true,
          useDisplayedColumnsOnly: true,
        },
      },
      sortOrder: {
        name: "Purchase Time MST",
        direction: "asc",
      },
    };

    return isAllowed === "allowed" ? (
      <>
        {this.state.showItemDetail && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showItemDetail}
            showTitle={true}
            title={"View Transaction Detail"}
            message={<ViewItemsDetail />}
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={() => this.showViewItemDetailDialog(false)}
            closeAlertDialog={() => this.showViewItemDetailDialog(false)}
            applyAlertDialog={() => this.showViewItemDetailDialog(false)}
          />
        )}

        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
              <div className={classes.selectLang}>
                <Grid item xs={12} md={12}>
                  <div className="fieldwrapper">
                    {/* <span>{fields.EventLanguages.label}</span> */}
                    <ReactSelectValidator
                      id={fields.Transaction.name}
                      name={fields.Transaction.name}
                      value={fields.Transaction.value}
                      placeholder={fields.Transaction.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.Transaction.name,
                          value
                        );
                        this.transactionstatus(value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.Transaction.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>
              </div>
            </ValidatorForm>

            <p>View all Transaction Report</p>
            <MUIDataTable
              title={"Transaction Reports"}
              data={allTransactionReports}
              columns={columns}
              options={options}
            />
            <br />
            {redoResTransaction !== "" ? (
              <Alert severity="success">{redoResTransaction[0]}</Alert>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    ) : (
      <>
        {" "}
        {isAllowed === "loading" ? (
          <ErrorMessage message="loading" />
        ) : (
          <ErrorMessage message="You are not allowed to see this page" />
        )}
      </>
    );
  }
}

export default withStyles(styles)(ViewAllTransactionReports);
