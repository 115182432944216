import {
    CONTROLER_PROFILE_PAGE_TEXT_CHANGE_HANDLER,
    CONTROLER_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
    PROFILE_SAVE_ALL_CONTROLLER_PROFILES,
    CONTROLER_PROFILE_PAGE_SAVE_ID_HANDLER,
    CONTROLER_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    ITEM_PAGE_SAVE_EVENT_IDS_HANDLER,
    ITEM_ADD_SESSION_SAVE
} from "./actions";

export const useritemReducer = (state = [], action) => {
    switch (action.type) {

        case CONTROLER_PROFILE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    ItemsForm: {
                        ...state.forms.ItemsForm,
                        fields: {
                            ...state.forms.ItemsForm.fields,
                            [action.key]: {
                                ...state.forms.ItemsForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case CONTROLER_PROFILE_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    ItemsForm: {
                        ...state.forms.ItemsForm,
                        fields: {
                            ...state.forms.ItemsForm.fields,
                            [action.key]: {
                                ...state.forms.ItemsForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case PROFILE_SAVE_ALL_CONTROLLER_PROFILES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allControllerItems: [...action.payload]
                }
            }
        }

        case ITEM_PAGE_SAVE_EVENT_IDS_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    ItemsForm: {
                        ...state.forms.ItemsForm,
                        fields: {
                            ...state.forms.ItemsForm.fields,
                            EventId: {
                                ...state.forms.ItemsForm.fields.EventId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        

        case CONTROLER_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    ItemsForm: {
                        ...state.forms.ItemsForm,
                        fields: {
                            ...state.forms.ItemsForm.fields,
                            [action.key]: {
                                ...state.forms.ItemsForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case CONTROLER_PROFILE_PAGE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    ItemsForm: {
                        ...state.forms.ItemsForm,
                        fields: {
                            ...state.forms.ItemsForm.fields,
                            ItemProfileId: {
                                ...state.forms.ItemsForm.fields.ItemProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case ITEM_ADD_SESSION_SAVE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    addItemResponseMsg: action.payload,
                    itemAddStatus: action.payloadAddItemMessage
                }
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}