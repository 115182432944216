import { connect } from "react-redux";
import Logout from "./component";
import { reverseString } from "../common/Helpers";
import ls from "local-storage";
import instance, { USER_URL } from "../../api/config";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, props) => ({
  logOut: (historyObject) => {
    instance.post(USER_URL + "user-management/logout").then((res) => {
      if (res) {
        localStorage.removeItem(reverseString("app_store"));
        ls.clear();
        window.location.replace("/login");
      }
    });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Logout);
