import BG from "../../../../../resources/images/landingbg.svg";

export const styles = theme => ({
    mainrapper: {
        background:  `url(${BG})`,
        backgroundSize: "cover",
        padding: "32px 13px",
        width: "100%",
        "& .fieldwrapper": {
            padding: "0px 13px",
        },
        "& .textfield": {
            marginTop: 13,
            marginBottom: 22,
            "& div": {
                height: "61px",
            }
        },
        "& .selectfield": {
            marginTop: 13,
            marginBottom: 22,
            "& .react-select__value-container": {
                padding: 14,
            }
        },
        "& .submitbtn": {
            margin: 13,
            height: 61,
            width: 230,
            float: "right",
        }
    },
    fieldswrapper: {
        background: "white",
        "& p": {
            color: "#707070",
        },
    }
});