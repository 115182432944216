import { connect } from "react-redux";
import ViewItemsDetail from "./component";

const mapStateToProps = state => ({
    editViewData: state.EVENT.editViewData,
    fields: state.EVENT.forms.auctionEventForm.fields,
    itemsGermanfields: state.EVENT.ItemsGermanForm.fields,
    itemsFrenchfields: state.EVENT.ItemsFrenchForm.fields
})

const mapDispatchToProps = (dispatch, props) => ({
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewItemsDetail);