import { connect } from "react-redux";
import EditEvent from "./component";
import {
  textUpdateEvent,
  textFieldChangeHandler,
  selectFieldChangeHandler,
  UpdateEventProfile,
  editsaveBiddingIncrementIds,
  textEditFieldChangeHandler,
  updateimageTeaserEventUrl,
  saveEventTimelineProfileIds,
} from "../actions";
import moment from "moment";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import ls from "local-storage";

import instance, { EVENT_URL } from "../../../../../api/config";

const mapStateToProps = (state) => ({
  editFields: state.EVENT.editData,
  fields: state.EVENT.forms.auctionEventForm.fields,
  updateEventResponseMsg: state.EVENT.data.updateEventResponseMsg,
  eventUpdateStatus: state.EVENT.data.eventUpdateStatus,
  updateteaserImageUploadUrl: state.EVENT.data.updateteaserImageUploadUrl,
});

const mapDispatchToProps = (dispatch, props) => ({
  textUpdateTimelineProfile: (name, e) => {
    dispatch(textUpdateEvent(name, e));
  },
  textUpdatevEent: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textUpdateEvent(name, e));
  },
  textEditFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textEditFieldChangeHandler(name, e));
  },
  UploadTeaserImg: (file) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Uploading Image", "small")
    );
    instance
      .post(
        EVENT_URL + "auction-event/auction-events/upload-event-teaser-image",
        file
      )
      .then((res) => {
        dispatch(updateimageTeaserEventUrl(res.data.data));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  UpdateEventProfile: (
    id,
    BiddingBeforeEvent,
    BiddingIncProfileId,
    EventAuctionTimeFrame,
    EventDescription,
    EventHeadline,
    EventName,
    EventTimelineProfileId,
    LiveStream,
    MainEvent,
    MaximumItemsCount,
    ParallelAuctions,
    MinimumItemsCount,
    PublishingTime,
    StartingTime,
    LiveStreamEmbeddedCode,
    LiveChatEmbeddedCode,
    updateteaserImageUploadUrl,
    Language,
    EventLanguages,
    SellingEvent,
    BreakBetween
  ) => {
    let dtPublishFormat = moment(PublishingTime.value).valueOf();
    let dtStartTimeFormat = moment(StartingTime.value).valueOf();

    const eventLanguageCode = ls.get("eventLanguageCode");

    let data = {
      biddingBeforeEvent: BiddingBeforeEvent.value
        ? BiddingBeforeEvent.value[0].value
        : 0,
      biddingIncrementProfileId: BiddingIncProfileId.value[0]
        ? BiddingIncProfileId.value[0].value
        : "",
      eventAuctionTimeFrame: EventAuctionTimeFrame
        ? parseInt(EventAuctionTimeFrame.value)
        : "",
      eventDescription: EventDescription ? EventDescription.value : "",
      eventHeadline: EventHeadline ? EventHeadline.value : "",
      eventName: EventName ? EventName.value : "",
      eventTimelineProfileId: EventTimelineProfileId.value[0]
        ? EventTimelineProfileId.value[0].value
        : "",
      liveStream: LiveStream.value ? LiveStream.value[0].value : 0,
      mainEvent: MainEvent.value ? MainEvent.value[0].value : 0,
      maximumItemsCount: MaximumItemsCount
        ? parseInt(MaximumItemsCount.value)
        : "",
      maximumParallelAuctions: ParallelAuctions
        ? parseInt(ParallelAuctions.value)
        : "",
      minimumItemsCount: MinimumItemsCount
        ? parseInt(MinimumItemsCount.value)
        : "",
      publishingTimeStamp: PublishingTime ? dtPublishFormat : "",
      startingTimeStamp: StartingTime ? dtStartTimeFormat : "",
      liveStreamEmbeddedCode: LiveStreamEmbeddedCode
        ? LiveStreamEmbeddedCode.value
        : [],
      liveChatEmbeddedCode: LiveChatEmbeddedCode
        ? LiveChatEmbeddedCode.value
        : "",
      languageCode: eventLanguageCode ? eventLanguageCode : "en",
      allowedCountryCodes: EventLanguages.value[0]
        ? EventLanguages.value[0]
        : "",
      eventTeaserImageURL: updateteaserImageUploadUrl,
      sellingEvent: SellingEvent.value ? SellingEvent.value[0].value : 0,
      delayForNextItemActivation: BreakBetween
        ? parseInt(BreakBetween.value)
        : "",
    };

    dispatch(UpdateEventProfile(id, data));
  },
  getBiddingIncIDs: () => {
    instance
      .get(EVENT_URL + "bidding-increment-profile/bidding-increment-profiles")
      .then((res) => {
        dispatch(editsaveBiddingIncrementIds(res.data.data));
      })
      .catch((error) => {});
  },
  getTimeLineProfileIDs: () => {
    instance
      .get(EVENT_URL + "timeline-profile/timeline-profiles")
      .then((res) => {
        dispatch(saveEventTimelineProfileIds(res.data.data));
      })
      .catch((error) => {});
  },
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler(name, e));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitTimelines: () => {
    dispatchProps.submitTimelines(
      stateProps.fields.BiddingBeforeEvent,
      stateProps.fields.EventDescription,
      stateProps.fields.EventHeadline,
      stateProps.fields.EventName,
      stateProps.fields.EventTeaser,
      stateProps.fields.LiveStream,
      stateProps.fields.MainEvent,
      stateProps.fields.MajorBannerColor,
      stateProps.fields.MinorBannerColor,
      stateProps.fields.StartingPrice,
      stateProps.fields.Language
    );
  },
  UpdateEventProfile: (id) => {
    dispatchProps.UpdateEventProfile(
      id,
      stateProps.fields.BiddingBeforeEvent,
      stateProps.fields.BiddingIncProfileId,
      stateProps.fields.EventAuctionTimeFrame,
      stateProps.fields.EventDescription,
      stateProps.fields.EventHeadline,
      stateProps.fields.EventName,
      stateProps.fields.EventTimelineProfileId,
      stateProps.fields.LiveStream,
      stateProps.fields.MainEvent,
      stateProps.fields.MaximumItemsCount,
      stateProps.fields.ParallelAuctions,
      stateProps.fields.MinimumItemsCount,
      stateProps.fields.PublishingTime,
      stateProps.fields.StartingTime,
      stateProps.fields.LiveStreamEmbeddedCode,
      stateProps.fields.LiveChatEmbeddedCode,
      stateProps.updateteaserImageUploadUrl,
      stateProps.fields.Language,
      stateProps.fields.EventLanguages,
      stateProps.fields.SellingEvent,
      stateProps.fields.BreakBetween
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditEvent);
