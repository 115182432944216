import { connect } from "react-redux";
import EditBidding from "./component";
import ls from "local-storage";
import instance, { EVENT_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

import { textFieldChangeHandler_PB } from "../actions";

const mapStateToProps = (state) => ({
  fields: state.BIDDING.forms.biddingProfileForm.fields,
  editFields: state.BIDDING.editData,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler_PB(name, e.value));
  },
  submitBiddingProfile: (ProfileName) => {
    let EditBiddingProfileId = ls.get("EditBiddingProfileId");
    let data = {
      biddingIncrementProfileName: ProfileName ? ProfileName.value : "",
    };
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Update Bidding Profile",
        "small"
      )
    );

    instance
      .put(
        EVENT_URL +
          "bidding-increment-profile/bidding-increment-profiles/update/" +
          EditBiddingProfileId,
        data
      )
      .then((res) => {
        window.location.replace("/user/all-profilebidding");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitBiddingProfile: () => {
    dispatchProps.submitBiddingProfile(stateProps.fields.ProfileName);
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditBidding);
