import React, { useState } from "react";

import draftToHtml from "draftjs-to-html";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";

import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

const TextEditor = ({ onChange, value, fieldName, classes }) => {
  const blocksFromHTML = convertFromHTML(value ? value : "");
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(state)
  );

  const handleChange = (editor) => {
    const value = draftToHtml(
      convertToRaw(editor.getCurrentContent())
    ).toString();

    setEditorState(editor);

    onChange && onChange(fieldName, { value: value });
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleChange}
      wrapperClassName="demo-wrapper"
      editorClassName={classes.demoEditor}
      stripPastedStyles={true}
      toolbar={{
        options: ["link"],
        link: {
          inDropdown: false,
          defaultTargetOption: "_blank",
          options: ["link"],
        },
      }}
    />
  );
};

export default withStyles(styles)(TextEditor);
