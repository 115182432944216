import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popover,
  MenuItem,
  ListItemText,
  Select,
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { NavLink } from "react-router-dom";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ErrorMessage from "../../common/ErrorMessage/component";
import moment from "moment";

class ViewAllLoginReports extends Component {
  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.fetchDataIfNeeded();
  };

  render() {
    const { classes, allLoginReports, isAllowed } = this.props;

    const filterDateTime = allLoginReports.filter((val) => val[5]);

    const mapDateTime = filterDateTime.map((val) => {
      return val[5];
    });
    const sortDateTime = mapDateTime.sort((a, b) => a - b);
    const sortDate = sortDateTime.map((item) => {
      return moment(new Date(item))
        .utcOffset("+0700")
        .format("DD.MM.YYYY HH:mm:ss");
    });

    // const id = open ? 'simple-popover' : undefined;

    const columns = [
      "eBay User ID",
      "eBay Username",
      "Auction User ID",
      "Event",
      "User Agent",

      {
        name: "Datetime MST",
        label: "Datetime MST",
        options: {
          filter: true,
          filterType: "custom",

          customBodyRender: (value) => {
            let newValue = "";
            if (value) {
              newValue = moment(new Date(value))
                .utcOffset("+0700")
                .format("DD.MM.YYYY HH:mm:ss");
            }
            return newValue;
          },

          // if the below value is set, these values will be used every time the table is rendered.
          // it's best to let the table internally manage the filterList
          //filterList: [25, 50],

          customFilterListOptions: {
            render: (v) => {
              if (v[0]) {
                return `Datetime MST: ${v[0]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              if (filters[0]) {
                return [date] < filters[0] || [date] > filters[0];
              } else {
                return false;
              }
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Datetime MST</FormLabel>
                <br />

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth={true}
                  value={
                    filterList[index][0] ? (
                      filterList[index][0]
                    ) : (
                      <div style={{ color: "gray" }}>Select Datetime MST</div>
                    )
                  }
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  input={<OutlinedInput id="" />}
                  displayEmpty={true}
                  renderValue={(selected) =>
                    selected == "" ? (
                      <div style={{ color: "gray" }}>Datetime MST</div>
                    ) : (
                      selected
                    )
                  }
                >
                  {sortDate.map((date) => (
                    <MenuItem key={date} value={date}>
                      <ListItemText primary={date} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ),
          },
          print: false,
        },
      },
    ];

    const options = {
      // filterType: 'checkbox',
      downloadOptions: {
        filename: "LoginReport.csv",
        separator: ";",
        filterOptions: {
          useDisplayedRowsOnly: true,
          useDisplayedColumnsOnly: true,
        },
      },
      sortOrder: {
        name: "Datetime MST",
        direction: "asc",
      },
    };

    return isAllowed === "allowed" ? (
      <>
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View all Login Report</p>
            <MUIDataTable
              title={"Login Reports"}
              data={allLoginReports}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        {" "}
        {isAllowed === "loading" ? (
          <ErrorMessage message="loading" />
        ) : (
          <ErrorMessage message="You are not allowed to see this page" />
        )}
      </>
    );
  }
}

export default withStyles(styles)(ViewAllLoginReports);
