import instance, { USER_URL } from "../../../../api/config";
import ls from "local-storage";

export const ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER =
  "ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER =
  "PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER";
export const ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER =
  "ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER";
export const APP_PAGE_SAVE_ID_HANDLER = "APP_PAGE_SAVE_ID_HANDLER";
export const SAVE_ALL_FILTER_DATA = "SAVE_ALL_FILTER_DATA";
export const PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES =
  "PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES";
export const APP_PAGE_IS_ALLOWED_HANDLER = "APP_PAGE_IS_ALLOWED_HANDLER";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textFieldChangeHandler_I = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const saveAllAccountTypesAndApp = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [item.id, item.app_name];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES,
    payload: newArray,
  });
};

export const saveFilterData = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [item.id, item.name, item.url, item.appName, String(item.isAllowed)];
  });
  dispatch({
    type: SAVE_ALL_FILTER_DATA,
    payload: newArray,
  });
};

export const saveAccountTypeIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.name };
  });
  dispatch({
    type: ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const saveAppIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item };
  });
  dispatch({
    type: APP_PAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const checkIfUrlIsAllowed = (value) => (dispatch) => {
  let data = {
    params: {
      accountTypeId: ls.get("AfterLoginAccountTypeid"),
      // accountTypeId: 1
    },
  };
  instance
    .post(USER_URL + "apps/getAppActions", {}, data)
    .then((res) => {
      let itemVal = true;
      res.data.data.forEach((item) => {
        if (item.actionUrl === value && !item.isAllowed) {
          // window.location.replace('/user');
          itemVal = item.isAllowed;
        }
      });
      dispatch({
        type: APP_PAGE_IS_ALLOWED_HANDLER,
        payload: itemVal ? "allowed" : "not-allowed",
      });
    })
    .catch((error) => {});
};
