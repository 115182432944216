import { connect } from "react-redux";
import ViewItemsDetail from "./component";

const mapStateToProps = state => ({
    editViewData: state.REPORTS.editViewData,
    fields: state.REPORTS.forms.auctionEventForm.fields,
    allViewTransactionDetails: state.REPORTS.data.allViewTransactionDetails,
})

const mapDispatchToProps = (dispatch, props) => ({
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewItemsDetail);