import { connect } from "react-redux";
import DashboardLanding from "./component";
import instance, { EVENT_URL, BUYER_SELLER_URL } from "../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../common/ReduxBased/LoadingSpinner/actions";
import { saveDashboardCount, saveDashboardData } from "./actions";
import ls from "local-storage";

const mapStateToProps = (state) => ({
  dashboardCount: state.LANDING_DASHBOARD.data.dashboardCount,
  dashboardDataCount: state.LANDING_DASHBOARD.data.dashboardDataCount,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: () => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Loading Data...", "small")
    );
    instance
      .get(EVENT_URL + "auction-event-dashboard/counts")
      .then((res) => {
        dispatch(saveDashboardCount(res.data.data));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        ls.set("TokenExpired401403", error.response.status);
        dispatch(handleLoadingSpinnerClose());
      });
  },
  dashboardData: () => {
    let data = {
      params: {
        reportType: "DASHBOARD",
      },
    };

    instance
      .get(BUYER_SELLER_URL + "reports", data)
      .then((res) => {
        dispatch(saveDashboardData(res.data));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DashboardLanding);
