export const CONTROLLER = {
  data: {
    allControllerItems: [],
    addItemResponseMsg: "",
    itemAddStatus: "",
  },
  forms: {
    ControllerItemForm: {
      fields: {
        ItemName: {
          label: "Controller Item Name",
          name: "ItemName",
          placeholder: "Controller Item Name",
          value: "",
        },
      },
    },
    controllerIncrementForm: {
      fields: {
        ItemId: {
          label: "Controller Item Id",
          name: "ItemId",
          placeholder: "Controller Item Id",
          value: [],
          options: [],
        },
        EndControllerPrice: {
          label: "End Controller Price",
          name: "EndControllerPrice",
          placeholder: "End Controller Price",
          value: "",
        },
        FirstIncrement: {
          label: "First Controller Increment",
          name: "FirstIncrement",
          placeholder: "First Controller Increment",
          value: "",
        },
        SecondIncrement: {
          label: "Second Controller Increment",
          name: "SecondIncrement",
          placeholder: "Second Controller Increment",
          value: "",
        },
        StartControllerPrice: {
          label: "Start Controller Price",
          name: "StartControllerPrice",
          placeholder: "Start Controller Price",
          value: "",
        },
      },
    },
    ItemsForm: {
      fields: {
        ItemName: {
          label: "Item Name",
          name: "ItemName",
          placeholder: "Item Name",
          value: "",
        },
        Category: {
          label: "Category",
          name: "Category",
          placeholder: "Category",
          value: "",
        },
        EventId: {
          label: "Event Name",
          name: "EventId",
          placeholder: "Event Name",
          value: "",
        },
        HeroItem: {
          label: "Hero Item",
          name: "HeroItem",
          placeholder: "Hero Item",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        TopItem: {
          label: "Top Item",
          name: "TopItem",
          placeholder: "Top Item",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        Price: {
          label: "Starting Price",
          name: "Price",
          placeholder: "Starting Price",
          value: "",
        },
        PublishTime: {
          label: "Publishing Time",
          name: "PublishTime",
          placeholder: "Publishing Time",
          value: "",
        },
        ItemEbayIdentifier: {
          label: "Item Ebay Identifier",
          name: "ItemEbayIdentifier",
          placeholder: "Item Ebay Identifier",
          value: "",
        },
        ItemDescription: {
          label: "Item Description",
          name: "ItemDescription",
          placeholder: "Item Description",
          value: "",
        }, // new fields
        AdditionalItemName: {
          label: "Additional Item Name",
          name: "AdditionalItemName",
          placeholder: "Additional Item Name",
          value: "",
        },
        CertificateAddDocument: {
          label: "Addiotional Document",
          name: "CertificateAddDocument",
          placeholder: "Additional Document",
          value: "",
        },
        IsActive: {
          label: "Is Active",
          name: "IsActive",
          placeholder: "Is Active",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        LosNr: {
          label: "losNr",
          name: "LosNr",
          placeholder: "losNr",
          value: "",
        },
        MarketValueEstimate: {
          label: "Market Value Estimate",
          name: "MarketValueEstimate",
          placeholder: "Market Value Estimate",
          value: "",
        },
        MarketValueEstimateFrontend: {
          label: "Market Value Estimate (Frontend)",
          name: "MarketValueEstimateFrontend",
          placeholder: "100 - 200",
          value: "",
        },
        MarketPlace: {
          label: "Market Place",
          name: "MarketPlace",
          placeholder: "Market Place",
          value: [],
          options: [
            { value: "EBAY_DE", label: "EBAY_DE", name: "de" },
            { value: "EBAY_FR", label: "EBAY_FR", name: "fr" },
            { value: "EBAY_GB", label: "EBAY_UK", name: "uk" },
          ],
        },
        MinimumPrice: {
          label: "Minimum Price",
          name: "MinimumPrice",
          placeholder: "Minimum Price",
          value: "",
        },
        MinimumPriceCheck: {
          label: "Minimum Price Check",
          name: "MinimumPriceCheck",
          placeholder: "Minimum Price Check",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        TeaserItem: {
          label: "Teaser Item",
          name: "TeaserItem",
          placeholder: "Teaser Item",
          value: "",
        },
        AuthenticityGuarantee: {
          label: "Authenticity Guarantee",
          name: "AuthenticityGuarantee",
          placeholder: "Authenticity Guarantee",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
      },
    },
  },
  UISettings: {},
};
export default CONTROLLER;
