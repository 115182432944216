import { connect } from "react-redux";
import PermissionList from "./component";
import instance, { USER_URL } from "../../../../../api/config";
import {
  selectFieldChangeHandler,
  saveAccountTypeIds,
  saveAppIds,
  saveFilterData,
} from "../actions";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import ls from "local-storage";

const mapStateToProps = (state) => ({
  allProfiles: state.PERMISSIONS.data.allAccountTypeProfiles,
  allFilter: state.PERMISSIONS.data.allFilterData,
  fields: state.PERMISSIONS.forms.AccountTypeForm.fields,
  AccountType: state.PERMISSIONS.forms.AccountTypeForm.fields.AccountType,
  App: state.PERMISSIONS.forms.AccountTypeForm.fields.App,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (id) => {
    let data = {};
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Filter Permission", "small")
    );

    instance
      .post(USER_URL + "apps/getAppActions", data)
      .then((res) => {
        dispatch(saveFilterData(res.data.data));
        ls.set("sidebarAllowedURLs", res.data.data);
        // window.location.replace('/user/all-permission')
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  deleteProfileHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Account Type",
        "small"
      )
    );
    instance
      .delete(USER_URL + "core-account-types/deleteAccountType/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
  getAccountTypeIDs: () => {
    instance
      .get(USER_URL + "core-account-types/getAccountTypes")
      .then((res) => {
        dispatch(saveAccountTypeIds(res.data.data));
      })
      .catch((error) => {});
  },
  getAppIDs: () => {
    instance
      .get(USER_URL + "apps/getAllApps")
      .then((res) => {
        dispatch(saveAppIds(res.data.data));
      })
      .catch((error) => {});
  },
  submitSearch: (AccountType, App) => {
    ls.set("AllDataPermission", AccountType, App);

    let data = {
      params: {
        accountTypeId: AccountType.value[0] ? AccountType.value[0].value : "",
        appName: App.value[0] ? App.value[0].label : "",
      },
    };
    ls.set(
      "EditAccountType",
      AccountType.value[0] ? AccountType.value[0].value : ""
    );
    ls.set("EditApps", App.value[0] ? App.value[0].label : "");
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Filter Permission", "small")
    );

    instance
      .post(USER_URL + "apps/getAppActions", {}, data)
      .then((res) => {
        // ls.set('AclAccountTypedata', res.data.data[0].accountTypes.coreAccountType);
        // ls.set('AclAppdata', res.data.data[0].apps.app_name);
        // ls.set('AclIsAlloweddata', String(res.data.data[0].isAllowed));

        dispatch(saveFilterData(res.data.data));
        // window.location.replace('/user/all-permission')
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  SubmitHandler: (AllowDeny) => {
    let EditAccountType = ls.get("EditAccountType");
    let EditApps = ls.get("EditApps");

    let data = {
      params: {
        accountTypeId: EditAccountType ? EditAccountType : "",
        appName: EditApps ? EditApps : "",
        isAllowed: AllowDeny.value[0] ? AllowDeny.value[0].title : 0,
      },
    };
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Permission Action", "small")
    );

    instance
      .put(USER_URL + "apps/updatePermission", {}, data)
      .then((res) => {
        window.location.replace("/user/all-permission");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
  submitSearch: () => {
    dispatchProps.submitSearch(
      stateProps.fields.AccountType,
      stateProps.fields.App
    );
  },
  SubmitHandler: () => {
    dispatchProps.SubmitHandler(
      stateProps.fields.AllowDeny,
      stateProps.fields.AccountType,
      stateProps.fields.App
    );
  },
  ...ownProps,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(PermissionList);
