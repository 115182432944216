export const DASHBOARD = {
    data: {
        allTImelineProfiles: [],
        deleteTimelineResponseMsg: '',
        timelineDeleteStatus: '',
        updateViewTimelineResponseMsg: '',
        timelineViewUpdateStatus: ''
    },
    editData: {
        editDataId: '',
    },
    editViewData: {
        editDataId: '',
        editCurrentDataId:"",
        editBidStart: '',
        editBidEnd:"",
        editDurationFirst:"",
        editDurationSecond:"",
        editDurationThird:""
    },
    forms: {
        timelineProfileForm: {
            fields: {
                BidsCountForToNext: {
                    label: "Bid Count For Next Stage",
                    name: "BidsCountForToNext",
                    placeholder: "Bid Count For Next Stage",
                    value: ""
                },
                BidsCountForPrevious: {
                    label: "Bid Count For Previous Stage",
                    name: "BidsCountForPrevious",
                    placeholder: "Bid Count For Previous Stage",
                    value: ""
                },
                IsDynamic: {
                    label: "Is Dynamic",
                    name: "IsDynamic",
                    placeholder: "Is Dynamic",
                    value: [],
                    options: [
                        {value: 1, label: "true"},
                        {value: 0, label: "false"}
                    ]
                },
                TimeForNext: {
                    label: "Time for Next Stage",
                    name: "TimeForNext",
                    placeholder: "Time for Next Stage",
                    value: ""
                },
                TimeForPrevious: {
                    label: "Time for Previous Stage",
                    name: "TimeForPrevious",
                    placeholder: "Time for Previous Stage",
                    value: ""
                },
                TimelineProfileName: {
                    label: "Timeline Profile Name",
                    name: "TimelineProfileName",
                    placeholder: "Timeline Profile Name",
                    value: ""
                },
                TitleTimeline: {
                    label: "Title",
                    name: "TitleTimeline",
                    placeholder: "Timeline Title",
                    value: ""
                },
            }
        },
        timelineForm: {
            fields: {
                BidEnd: {
                    label: "Bid End",
                    name: "BidEnd",
                    placeholder: "Bid End",
                    value: ""
                },
                BidStart: {
                    label: "Bid Start",
                    name: "BidStart",
                    placeholder: "Bid Start",
                    value: ""
                },
                DurationFirstRound: {
                    label: "Duration First Round",
                    name: "DurationFirstRound",
                    placeholder: "Duration First Round",
                    value: ""
                },
                DurationSecondRound: {
                    label: "Duration Second Round",
                    name: "DurationSecondRound",
                    placeholder: "Duration Second Round",
                    value: ""
                },
                DurationThirdRound: {
                    label: "Duration Third Round",
                    name: "DurationThirdRound",
                    placeholder: "Duration Third Round",
                    value: ""
                },
                TimelineProfileId: {
                    label: "Timeline Profile ID",
                    name: "TimelineProfileId",
                    placeholder: "Timeline Profile ID",
                    value: [],
                    options: []
                },
            }
        }
    },
    UISettings: {
        timelineEditError: "",
        saveTimelineError: ""
    }
}
export default DASHBOARD;