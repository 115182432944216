import { connect } from "react-redux";
import Header from "./component";
import { reverseString } from "../../../../common/Helpers";
import ls from "local-storage";
import instance, { EVENT_URL, USER_URL } from "../../../../../api/config";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, props) => ({
  logOut: (historyObject) => {
    instance.post(USER_URL + "user-management/logout").then((res) => {
      if (res) {
        localStorage.removeItem(reverseString("app_store"));
        ls.clear();
        window.location.replace("/login");
      }
    });
  },
  fetchDataIfNeeded: async () => {
    await instance
      .get(EVENT_URL + "auction-event-dashboard/counts")
      .then((res) => {})
      .catch((error) => {
        const { response } = error;
        if (response.status == 401 || response.status == 403) {
          localStorage.removeItem(reverseString("app_store"));
          ls.clear();
          window.location.replace("/login");
        }
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Header);
