export const BUYERS = {
    data: {
        allBuyers: []
    },
    forms: {
        auctionEventForm: {
            fields: {
                BiddingBeforeEvent: {
                    label: "Bidding Before Event",
                    name: "BiddingBeforeEvent",
                    placeholder: "Bidding Before Event",
                    value: [],
                    options: [
                        {value: 1, label: "true"},
                        {value: 0, label: "false"}
                    ]
                },
                BiddingIncProfileId: {
                    label: "Bidding Increment Profile",
                    name: "BiddingIncProfileId",
                    placeholder: "Bidding Increment Profile",
                    value: [],
                    options: []
                },
                Category: {
                    label: "Category",
                    name: "Category",
                    placeholder: "Category",
                    value: ""
                },
                EventAuctionTimeFrame: {
                    label: "Event Auction Time Frame",
                    name: "EventAuctionTimeFrame",
                    placeholder: "Event Auction Time Frame",
                    value: ""
                },
                EventDescription: {
                    label: "Event Description",
                    name: "EventDescription",
                    placeholder: "Event Description",
                    value: ""
                },
                EventHeadline: {
                    label: "Event Headline",
                    name: "EventHeadline",
                    placeholder: "Event Headline",
                    value: ""
                },
                EventName: {
                    label: "Event Name",
                    name: "EventName",
                    placeholder: "Event Name",
                    value: ""
                },
                EventTeaser: {
                    label: "Event Teaser",
                    name: "EventTeaser",
                    placeholder: "Event Teaser",
                    value: ""
                },
                EventTimelineProfileId: {
                    label: "Event Timeline Profile",
                    name: "EventTimelineProfileId",
                    placeholder: "Event Timeline Profile",
                    value: [],
                    options: []
                },
                LiveStream: {
                    label: "Live Stream",
                    name: "LiveStream",
                    placeholder: "Live Stream",
                    value: [],
                    options: [
                        {value: 1, label: "true"},
                        {value: 0, label: "false"}
                    ]
                },
                MainEvent: {
                    label: "Main Event",
                    name: "MainEvent",
                    placeholder: "Main Event",
                    value: [],
                    options: [
                        {value: 1, label: "true"},
                        {value: 0, label: "false"}
                    ]
                },
                MajorBannerColor: {
                    label: "Major Banner Color",
                    name: "MajorBannerColor",
                    placeholder: "Major Banner Color",
                    value: ""
                },
                MaximumItemsCount: {
                    label: "Maximum Items Count",
                    name: "MaximumItemsCount",
                    placeholder: "Maximum Items Count",
                    value: ""
                },
                ParallelAuctions: {
                    label: "Maximum Parallel Auctions",
                    name: "ParallelAuctions",
                    placeholder: "Maximum Parallel Auctions",
                    value: ""
                },
                MinimumItemsCount: {
                    label: "Minimum Items Count",
                    name: "MinimumItemsCount",
                    placeholder: "Minimum Items Count",
                    value: ""
                },
                MinorBannerColor: {
                    label: "Minor Banner Color",
                    name: "MinorBannerColor",
                    placeholder: "Minor Banner Color",
                    value: ""
                },
                PublishingTime: {
                    label: "Publishing Time",
                    name: "PublishingTime",
                    placeholder: "Publishing Time",
                    value: ""
                },
                StartingPrice: {
                    label: "Starting Price",
                    name: "StartingPrice",
                    placeholder: "Starting Price",
                    value: ""
                },
                StartingTime: {
                    label: "Starting Time",
                    name: "StartingTime",
                    placeholder: "Starting Time",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        
    }
}
export default BUYERS;