import { connect } from "react-redux";
import ViewAllUserAccount from "./component";
import instance, { USER_URL } from "../../../../../api/config";
import { saveAllAccountUsers, editAccountUserChangeHandler } from "../actions";

import { checkIfUrlIsAllowed } from "../../Permissions/actions";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = (state) => ({
  allProfiles: state.ACCOUNT_USER.data.allAccountTypeProfiles,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (id) => {
    instance
      .get(USER_URL + "user-management/getAllUsers")
      .then((res) => {
        dispatch(saveAllAccountUsers(res.data.data));
      })
      .catch((err) => {});
  },
  deleteProfileHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Account Type",
        "small"
      )
    );
    instance
      .delete(USER_URL + "user-management/deleteUser/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editAccountUserChangeHandler: (value, name) => {
    dispatch(editAccountUserChangeHandler(value, name));
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/user-management/getAllUsers"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllUserAccount);
