export const BIDDING = {
    data: {
        allBiddingProfiles: [],
        deleteBiddingResponseMsg: '',
        biddingDeleteStatus: '',
        updateViewBiddingResponseMsg: '',
        biddingViewUpdateStatus: ''
    },
    editData: {
        editDataId: '',
        editDataName: '',
    },
    editViewData: {
        editDataId: '',
        editCurrentDataId:"",
    },
    forms: {
        biddingProfileForm: {
            fields: {
                ProfileName: {
                    label: "Bidding Profile Name",
                    name: "ProfileName",
                    placeholder: "Bidding Profile Name",
                    value: ""
                },
            }
        },
        biddingIncrementForm: {
            fields: {
                ProfileId: {
                    label: "Bidding Profile",
                    name: "ProfileId",
                    placeholder: "Bidding Profile",
                    value: [],
                    options: []
                },
                EndBiddingPrice: {
                    label: "End Bidding Price",
                    name: "EndBiddingPrice",
                    placeholder: "End Bidding Price",
                    value: ""
                },
                FirstIncrement: {
                    label: "First Bidding Increment",
                    name: "FirstIncrement",
                    placeholder: "First Bidding Increment",
                    value: ""
                },
                SecondIncrement: {
                    label: "Second Bidding Increment",
                    name: "SecondIncrement",
                    placeholder: "Second Bidding Increment",
                    value: ""
                },
                StartBiddingPrice: {
                    label: "Start Bidding Price",
                    name: "StartBiddingPrice",
                    placeholder: "Start Bidding Price",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        biddingIncrementError: ''
    }
}
export default BIDDING;