import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { ValidatorForm } from "react-material-ui-form-validator";
import instance, { EVENT_URL } from "../../../../../../../api/config";
import MUIDataTable from "mui-datatables";
import ls from "local-storage";

class ViewItemsDetail extends Component {
  state = {
    title: "",
    itemCondition: "",
    shortDescription: "",
    color: "",
    brand: "",
    edititemName: "",
    edititemEbayIdentifier: "",
    edittopItem: "",
    authenticityGuarantee: "",
    shipLocations: [],
    shippingOptions: [],
    quantity: "",
    bestOfferEnabled: "",
    sellerAuthorized: "",
  };

  SubmitHandler = () => {
    const { editViewData } = this.props;
    const {
      edititemName,
      edititemEbayIdentifier,
      edittopItem,
      title,
      itemCondition,
      shortDescription,
      color,
      brand,
      authenticityGuarantee,
    } = this.state;
    let data = {
      itemName: edititemName,
      itemEbayIdentifier: edititemEbayIdentifier,
      topItem: edittopItem,
      title: title,
      itemCondition: itemCondition,
      shortDescription: shortDescription,
      color: color,
      brand: brand,
      languageCode: "en",
    };

    this.props.UpdateItemEvent(
      editViewData.editDataId,
      editViewData.editCurrentDataId,
      data
    );
  };

  componentDidMount = () => {
    const { editViewData } = this.props;
    let paramCode = ls.get("languageCode");
    instance
      .get(
        EVENT_URL +
          "auction-event/auction-event-items/get/" +
          editViewData.editCurrentDataId +
          "/" +
          editViewData.editDataId +
          "?languageCode=" +
          paramCode
      )
      .then((res) => {
        const response = res.data.data ? res.data.data : [];
        this.setState({
          edititemName: response.itemName ? response.itemName : "",
          edititemEbayIdentifier: response.itemEbayIdentifier
            ? response.itemEbayIdentifier
            : "",
          edittopItem: response.topItem ? response.topItem : "",
          authenticityGuarantee: response.authenticityGuarantee
            ? response.authenticityGuarantee
            : "",
          title: response.itemDetail ? response.itemDetail.title : "",
          itemCondition: response.itemDetail
            ? response.itemDetail.itemCondition
            : "",
          shortDescription: response.itemDetail
            ? response.itemDetail.shortDescription
            : "",
          color: response.itemDetail ? response.itemDetail.color : "",
          brand: response.itemDetail ? response.itemDetail.brand : "",
          quantity: response.itemDetailTrading.quantity
            ? response.itemDetailTrading.quantity
            : "",
          bestOfferEnabled: response.itemDetailTrading.bestOfferEnabled
            ? response.itemDetailTrading.bestOfferEnabled
            : "",
          sellerAuthorized: response.itemDetailTrading.sellerAuthorized
            ? response.itemDetailTrading.sellerAuthorized
            : "",
        });
        let shipLoc = response.itemDetail.shipToLocations[0].regionIncluded.map(
          (item) => {
            return [item.id, item.regionName];
          }
        );
        this.setState({
          shipLocations: [...shipLoc],
        });

        let shipOpt = response.itemDetail.shippingOptions.map((item) => {
          return [item.id, item.type];
        });
        this.setState({
          shippingOptions: [...shipOpt],
        });
      })
      .catch((err) => {});
  };

  textChangeHandler = (name, value) => {
    this.setState({
      [name]: value ? value.value : "",
    });
  };

  render() {
    const { classes, itemsGermanfields, itemsFrenchfields } = this.props;

    const {
      edititemName,
      edititemEbayIdentifier,
      edittopItem,
      title,
      itemCondition,
      shortDescription,
      authenticityGuarantee,
      color,
      brand,
      quantity,
      bestOfferEnabled,
      sellerAuthorized,
    } = this.state;

    let paramCode = ls.get("languageCode");

    return (
      <div className={classes.editmainrapper}>
        <div className={classes.editfieldswrapper}>
          <p>View Event Item Detail</p>

          <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Item Name"}</span>
                  <div className={classes.fieldsdata}>{edititemName}</div>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Item ebay Identifier"}</span>
                  <div className={classes.fieldsdata}>
                    {edititemEbayIdentifier}
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Top Item"}</span>
                  <div className={classes.fieldsdata}>
                    {edittopItem ? "true" : "false"}
                  </div>
                </div>
              </Grid>

              {/* new fields */}
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Title"}</span>
                  <div className={classes.fieldsdata}>{title}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Item Condition"}</span>
                  <div className={classes.fieldsdata}>{itemCondition}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Short Description"}</span>
                  <div className={classes.fieldsdata}>{shortDescription}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Color"}</span>
                  <div className={classes.fieldsdata}>{color}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>{"Brand"}</span>
                  <div className={classes.fieldsdata}>{brand}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Authenticity Guarantee"}</span>
                  <div className={classes.fieldsdata}>
                    {authenticityGuarantee ? "true" : "false"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Quantity"}</span>
                  <div className={classes.fieldsdata}>{quantity}</div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Best Offer Enabled"}</span>
                  <div className={classes.fieldsdata}>
                    {bestOfferEnabled ? "true" : "false"}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span> {"Seller Authorized"}</span>
                  <div className={classes.fieldsdata}>
                    {sellerAuthorized ? "true" : "false"}
                  </div>
                </div>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>

        <div>
          <MUIDataTable
            title={"Shipping Locations"}
            data={this.state.shipLocations}
            columns={["ID", "Region Name"]}
          />

          <MUIDataTable
            title={"Shipping Options"}
            data={this.state.shippingOptions}
            columns={["ID", "Type"]}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ViewItemsDetail);
