export const SELLER = {
    data: {
        allSellers: [],
        sellerTokenRes:"",
        addSellerResponseMsg:'',
        sellerAddStatus:"",
        fetchTokenStatus:"",

    },
    editData: {
        editDataId: '',
        editDataFirstname:"",
        editDataLastname:"",
        editDataUserId:"",
        editDataEmail:"",
    },
    forms: {
        sellerForm: {
            fields: {
                SellerUsernameCreate: {
                    label: "Add eBay Username",
                    name: "SellerUsernameCreate",
                    placeholder: "Add eBay Username",
                    value: ""
                },
                SellerEmailCreate: {
                    label: "Add Email",
                    name: "SellerEmailCreate",
                    placeholder: "Add Email",
                    value: ""
                },
            }
        },

        sellerFormEdit: {
            fields: {
                SellerFirstNameCreate: {
                    label: "Add Firstname",
                    name: "SellerFirstNameCreate",
                    placeholder: "Add Firstname",
                    value: ""
                },
                SellerLastNameCreate: {
                    label: "Add Lastname",
                    name: "SellerLastNameCreate",
                    placeholder: "Add Lastname",
                    value: ""
                },
                SellerUsernameCreate: {
                    label: "Add Username",
                    name: "SellerUsernameCreate",
                    placeholder: "Add Username",
                    value: ""
                },
                SellerEmailCreate: {
                    label: "Add Email",
                    name: "SellerEmailCreate",
                    placeholder: "Add Email",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        
    }
}
export default SELLER;