import React from "react";

class Logout extends React.Component {

    componentDidMount = () => {
        this.props.logOut(this.props.history);
    };
    render() {

        return (
            <></>
        );
    }
}

export default Logout;