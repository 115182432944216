import { connect } from "react-redux";
import ViewAllTimelines from "./component";
import {
    editViewTimelineChangeHandler
} from "../../../actions";
import {
    checkIfUrlIsAllowed
} from "../../../Permissions/actions";

const mapStateToProps = state => ({
    allProfiles: state.DASHBOARD.data.allTImelineProfiles,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed
   
})

const mapDispatchToProps = (dispatch, props) => ({
    editViewTimelineChangeHandler: (value,current_id,data) => {
        dispatch(editViewTimelineChangeHandler(value,current_id,data));
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('/timeline-profile/timeline-profiles/{timelineProfileId}/timelines'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewAllTimelines);