import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, { EVENT_URL } from "../../../../../../api/config";
import { NavLink } from "react-router-dom";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../../common/ReduxBased/confirmationDialog/container";
import ViewItemsDetail from "./ViewItemDetail/container";
import ReactSelectValidator from "../../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import ls from "local-storage";
import moment from "moment";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

import EditEvent from "./EditItem/container";
import ChangePriority from "./ChangePriority/container";
import ErrorMessage from "../../../common/ErrorMessage/component";

class ViewItems extends Component {
  state = {
    alltimelines: [],
    show: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    languageField: {
      name: "Language Field",
      placeholder: "Select Language",
      label: "Select Language",
      options: [],
      value: [],
    },
  };

  handleClick = (event, dataValue, rowData) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentID: dataValue,
      editData: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
      currentId: id,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  editHandler = (show, id, current_id, editData) => {
    this.props.editViewItemChangeHandler(id, current_id, editData);
    this.setState({
      showEdit: show,
    });
  };

  showItemDetail = (show, id, current_id, editData) => {
    this.props.editViewItemChangeHandler(id, current_id, editData);
    this.setState({
      showItemDetail: show,
    });
  };

  showViewItemDetailDialog = (value) => {
    this.setState({
      showItemDetail: value,
    });
  };

  editPriorityHandler = (show, id, current_id, editData) => {
    this.props.editViewItemChangeHandler(id, current_id, editData);
    this.setState({
      showPrioty: show,
      currentPriority: editData[10]
    });
  };

  showPriorityEventDialog = (value) => {
    this.setState({
      showPrioty: value,
    });
  };



  deleteProfileHandler = (id) => {
    this.setState({
      showDelete: false,
    });
  
    const stateCurrentId = this.state.editData ? this.state.editData[0] : "";
    const propsCurrentId = this.props.currentId;

    handleLoadingSpinnerOpenWithOptions(
      true,
      "Deleting Timeline Profile",
      "small"
    );
    instance
      .delete(
        EVENT_URL +
          "auction-event/auction-event-items/remove/" +
          stateCurrentId +
          "/" +
          propsCurrentId
      )
      .then((res) => {
        handleLoadingSpinnerClose();
        window.location.reload();
      })
      .catch((err) => {
        handleLoadingSpinnerClose();
      });
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    ls.set("languageCode", "en");
    instance
      .get(
        EVENT_URL +
          "auction-event/auction-event-items/" +
          this.props.currentId +
          "?languageCode=en"
      )
      .then((res) => {
        this.setState({
          itemsLength: res.data?.data?.length
        })
        let newArray = res.data.data.map((item) => {
          
          let eligibleTime =
            moment(item.itemDetail.itemEndDate).valueOf() >
              moment(new Date()).valueOf() ||
            item.itemDetail.itemEndDate === null
              ? true
              : false;
          let publishtimevalue = moment(item.publishingTimeStamp).format(
            "ddd MMM DD YYYY HH:mm:ss"
          );
          let eligibleMarketValue =
            Number(item.marketValueEstimate * 2) <=
            Number(item.itemDetail.price.value)
              ? true
              : false;
          return [
            item.id,
            item.itemName,
            item.itemEbayIdentifier,
            publishtimevalue.toString(),
            item.topItem ? "true" : "false",
            item.losNr,
            item.marketValueEstimate,
            item.startingPrice,
            item.minimumPrice,
            item.minimumPriceCheck ? "true" : "false",
            item.prioritySequence,
            item.itemDetailTrading.quantity > 0 &&
            item.itemDetailTrading.bestOfferEnabled === true &&
            item.itemDetailTrading.sellerAuthorized === true &&
            eligibleTime === true &&
            eligibleMarketValue === true
              ? "true"
              : "false",
          ];
        });
        this.setState({
          alltimelines: [...newArray],
        });
      })
      .catch((err) => {});

    //getting languages
    instance
      .get(EVENT_URL + "core/core-languages")
      .then((res) => {
        const languages = res.data.data
          ? res.data.data.map((item) => {
              return {
                value: item.languageCode,
                label: item.languageName,
              };
            })
          : [];
        const updatedLanguages = { ...this.state.languageField };
        updatedLanguages.options = [...languages];
        this.setState({
          languageField: updatedLanguages,
        });
      })
      .catch((err) => {});
  };

  viewTimelines = (value) => {};

  showEditEventDialog = (value) => {
    this.setState({
      showEdit: value,
    });
  };

  selectFieldChangeHandler = (name, value) => {
    const updatedLanguages = { ...this.state.languageField };
    updatedLanguages.value = value ? { ...value } : [];
    this.setState({
      languageField: updatedLanguages,
    });
    let paramCode = value ? value.value : "en";
    ls.set("languageCode", paramCode);
    instance
      .get(
        EVENT_URL +
          "auction-event/auction-event-items/" +
          this.props.currentId +
          "?languageCode=" +
          paramCode
      )
      .then((res) => {
        let newArray = res.data.data.map((item) => {
          let publishtimevalue = moment(item.publishingTimeStamp).format(
            "ddd MMM DD YYYY HH:mm:ss"
          );
          return [
            item.id,
            item.itemName,
            item.itemEbayIdentifier,
            publishtimevalue.toString(),
            item.topItem ? "true" : "false",
            item.losNr,
            item.marketValueEstimate,
            item.startingPrice,
            item.minimumPrice,
            item.minimumPriceCheck ? "true" : "false",
            item.prioritySequence,
            item.itemDetailTrading.quantity > 0 &&
            item.itemDetailTrading.bestOfferEnabled === true &&
            item.itemDetailTrading.sellerAuthorized === true
              ? "true"
              : "false",
          ];
        });
        this.setState({
          alltimelines: [...newArray],
        });
      })
      .catch((err) => {});
  };

  render() {
    const {
      classes,
      currentId,
      isAllowed,
      itemsGermanfields,
      itemsFrenchfields,
    } = this.props;
    const { editData, languageField } = this.state;
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    let paramCode = ls.get("languageCode");

    const columns = [
      "ID",
      "Item Name",
      "Item Ebay Identifier",
      "Publishing Time",
      "Top Item",
      "losNr",
      "Market Value Estimate",
      "Starting Price",
      "Minimum Price",
      "Minimum Price Check",
      "Priority Sequence",
      "Eligible",
      // paramCode === "de" ? itemsGermanfields.Id.label : paramCode === "fr" ? itemsFrenchfields.Id.label : "ID",
      // paramCode === "de" ? itemsGermanfields.ItemName.label : paramCode === "fr" ? itemsFrenchfields.ItemName.label : "Item Name",
      // paramCode === "de" ? itemsGermanfields.ItemEbayIdentifier.label : paramCode === "fr" ? itemsFrenchfields.ItemEbayIdentifier.label : "Item Ebay Identifier",
      // paramCode === "de" ? itemsGermanfields.PublishTime.label : paramCode === "fr" ? itemsFrenchfields.PublishTime.label : "Publishing Time",
      // paramCode === "de" ? itemsGermanfields.TopItem.label : paramCode === "fr" ? itemsFrenchfields.TopItem.label : "Top Item",
      // paramCode === "de" ? itemsGermanfields.losNr.label : paramCode === "fr" ? itemsFrenchfields.losNr.label : "losNr",
      // paramCode === "de" ? itemsGermanfields.MarketValueEstimate.label : paramCode === "fr" ? itemsFrenchfields.MarketValueEstimate.label : "Market Value Estimate",
      // paramCode === "de" ? itemsGermanfields.StartingPrice.label : paramCode === "fr" ? itemsFrenchfields.StartingPrice.label : "Starting Price",
      // paramCode === "de" ? itemsGermanfields.MinimumPrice.label : paramCode === "fr" ? itemsFrenchfields.MinimumPrice.label : "Minimum Price",
      // paramCode === "de" ? itemsGermanfields.MinimumPriceCheck.label : paramCode === "fr" ? itemsFrenchfields.MinimumPriceCheck.label : "Minimum Price Check",
      // paramCode === "de" ? itemsGermanfields.PrioritySequence.label : paramCode === "fr" ? itemsFrenchfields.PrioritySequence.label : "Priority Sequence",
      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;
            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>

                 {this.state.itemsLength > 1 && 
                 (<Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.editPriorityHandler(
                          true,
                          currentId,
                          this.state.currentID,
                          editData
                        )
                      }
                    >
                      Change Priority
                    </Button>)
                    } 

                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showItemDetail(
                          true,
                          currentId,
                          this.state.currentID,
                          editData
                        )
                      }
                    >
                      View Detail
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.editHandler(
                          true,
                          currentId,
                          this.state.currentID,
                          editData
                        )
                      }
                    >
                      Edit
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showAlertDialogDelete(true, this.state.currentId)
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {};

    return isAllowed === "allowed" ? (
      <>
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Item"}
            message={"Are you sure want to delete this item?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}

        {this.state.showPrioty && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showPrioty}
            showTitle={true}
            title={"Change Priority"}
            message={<ChangePriority currentPrioritySequence={this.state.currentPriority} itemsLength={this.state.itemsLength} />}
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={() => this.showPriorityEventDialog(false)}
            closeAlertDialog={() => this.showPriorityEventDialog(false)}
            applyAlertDialog={() => this.showPriorityEventDialog(false)}
          />
        )}


        {this.state.showEdit && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showEdit}
            showTitle={true}
            title={"Edit Event"}
            message={<EditEvent />}
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={() => this.showEditEventDialog(false)}
            closeAlertDialog={() => this.showEditEventDialog(false)}
            applyAlertDialog={() => this.showEditEventDialog(false)}
          />
        )}

        {this.state.showItemDetail && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.showItemDetail}
            showTitle={true}
            title={"View Item Detail"}
            message={<ViewItemsDetail />}
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={() => this.showViewItemDetailDialog(false)}
            closeAlertDialog={() => this.showViewItemDetailDialog(false)}
            applyAlertDialog={() => this.showViewItemDetailDialog(false)}
          />
        )}
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View all Event Items</p>
            <div className={classes.filterbox}>
              <NavLink to={"/user/create-items"} className={classes.createlink}>
                Create Item
              </NavLink>

              <div className="fieldwrapper">
                <ValidatorForm
                // onSubmit={this.SubmitHandler}
                // autoComplete="off"
                >
                  <ReactSelectValidator
                    id={languageField.name}
                    name={languageField.name}
                    value={languageField.value}
                    placeholder={languageField.placeholder}
                    validations={true}
                    fullWidth={true}
                    onChange={(value) => {
                      this.selectFieldChangeHandler(languageField.name, value);
                    }}
                    validators={["required"]}
                    errorMessages={["Required"]}
                    options={languageField.options}
                    className="selectfield"
                  />
                </ValidatorForm>
              </div>
            </div>
            <MUIDataTable
              title={"Item Details"}
              data={this.state.alltimelines}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    ) : (
      <>
        {" "}
        {isAllowed === "loading" ? (
          <ErrorMessage message="loading" />
        ) : (
          <ErrorMessage message="You are not allowed to see this page" />
        )}
      </>
    );
  }
}

export default withStyles(styles)(ViewItems);
