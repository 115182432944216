import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";

class EditAccount extends Component {
      state={
          editFirstName:'',
          editLastName:'',
          editActivationToken:''
      }

    SubmitHandler = () => {
        const {editFields } = this.props;
        const { editFirstName,editLastName} = this.state;
        let data ={
            firstName :editFirstName,
            lastName :editLastName,
            accountId : editFields.editDataId
        }

        this.props.UpdateAccountProfile(editFields.editDataId, data);
    }

    componentDidMount = () => {
        const { editFields } = this.props;
        this.setState({
            editFirstName: editFields.editDataFirstName ? editFields.editDataFirstName : '',
            editLastName: editFields.editDataLastName ? editFields.editDataLastName : '',
        })
    }

    textChangeHandler = (name, value) => {
        this.setState({
            [name]: value ? value.value : ""
        })
    }

    render() {
        const {
            classes,
            editFields,
        } = this.props;

        const {
            editFirstName,
            editLastName,
        } = this.state;

        return (
            <>
                <div className={classes.editmainrapper}>
                <div className={classes.editfieldswrapper}>
                    <p>Edit Account {editFields.editDataId}</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>First Name</span>
                                <CustomTextValidator
                                    id={editFirstName}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editFirstName}
                                    value={editFirstName}
                                    onChange={value => {
                                        this.textChangeHandler('editFirstName', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Last Name</span>
                                <CustomTextValidator
                                    id={editLastName}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editLastName}
                                    value={editLastName}
                                    onChange={value => {
                                        this.textChangeHandler('editLastName', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>
                          
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditAccount);