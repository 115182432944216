import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import ls from "local-storage";
import Alert from "@material-ui/lab/Alert";
import TextEditor from "../../../../common/TextEditor/component";
import moment from "moment";

class CreateControllerProfile extends Component {
  componentDidMount = () => {
    this.props.getEventIDs();
  };

  SubmitHandler = () => {
    this.props.submitItems();
  };

  render() {
    console.log(this.SubmitHandler, "submithand.....");

    let ItemResponseMessage = ls.get("ItemResponseMessage");

    const {
      classes,
      textFieldChangeHandler,
      selectFieldChangeHandler,
      addItemResponseMsg,
      itemAddStatus,
      fields,
    } = this.props;
    return (
      <>
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>Create a New Item</p>

            <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.ItemEbayIdentifier.label}</span>
                    <CustomTextValidator
                      id={fields.ItemEbayIdentifier.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.ItemEbayIdentifier.placeholder}
                      name={fields.ItemEbayIdentifier.name}
                      value={fields.ItemEbayIdentifier.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.ItemEbayIdentifier.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.ItemName.label}</span>
                    <CustomTextValidator
                      id={fields.ItemName.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.ItemName.placeholder}
                      name={fields.ItemName.name}
                      value={fields.ItemName.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.ItemName.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.LosNr.label}</span>
                    <CustomTextValidator
                      id={fields.LosNr.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.LosNr.placeholder}
                      name={fields.LosNr.name}
                      value={fields.LosNr.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.LosNr.name, value);
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.PublishTime.label}</span>
                    <CustomTextValidator
                      id={fields.PublishTime.name}
                      fullWidth={true}
                      type="datetime-local"
                      placeholder={fields.PublishTime.placeholder}
                      name={fields.PublishTime.name}
                      value={fields.PublishTime.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.PublishTime.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.Price.label}</span>
                    <CustomTextValidator
                      id={fields.Price.name}
                      validations={true}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.Price.placeholder}
                      name={fields.Price.name}
                      value={fields.Price.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.Price.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MinimumPrice.label}</span>
                    <CustomTextValidator
                      id={fields.MinimumPrice.name}
                      validations={true}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.MinimumPrice.placeholder}
                      name={fields.MinimumPrice.name}
                      value={fields.MinimumPrice.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.MinimumPrice.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MinimumPriceCheck.label}</span>
                    <ReactSelectValidator
                      id={fields.MinimumPriceCheck.name}
                      name={fields.MinimumPriceCheck.name}
                      value={fields.MinimumPriceCheck.value}
                      placeholder={fields.MinimumPriceCheck.placeholder}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.MinimumPriceCheck.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.MinimumPriceCheck.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MarketValueEstimate.label}</span>
                    <CustomTextValidator
                      id={fields.MarketValueEstimate.name}
                      validations={true}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.MarketValueEstimate.placeholder}
                      name={fields.MarketValueEstimate.name}
                      value={fields.MarketValueEstimate.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.MarketValueEstimate.name,
                          value
                        );
                      }}
                      validators={[
                        "required",
                        "matchRegexp:(^[0-9]+(-[0-9]+)+$|^[0-9]*$)",
                      ]}
                      errorMessages={[
                        "Required",
                        "Market Value should be in Range i.e (10 OR 100-500)",
                      ]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MarketValueEstimateFrontend.label}</span>
                    <CustomTextValidator
                      id={fields.MarketValueEstimateFrontend.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.MarketValueEstimateFrontend.placeholder}
                      name={fields.MarketValueEstimateFrontend.name}
                      value={fields.MarketValueEstimateFrontend.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.MarketValueEstimateFrontend.name,
                          value
                        );
                      }}
                      validators={[
                        "required",
                      ]}
                      errorMessages={[
                        "Required",
                      ]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MarketPlace.label}</span>
                    <ReactSelectValidator
                      id={fields.MarketPlace.name}
                      name={fields.MarketPlace.name}
                      value={fields.MarketPlace.value}
                      placeholder={fields.MarketPlace.placeholder}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.MarketPlace.name,
                          value
                        );
                      }}
                      options={fields.MarketPlace.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.TopItem.label}</span>
                    <ReactSelectValidator
                      id={fields.TopItem.name}
                      name={fields.TopItem.name}
                      value={fields.TopItem.value}
                      placeholder={fields.TopItem.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.TopItem.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.TopItem.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.ItemDescription.label}</span>
                    <TextEditor
                      fieldName={fields.ItemDescription.name}
                      onChange={textFieldChangeHandler}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.AuthenticityGuarantee.label}</span>
                    <ReactSelectValidator
                      id={fields.AuthenticityGuarantee.name}
                      name={fields.AuthenticityGuarantee.name}
                      value={fields.AuthenticityGuarantee.value}
                      placeholder={fields.AuthenticityGuarantee.placeholder}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.AuthenticityGuarantee.name,
                          value
                        );
                      }}
                      options={fields.AuthenticityGuarantee.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.IsActive.label}</span>
                    <ReactSelectValidator
                      id={fields.IsActive.name}
                      name={fields.IsActive.name}
                      value={fields.IsActive.value}
                      placeholder={fields.IsActive.placeholder}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.IsActive.name, value);
                      }}
                      options={fields.IsActive.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventId.label}</span>
                    <ReactSelectValidator
                      id={fields.EventId.name}
                      name={fields.EventId.name}
                      value={fields.EventId.value}
                      placeholder={fields.EventId.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.EventId.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      isSearchable={true}
                      options={fields.EventId.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="submitbtn"
                    type="submit"
                  >
                    Add
                  </Button>
                </Grid>

                {addItemResponseMsg ? (
                  <Alert severity="error">{itemAddStatus}</Alert>
                ) : null}
              </Grid>
            </ValidatorForm>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(CreateControllerProfile);
