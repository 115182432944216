import { connect } from "react-redux";
import ViewItems from "./component";
import {
    editViewItemChangeHandler
} from "../../actions";
import {
    checkIfUrlIsAllowed
} from "../../../Permissions/actions";

const mapStateToProps = state => ({
    allProfiles: state.DASHBOARD.data.allTImelineProfiles,
    isAllowed: state.PERMISSIONS.UISettings.isAllowed,
    itemsGermanfields: state.EVENT.ItemsGermanForm.fields,
    itemsFrenchfields: state.EVENT.ItemsFrenchForm.fields
})

const mapDispatchToProps = (dispatch, props) => ({
    editViewItemChangeHandler: (value,current_id,name) => {
        dispatch(editViewItemChangeHandler(value,current_id,name));
    },
    checkIfUrlIsAllowed: async () => {
        await dispatch(checkIfUrlIsAllowed('/auction-event/auction-event-items/{eventId}'))
    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(ViewItems);