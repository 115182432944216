import instance, { USER_URL } from "../../../../api/config";

export const ACCOUNT_USER_PAGE_TEXT_CHANGE_HANDLER =
  "ACCOUNT_USER_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const ACCOUNT_USER_PAGE_SELECT_CHANGE_HANDLER =
  "ACCOUNT_USER_PAGE_SELECT_CHANGE_HANDLER";
export const ACCOUNT_USER_PAGE_SAVE_ID_HANDLER =
  "ACCOUNT_USER_PAGE_SAVE_ID_HANDLER";
export const SET_UPDATE_ACCOUNT_TYPE_PROFILE =
  "SET_UPDATE_ACCOUNT_TYPE_PROFILE";
export const ACCOUNT_USER_EDIT_TEXT_CHANGE_HANDLER =
  "ACCOUNT_USER_EDIT_TEXT_CHANGE_HANDLER";

export const PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES =
  "PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES";
export const PROFILE_ACCOUNT_ERROR_HANDLER = "PROFILE_ACCOUNT_ERROR_HANDLER";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: ACCOUNT_USER_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: ACCOUNT_USER_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const saveAllAccountUsers = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [
      item.id,
      item.firstName,
      item.lastName,
      item.accountTypeName,
      item.email,
      item.createdDate,
      String(item.isActive),
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES,
    payload: newArray,
  });
};

export const saveAccountUserIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.name };
  });
  dispatch({
    type: ACCOUNT_USER_PAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const editAccountUserChangeHandler = (value, name) => (dispatch) => {
  dispatch({
    type: ACCOUNT_USER_EDIT_TEXT_CHANGE_HANDLER,
    payloadFirstName: name[1],
    payloadLastName: name[2],
    payloadEmail: name[4],
    payloadStatus: name[6],
    payloadAccountType: name[3],
    key: value,
  });
};

export const textUpdateAccountType = (name, value) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_ACCOUNT_TYPE_PROFILE,
    payload: value,
    key: name,
  });
};

export const UpdateAccountTypeProfile = (id, data) => (dispatch) => {
  instance
    .put(USER_URL + "user-management/updateUser/" + id, data)
    .then((res) => {
      window.location.replace("/user/all-useraccount");
    })
    .catch((error) => {});
};

export const setUserAccountErrorHandler = (value) => (dispatch) => {
  dispatch({
    type: PROFILE_ACCOUNT_ERROR_HANDLER,
    payload: value,
  });
};
