export const EVENT = {
  data: {
    allEvents: [],
    updateEventResponseMsg: "",
    eventUpdateStatus: "",
    teaserImageUploadUrl: "",
    updateteaserImageUploadUrl: "",
    eventCreateErrorsAlert: "",
    eventStopErrorsAlert: "",
  },
  editData: {
    editDataId: "",
    editDataName: "",
    editDataHeadline: {
      label: "Event Headline",
      name: "editDataHeadline",
      placeholder: "Event Headline",
      value: "",
    },
    editDataDescription: "",
    editDataTeaser: "",
    editDataBbevent: "",
    editDataBprofile: "",
    editDataTprofile: "",
    editDataLiveStream: "",
    editDataMainEvent: "",
    editDataMajorColor: "",
    editDataBannerColor: "",
    editDataPublishTime: "",
    editDataStartTime: "",
    editDataStartPrice: "",
    editBiddingIncProfileId: {
      label: "Bidding Increment Profile",
      name: "editBiddingIncProfileId",
      placeholder: "Bidding Increment Profile",
      value: [],
      options: [],
    },
  },
  // editViewData: {
  //     editDataId: '',
  //     editCurrentDataId:"",
  //     edititemName:"",
  //     editheroItem:"",
  //     edititemEbayIdentifier:"",
  //     editpublishingTime:"",
  //     edittopItem:""
  // },
  forms: {
    auctionEventForm: {
      fields: {
        BiddingBeforeEvent: {
          label: "Bidding Before Event",
          name: "BiddingBeforeEvent",
          placeholder: "Bidding Before Event",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        BiddingIncProfileId: {
          label: "Bidding Increment Profile",
          name: "BiddingIncProfileId",
          placeholder: "Bidding Increment Profile",
          value: [],
          options: [],
        },
        Category: {
          label: "Category",
          name: "Category",
          placeholder: "Category",
          value: "",
        },
        EventAuctionTimeFrame: {
          label: "Event Auction Time Frame",
          name: "EventAuctionTimeFrame",
          placeholder: "Event Auction Time Frame",
          value: "",
        },
        EventDescription: {
          label: "Event Description",
          name: "EventDescription",
          placeholder: "Event Description",
          value: "",
        },
        EventHeadline: {
          label: "Event Headline",
          name: "EventHeadline",
          placeholder: "Event Headline",
          value: "",
        },
        EventName: {
          label: "Event Name",
          name: "EventName",
          placeholder: "Event Name",
          value: "",
        },
        EventTeaser: {
          label: "Event Teaser",
          name: "EventTeaser",
          placeholder: "Event Teaser",
          value: "",
        },
        EventTimelineProfileId: {
          label: "Timeline Profile",
          name: "EventTimelineProfileId",
          placeholder: "Timeline Profile",
          value: [],
          options: [],
        },
        LiveStream: {
          label: "Live Stream",
          name: "LiveStream",
          placeholder: "Live Stream",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        SellingEvent: {
          label: "Selling Event",
          name: "SellingEvent",
          placeholder: "Selling Event",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        MainEvent: {
          label: "Main Event",
          name: "MainEvent",
          placeholder: "Main Event",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        MajorBannerColor: {
          label: "Major Banner Color",
          name: "MajorBannerColor",
          placeholder: "Major Banner Color",
          value: "",
        },
        MaximumItemsCount: {
          label: "Maximum Items Count",
          name: "MaximumItemsCount",
          placeholder: "Maximum Items Count",
          value: "",
        },
        ParallelAuctions: {
          label: "Maximum Parallel Auctions",
          name: "ParallelAuctions",
          placeholder: "Maximum Parallel Auctions",
          value: "",
        },
        MinimumItemsCount: {
          label: "Minimum Items Count",
          name: "MinimumItemsCount",
          placeholder: "Minimum Items Count",
          value: "",
        },
        MinorBannerColor: {
          label: "Minor Banner Color",
          name: "MinorBannerColor",
          placeholder: "Minor Banner Color",
          value: "",
        },
        PublishingTime: {
          label: "Publishing Time",
          name: "PublishingTime",
          placeholder: "Publishing Time",
          value: "",
        },
        StartingPrice: {
          label: "Starting Price",
          name: "StartingPrice",
          placeholder: "Starting Price",
          value: "",
        },
        StartingTime: {
          label: "Starting Time",
          name: "StartingTime",
          placeholder: "Starting Time",
          value: "",
        },
        LiveStreamEmbeddedCode: {
          label: "Video Embedded Code",
          name: "LiveStreamEmbeddedCode",
          placeholder: "Video Embedded Code",
          value: "",
        },
        LiveChatEmbeddedCode: {
          label: "Chat Embedded Code",
          name: "LiveChatEmbeddedCode",
          placeholder: "Chat Embedded Code",
          value: "",
        },
        UpdateTeaserImage: {
          label: "Event Teaser Image",
          name: "UpdateTeaserImage",
          placeholder: "Event Teaser Image",
          value: "",
        },
        AuthenticityGuarantee: {
          label: "Authenticity Guarantee",
          name: "AuthenticityGuarantee",
          placeholder: "Authenticity Guarantee",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        MarketPlace: {
          label: "Market Place",
          name: "MarketPlace",
          placeholder: "Market Place",
          value: [],
          options: [
            { value: 1, label: "EBAY_DE" },
            { value: 0, label: "EBAY_FR" },
          ],
        },
        BreakBetween: {
          label: "Break Between Auctions",
          name: "BreakBetween",
          placeholder: "Range (5-180 seconds)",
          value: "30",
        },
        Language: {
          label: "Select Language",
          name: "Language",
          placeholder: "Select Language",
          value: [],
          options: [
            { value: 2, label: "English", name: "en" },
            { value: 1, label: "German", name: "de" },
            { value: 0, label: "French", name: "fr" },
          ],
        },
        EventLanguages: {
          label: "Select Language",
          name: "EventLanguages",
          placeholder: "Select Language",
          value: [],
          options: [],
        },
      },
    },
  },
  editforms: {
    auctionEventForm: {
      fields: {
        BiddingBeforeEvent: {
          label: "Bidding Before Event",
          name: "BiddingBeforeEvent",
          placeholder: "Bidding Before Event",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        BiddingIncProfileId: {
          label: "Bidding Increment Profile",
          name: "BiddingIncProfileId",
          placeholder: "Bidding Increment Profile",
          value: [],
          options: [],
        },
        Category: {
          label: "Category",
          name: "Category",
          placeholder: "Category",
          value: "",
        },
        EventAuctionTimeFrame: {
          label: "Event Auction Time Frame",
          name: "EventAuctionTimeFrame",
          placeholder: "Event Auction Time Frame",
          value: "",
        },
        EventDescription: {
          label: "Event Description",
          name: "EventDescription",
          placeholder: "Event Description",
          value: "",
        },
        EventHeadline: {
          label: "Event Headline",
          name: "EventHeadline",
          placeholder: "Event Headline",
          value: "",
        },
        EventName: {
          label: "Event Name",
          name: "EventName",
          placeholder: "Event Name",
          value: "",
        },
        EventTeaser: {
          label: "Event Teaser",
          name: "EventTeaser",
          placeholder: "Event Teaser",
          value: "",
        },
        EventTimelineProfileId: {
          label: "Event Timeline Profile",
          name: "EventTimelineProfileId",
          placeholder: "Event Timeline Profile",
          value: [],
          options: [],
        },
        LiveStream: {
          label: "Live Stream",
          name: "LiveStream",
          placeholder: "Live Stream",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        MainEvent: {
          label: "Main Event",
          name: "MainEvent",
          placeholder: "Main Event",
          value: [],
          options: [
            { value: 1, label: "true" },
            { value: 0, label: "false" },
          ],
        },
        MajorBannerColor: {
          label: "Major Banner Color",
          name: "MajorBannerColor",
          placeholder: "Major Banner Color",
          value: "",
        },
        MaximumItemsCount: {
          label: "Maximum Items Count",
          name: "MaximumItemsCount",
          placeholder: "Maximum Items Count",
          value: "",
        },
        ParallelAuctions: {
          label: "Maximum Parallel Auctions",
          name: "ParallelAuctions",
          placeholder: "Maximum Parallel Auctions",
          value: "",
        },
        MinimumItemsCount: {
          label: "Minimum Items Count",
          name: "MinimumItemsCount",
          placeholder: "Minimum Items Count",
          value: "",
        },
        MinorBannerColor: {
          label: "Minor Banner Color",
          name: "MinorBannerColor",
          placeholder: "Minor Banner Color",
          value: "",
        },
        PublishingTime: {
          label: "Publishing Time",
          name: "PublishingTime",
          placeholder: "Publishing Time",
          value: "",
        },
        StartingPrice: {
          label: "Starting Price",
          name: "StartingPrice",
          placeholder: "Starting Price",
          value: "",
        },
        StartingTime: {
          label: "Starting Time",
          name: "StartingTime",
          placeholder: "Starting Time",
          value: "",
        },
      },
    },
  },
  ItemsGermanForm: {
    fields: {
      Title: {
        label: "Titel",
        name: "title",
        placeholder: "Titel",
        value: "",
      },
      ItemEbayIdentifier: {
        label: "Artikel ebay-Kennung",
        name: "ItemEbayIdentifier",
        placeholder: "Artikel ebay-Kennung",
        value: "",
      },
      TopItem: {
        label: "Top-Artikel",
        name: "TopItem",
        placeholder: "Top-Artikel",
        value: "",
      },
      ItemCondition: {
        label: "Artikel Zustand",
        name: "ItemCondition",
        placeholder: "Artikel Zustand",
        value: "",
      },
      ShortDescription: {
        label: "kurze Beschreibung",
        name: "ShortDescription",
        placeholder: "kurze Beschreibung",
        value: "",
      },
      Color: {
        label: "Farbe",
        name: "Color",
        placeholder: "Farbe",
        value: "",
      },
      Brand: {
        label: "Marke",
        name: "Brand",
        placeholder: "Marke",
        value: "",
      },
      ShippingLocations: {
        label: "Versandorte",
        name: "ShippingLocations",
        placeholder: "Versandorte",
        value: "",
      },
      ShippingOptions: {
        label: "Versandoptionen",
        name: "ShippingOptions",
        placeholder: "Versandoptionen",
        value: "",
      },
      Id: {
        label: "Ich würde",
        name: "Id",
        placeholder: "Ich würde",
        value: "",
      },
      ItemName: {
        label: "Artikelname",
        name: "ItemName",
        placeholder: "Artikelname",
        value: "",
      },
      PublishTime: {
        label: "Veröffentlichungszeit",
        name: "PublishTime",
        placeholder: "Veröffentlichungszeit",
        value: "",
      },
      losNr: {
        label: "losNr",
        name: "losNr",
        placeholder: "losNr",
        value: "",
      },
      MarketValueEstimate: {
        label: "Marktwertschätzung",
        name: "MarketValueEstimate",
        placeholder: "Marktwertschätzung",
        value: "",
      },
      StartingPrice: {
        label: "Startpreis",
        name: "StartingPrice",
        placeholder: "Startpreis",
        value: "",
      },
      PrioritySequence: {
        label: "Prioritätsfolge",
        name: "PrioritySequence",
        placeholder: "Prioritätsfolge",
        value: "",
      },
      MinimumPrice: {
        label: "Minimaler Preis",
        name: "MinimumPrice",
        placeholder: "Minimaler Preis",
        value: "",
      },
      MinimumPriceCheck: {
        label: "Überprüfung des Mindestpreises",
        name: "MinimumPriceCheck",
        placeholder: "Überprüfung des Mindestpreises",
        value: "",
      },
      AuthenticityGuarantee: {
        label: "Authentizität Garantie",
        name: "AuthenticityGuarantee",
        placeholder: "Authentizität Garantie",
        value: [],
        options: [
          { value: 1, label: "true" },
          { value: 0, label: "false" },
        ],
      },
    },
  },
  ItemsFrenchForm: {
    fields: {
      Title: {
        label: "Titre",
        name: "title",
        placeholder: "Titre",
        value: "",
      },
      ItemEbayIdentifier: {
        label: "Identifiant ebay de l'article",
        name: "ItemEbayIdentifier",
        placeholder: "Identifiant ebay de l'article",
        value: "",
      },
      TopItem: {
        label: "Article supérieur",
        name: "TopItem",
        placeholder: "Article supérieur",
        value: "",
      },
      ItemCondition: {
        label: "Etat de l'objet",
        name: "ItemCondition",
        placeholder: "Etat de l'objet",
        value: "",
      },
      ShortDescription: {
        label: "brève description",
        name: "ShortDescription",
        placeholder: "brève description",
        value: "",
      },
      Color: {
        label: "Couleur",
        name: "Color",
        placeholder: "Couleur",
        value: "",
      },
      Brand: {
        label: "Marque",
        name: "Brand",
        placeholder: "Marque",
        value: "",
      },
      ShippingLocations: {
        label: "Lieux d'expédition",
        name: "ShippingLocations",
        placeholder: "Lieux d'expédition",
        value: "",
      },
      ShippingOptions: {
        label: "Les options d'expédition",
        name: "ShippingOptions",
        placeholder: "Les options d'expédition",
        value: "",
      },
      Id: {
        label: "identifiant",
        name: "Id",
        placeholder: "identifiant",
        value: "",
      },
      ItemName: {
        label: "Nom de l'article",
        name: "ItemName",
        placeholder: "Nom de l'article",
        value: "",
      },
      PublishTime: {
        label: "Heure de publication",
        name: "PublishTime",
        placeholder: "Heure de publication",
        value: "",
      },
      losNr: {
        label: "losNr",
        name: "losNr",
        placeholder: "losNr",
        value: "",
      },
      MarketValueEstimate: {
        label: "Estimation de la valeur marchande",
        name: "MarketValueEstimate",
        placeholder: "Estimation de la valeur marchande",
        value: "",
      },
      StartingPrice: {
        label: "Estimation de la valeur marchande",
        name: "StartingPrice",
        placeholder: "Estimation de la valeur marchande",
        value: "",
      },
      MinimumPrice: {
        label: "Prix ​​minimum",
        name: "MinimumPrice",
        placeholder: "Prix ​​minimum",
        value: "",
      },
      MinimumPriceCheck: {
        label: "Vérification du prix minimum",
        name: "MinimumPriceCheck",
        placeholder: "Vérification du prix minimum",
        value: "",
      },
      PrioritySequence: {
        label: "Séquence prioritaire",
        name: "PrioritySequence",
        placeholder: "Séquence prioritaire",
        value: "",
      },
      AuthenticityGuarantee: {
        label: "Authenticité Garantie",
        name: "AuthenticityGuarantee",
        placeholder: "Authenticité Garantie",
        value: [],
        options: [
          { value: 1, label: "true" },
          { value: 0, label: "false" },
        ],
      },
    },
  },
  UISettings: {
    updateEventItemError: "",
  },
};
export default EVENT;
