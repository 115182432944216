import { connect } from "react-redux";
import EditItem from "./component";
import {
    textUpdateEvent,
    UpdateItemEvent
} from "../../../actions";

const mapStateToProps = state => ({
    editViewData: state.EVENT.editViewData,
    fields: state.EVENT.forms.auctionEventForm.fields,
    itemsFields: state.CONTROLLER.forms.ItemsForm.fields,
    updateEventItemError: state.EVENT.UISettings.updateEventItemError

})

const mapDispatchToProps = (dispatch, props) => ({
    textUpdateTimelineProfile: (name, e) => {
        dispatch(textUpdateEvent(name, e));
    },
  
    UpdateItemEvent: (id,current_id,data) => {
        dispatch(UpdateItemEvent(id, current_id,data));

    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitTimelines: () => {
        dispatchProps.submitTimelines(
            stateProps.fields.BiddingBeforeEvent,
            stateProps.fields.EventDescription,
            stateProps.fields.EventHeadline,
            stateProps.fields.EventName,
            stateProps.fields.EventTeaser,
            stateProps.fields.LiveStream,
            stateProps.fields.MainEvent,
            stateProps.fields.MajorBannerColor,
            stateProps.fields.MinorBannerColor,
            stateProps.fields.StartingPrice,
            stateProps.fields.authenticityGuarantee,
            stateProps.fields.marketPlace
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditItem);