import { connect } from "react-redux";
import CreateTimelineProfile from "./component";
import {
  textFieldChangeHandler_PT,
  selectFieldChangeHandler_PT,
} from "../../actions";
import instance, { EVENT_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  fields: state.DASHBOARD.forms.timelineProfileForm.fields,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler_PT(name, e));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler_PT(name, e));
  },
  submitTimelineProfile: (
    BidsCountForToNext,
    BidsCountForPrevious,
    IsDynamic,
    TimelineProfileName
  ) => {
    let data = {
      bidsCountForGoingToNextStage: BidsCountForToNext
        ? BidsCountForToNext.value
        : "",
      bidsCountForGoingToPreviousStage: BidsCountForPrevious
        ? BidsCountForPrevious.value
        : "",
      isTimelineProfileDynamic: IsDynamic.value ? IsDynamic.value[0].label : 0,
      timelineProfileName: TimelineProfileName ? TimelineProfileName.value : "",
    };
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Saving Timeline Profile",
        "small"
      )
    );

    instance
      .post(EVENT_URL + "timeline-profile/timeline-profiles/create", data)
      .then((res) => {
        window.location.replace("/user/all-profiletimeline");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(
      checkIfUrlIsAllowed("/timeline-profile/timeline-profiles/create")
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitTimelineProfile: () => {
    dispatchProps.submitTimelineProfile(
      stateProps.fields.BidsCountForToNext,
      stateProps.fields.BidsCountForPrevious,
      stateProps.fields.IsDynamic,
      stateProps.fields.TimelineProfileName
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateTimelineProfile);
