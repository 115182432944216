import { connect } from "react-redux";
import ViewAllTransactionReports from "./component";
import instance, { BUYER_SELLER_URL } from "../../../../../api/config";
import {
  transactionViewFilterChangeHandler,
  saveAllTransactionReports,
  selectFieldChangeHandler,
  saveTotalTransactions,
  saveRedoResMessage,
} from "../actions";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  allTransactionReports: state.REPORTS.data.allTransactionReports,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
  redoResTransaction: state.REPORTS.data.redoResTransaction,
  totalCountsTransactions: state.REPORTS.data.totalCountsTransactions,
  fields: state.REPORTS.forms.auctionEventForm.fields,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (size, page, dropdownStatus) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Loading Report...", "small")
    );

    let data = {
      params: {
        reportType: dropdownStatus === "failed" ? "FAILED" : "TRANSACTION",
        page: page,
        size: size,
      },
    };

    instance
      .get(BUYER_SELLER_URL + "reports", data)
      .then((res) => {
        dispatch(saveAllTransactionReports(res.data.reports));
        dispatch(saveTotalTransactions(res.data.totalRecords));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editViewItemChangeHandler: (value, current_id, name) => {
    dispatch(transactionViewFilterChangeHandler(value, current_id, name));
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/auction-event/auction-events"));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
  editRedoChangeHandler: (itemID, eventID) => {
    dispatch(handleLoadingSpinnerOpenWithOptions(true, "Updating...", "small"));

    let data = {
      params: {
        itemId: itemID,
        eventId: eventID,
      },
    };

    instance
      .get(BUYER_SELLER_URL + "reports/retryFailedTransactions", data)
      .then((res) => {
        dispatch(saveRedoResMessage(res.data.responseMessage));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllTransactionReports);
