export const SAVE_ALL_LANGUAGES_HANDLER = "SAVE_ALL_LANGUAGES_HANDLER";
export const CREATE_LANGUAGE_PAGE_TEXT_CHANGE_HANDLER = "CREATE_LANGUAGE_PAGE_TEXT_CHANGE_HANDLER";

export const saveAllLanguages = (value) => dispatch => {
    let newArray = value.map((item) => {return [item.id, item.languageName, 
        item.languageCode]})
    dispatch({
        type: SAVE_ALL_LANGUAGES_HANDLER,
        payload: newArray,
    });
};

export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: CREATE_LANGUAGE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};