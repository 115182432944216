import { connect } from "react-redux";
import EditTimeline from "./component";
import {
    textFieldChangeHandler_PT,
    selectFieldChangeHandler_Edit,
    UpdateTimelineProfile
} from "../../actions";

import ls from 'local-storage';

const mapStateToProps = state => ({
    editFields: state.DASHBOARD.editData,
    fields: state.DASHBOARD.forms.timelineProfileForm.fields

})

const mapDispatchToProps = (dispatch, props) => ({
    textFieldChangeHandler: (name, e) => {
        if (typeof e.value !== "string") {
            e.value = "";
        }
        dispatch(textFieldChangeHandler_PT(name, e));
    },
    selectFieldChangeHandler: (name, e) => {
        dispatch(selectFieldChangeHandler_Edit(name, e));
    },
    submitTimelineProfile: (id, BidsCountForToNext, BidsCountForPrevious, IsDynamic, TimelineProfileName) => {

        let data = {
            bidsCountForGoingToNextStage: BidsCountForToNext ? parseInt(BidsCountForToNext.value) : "",
            bidsCountForGoingToPreviousStage: BidsCountForPrevious ? parseInt(BidsCountForPrevious.value) : "",
            isTimelineProfileDynamic: IsDynamic.value ? IsDynamic.value[0].label : 0,
            timelineProfileName: TimelineProfileName ? TimelineProfileName.value : ""
          }

        dispatch(UpdateTimelineProfile(id, data));

    },
  
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitTimelineProfile: (id) => {
        dispatchProps.submitTimelineProfile(
            id,
            stateProps.fields.BidsCountForToNext,
            stateProps.fields.BidsCountForPrevious,
            stateProps.fields.IsDynamic,
            stateProps.fields.TimelineProfileName
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditTimeline);