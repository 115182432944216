import axios from "axios";

import { getAppStoreValue } from "../components/common/Helpers";

export const CURRENT_ENV = process.env.REACT_APP_ENV;
export const BASE_HOST_URL = process.env.REACT_APP_BASE_HOST_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const EVENT_URL = process.env.REACT_APP_EVENT_URL;
export const USER_URL = process.env.REACT_APP_USER_URL;
export const BUYER_SELLER_URL = process.env.REACT_APP_BUYER_SELLER_URL;
export const REPORTS_URL = process.env.REACT_APP_REPORTS_URL;

const headers = {
  "Content-Type": "application/JSON",
  "Access-Control-Allow-Origin": "*",
};

const instance = axios.create({
  baseURL: EVENT_URL,
  baseUserURL: USER_URL,
  headers: headers,
});

instance.interceptors.request.use(
  function (request) {
    // request.httpsAgent = new https.Agent({
    //     rejectUnauthorized: false,
    //     cert: file,
    // });
    let token = getAppStoreValue("sessionToken").toString();
    if (!!token) {
      request.headers.authorization = `Bearer ${token}`;
    } else {
      request.config = { headers: { "Content-Type": "multipart/form-data" } };
    }
    // Do something with response data
    return request;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    try {
      var res = JSON.parse(
        response.request.responseText.replace(
          /("[^"]*"\s*:\s*)(\d{16,})/g,
          '$1"$2"'
        )
      );
      response.data.responseObj = res.responseObj;
    } catch (e) {
      console.log("Error Parsing json", e);
    }

    return response;
  },
  function (e) {
    if (!!e.response) {
      let status = e.response.status;
      switch (status) {
        case 401:
          localStorage.setItem("isAuthenticated", false);
          localStorage.setItem("token", "");
          localStorage.clear();
          window.location.href = "/login";
          break;
        default:
      }
    }
    return Promise.reject(e);
  }
);

export default instance;
