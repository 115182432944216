import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Popover,
  Grid,
  MenuItem,
  ListItemText,
  Select,
  FormGroup,
  FormLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { NavLink } from "react-router-dom";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ViewItems from "./ViewItems/container";
import EditAuctionEvent from "../EditEvent/container";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from "local-storage";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import instance, { EVENT_URL } from "../../../../../api/config";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

class ViewAllEvents extends Component {
  state = {
    show: false,
    showDelete: false,
    currentId: "",
    anchorEl: null,
    editData: "",
    languageCode: "",
    allEvents: [],
    stopEvent: false,
    languageField: {
      name: "Language Field",
      placeholder: "Select Language",
      label: "Select Language",
      options: [],
      value: [],
    },
  };

  handleClick = (event, dataValue, rowData) => {
    let newDate = new Date().valueOf();

    let stopEventCondition = newDate > rowData[14] ? true : false;

    this.setState({
      stopEvent: stopEventCondition,
      anchorEl: event.currentTarget,
      currentId: dataValue,
      editData: rowData,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  showAlertDialog = (value, id) => {
    this.setState({
      show: value,
    });
  };

  showAlertDialogDelete = (value, id) => {
    this.setState({
      showDelete: value,
      currentId: id,
    });
  };

  editHandler = (show, id, editData) => {
    this.props.editEventChangeHandler(id, editData);
    this.setState({
      showEdit: show,
    });
  };

  stopEventHandler = (show, id, editData) => {
    this.props.stopEventChangeHandler(id, editData);
  };

  deleteProfileHandler = () => {
    this.setState({
      showDelete: false,
    });
    this.props.deleteProfileHandler(this.state.currentId);
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.getLanguages();
    // this.props.fetchDataIfNeeded();

    let perData = {
      params: {
        languageCode: "en",
      },
    };

    instance
      .get(EVENT_URL + "auction-event/auction-events", perData)
      .then((res) => {
        let newArray = res.data.data.map((item) => {
          // let publishtimevalue =  moment(item.publishingTimeStamp).format("ddd MMM DD YYYY HH:mm:ss");
          // let startingtimevalue =  moment(item.startingTimeStamp).format("ddd MMM DD YYYY HH:mm:ss");
          return [
            item.id,
            item.eventName,
            item.eventHeadline,
            item.eventDescription,
            item.allowedCountryCodes
              ? item.allowedCountryCodes.map((val, index) => {
                  return (index ? ", " : "") + val.countryCode;
                })
              : "",
            String(item.biddingBeforeEvent),
            item.biddingIncrementProfile.biddingIncrementProfileName,
            item.timelineProfile.timelineProfileName,
            String(item.liveStream),
            String(item.mainEvent),
            String(item.sellingEvent),
            item.delayForNextItemActivation,
            item.eventTeaserImageURL,
            item.publishingTimeStamp,
            item.startingTimeStamp,
          ];
        });
        this.setState({
          allEvents: [...newArray],
        });

        const languages = res.data.data
          ? res.data.data.map((item) => {
              return {
                value: item.languageCode,
                label: item.languageName,
              };
            })
          : [];
        const updatedLanguages = { ...this.state.languageField };
        updatedLanguages.options = [...languages];
        this.setState({
          languageField: updatedLanguages,
        });
      })
      .catch((err) => {
        // dispatch(handleLoadingSpinnerClose());
      });
  };

  showEditAuctionEventDialog = (value) => {
    this.setState({
      showEdit: value,
    });
    window.location.reload();
  };

  selectFieldChangeHandler = (name, value) => {
    const updatedLanguages = { ...this.state.languageField };
    const selectedLanguage = (updatedLanguages.value = value
      ? { ...value }
      : []);
    this.setState({
      languageField: updatedLanguages,
    });
    ls.set("eventLanguageCode", selectedLanguage.name);

    let perData = {
      params: {
        languageCode: value ? value.name : "en",
      },
    };

    instance
      .get(EVENT_URL + "auction-event/auction-events", perData)
      .then((res) => {
        let newArray = res.data.data.map((item) => {
          // let publishtimevalue =  moment(item.publishingTimeStamp).format("ddd MMM DD YYYY HH:mm:ss");
          // let startingtimevalue =  moment(item.startingTimeStamp).format("ddd MMM DD YYYY HH:mm:ss");
          return [
            item.id,
            item.eventName,
            item.eventHeadline,
            item.eventDescription,
            item.allowedCountryCodes
              ? item.allowedCountryCodes.map((val, index) => {
                  return (index ? ", " : "") + val.countryCode;
                })
              : "",
            String(item.biddingBeforeEvent),
            item.biddingIncrementProfile.biddingIncrementProfileName,
            item.timelineProfile.timelineProfileName,
            String(item.liveStream),
            String(item.mainEvent),
            String(item.sellingEvent),
            item.delayForNextItemActivation,
            item.eventTeaserImageURL,
            item.publishingTimeStamp,
            item.startingTimeStamp,
          ];
        });
        this.setState({
          allEvents: [...newArray],
        });
      })
      .catch((err) => {
        // dispatch(handleLoadingSpinnerClose());
      });
  };

  render() {
    const {
      classes,
      // allEvents,
      isAllowed,
      eventStopErrorsAlert,
      fields,
    } = this.props;

    const { currentId, editData, allEvents, languageField } = this.state;

    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;

    const filterPublishDateTime = allEvents.filter((val) => val[11]);
    const filterStartDateTime = allEvents.filter((val) => val[12]);

    const mapDateTime = filterPublishDateTime.map((val) => {
      return val[11];
    });
    const sortDateTime = mapDateTime.sort((a, b) => a - b);
    const sortDate = sortDateTime.map((item) => {
      return moment(new Date(item)).format("MMM DD YYYY HH:mm:ss");
    });

    const mapStartDateTime = filterStartDateTime.map((val) => {
      return val[12];
    });
    const sortStartDateTime = mapStartDateTime.sort((a, b) => a - b);
    const sortStartDate = sortStartDateTime.map((item) => {
      return moment(new Date(item)).format("MMM DD YYYY HH:mm:ss");
    });

    const columns = [
      "Event ID",
      "Event Name",
      "Headline",
      {
        name: "Description",
        label: "Description",
        options: {
          customBodyRender: (props, data) => {
            return (
              <span className={classes.descriptionbox}>{data.rowData[3]}</span>
            );
          },
        },
      },
      "Allow Countries",
      "Bidding Before Event",
      "Bidding Profile",
      "Timeline Profile",
      "Live Stream",
      "Main Event",
      "Selling Event",
      "Break Between",
      {
        name: "Teaser Image",
        label: "Teaser Image",
        options: {
          customBodyRender: (props, data) => {
            return data.rowData[12] ? (
              <img className={classes.teaserimage} src={data.rowData[12]} />
            ) : (
              <></>
            );
          },
        },
      },

      {
        name: "Publishing Time",
        label: "Publishing Time",
        options: {
          filter: true,
          sort: true,
          filterType: "custom",

          customBodyRender: (value) => {
            let newValue = "";
            if (value) {
              newValue = moment(new Date(value)).format("MMM DD YYYY HH:mm:ss");
            }
            return newValue;
          },

          // if the below value is set, these values will be used every time the table is rendered.
          // it's best to let the table internally manage the filterList
          //filterList: [25, 50],

          customFilterListOptions: {
            render: (v) => {
              if (v[0]) {
                return `Publishing Time: ${v[0]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              if (filters[0]) {
                return [date] < filters[0] || [date] > filters[0];
              } else {
                return false;
              }
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel style={{ color: "#3c3c3c", fontSize: "12px" }}>
                  Publishing Time
                </FormLabel>
                <br />

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth={true}
                  value={
                    filterList[index][0] ? (
                      filterList[index][0]
                    ) : (
                      <div style={{ color: "gray" }}>
                        Select Publishing Time
                      </div>
                    )
                  }
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  input={<OutlinedInput id="" />}
                  displayEmpty={true}
                  renderValue={(selected) =>
                    selected == "" ? (
                      <div style={{ color: "gray" }}>Publishing Time</div>
                    ) : (
                      selected
                    )
                  }
                >
                  {sortDate.map((date) => (
                    <MenuItem key={date} value={date}>
                      <ListItemText primary={date} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ),
          },
          print: false,
        },
      },
      {
        name: "Starting Time",
        label: "Starting Time",
        options: {
          filter: true,
          sort: true,
          filterType: "custom",

          customBodyRender: (value) => {
            let newValue = "";
            if (value) {
              newValue = moment(new Date(value)).format("MMM DD YYYY HH:mm:ss");
            }
            return newValue;
          },

          // if the below value is set, these values will be used every time the table is rendered.
          // it's best to let the table internally manage the filterList
          //filterList: [25, 50],

          customFilterListOptions: {
            render: (v) => {
              if (v[0]) {
                return `Starting Time: ${v[0]}`;
              }
              return [];
            },
            update: (filterList, filterPos, index) => {
              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1, "");
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(date, filters) {
              if (filters[0]) {
                return [date] < filters[0] || [date] > filters[0];
              } else {
                return false;
              }
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel style={{ color: "#3c3c3c", fontSize: "12px" }}>
                  Starting Time
                </FormLabel>
                <br />

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth={true}
                  value={
                    filterList[index][0] ? (
                      filterList[index][0]
                    ) : (
                      <div style={{ color: "gray" }}>Select Starting Time</div>
                    )
                  }
                  onChange={(event) => {
                    filterList[index][0] = event.target.value;
                    onChange(filterList[index], index, column);
                  }}
                  input={<OutlinedInput id="" />}
                  displayEmpty={true}
                  renderValue={(selected) =>
                    selected == "" ? (
                      <div style={{ color: "gray" }}>Starting Time</div>
                    ) : (
                      selected
                    )
                  }
                >
                  {sortStartDate.map((date) => (
                    <MenuItem key={date} value={date}>
                      <ListItemText primary={date} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ),
          },
          print: false,
        },
      },

      {
        name: "Action",
        label: "Action",
        options: {
          customBodyRender: (props, data) => {
            let rowDataValue = data.rowData[0];
            let rowData = data.rowData;

            return (
              <div>
                <Button
                  aria-describedby={id}
                  variant="contained"
                  color="primary"
                  onClick={(e) => this.handleClick(e, rowDataValue, rowData)}
                >
                  Actions
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.actions}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => this.showAlertDialog(true, currentId)}
                    >
                      Items
                    </Button>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.editHandler(true, currentId, editData)
                      }
                    >
                      Edit
                    </Button>
                    {this.state.stopEvent === true ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() =>
                          this.stopEventHandler(true, currentId, editData)
                        }
                      >
                        Stop Event
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        this.showAlertDialogDelete(true, currentId)
                      }
                    >
                      Delete
                    </Button>

                    <input id="bulkUploadFileSelecterInput" accept=".csv" type="file" hidden onChange={(e) => {
                      this.props.bulkUploadItems(currentId, e)
                    }} />
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        document.getElementById('bulkUploadFileSelecterInput').click();
                      }}
                    >
                      Bulk Upload Items
                    </Button>
                  </div>
                </Popover>
              </div>
            );
          },
        },
      },
    ];

    const options = {
      // filterType: 'checkbox',
      downloadOptions: {
        filename: "Events.csv",
        separator: ";",
        filterOptions: {
          useDisplayedRowsOnly: true,
          useDisplayedColumnsOnly: true,
        },
      },
    };

    return ls.get("AclGetEventAlloweddata") === true &&
      isAllowed === "allowed" ? (
      <>
        {this.state.show && (
          <AlertDialog
            maxWidth={"lg"}
            open={this.state.show}
            showTitle={false}
            hideApplyButton={true}
            message={
              <ViewItems
                currentId={this.state.currentId}
                showAlertDialog={this.showAlertDialog}
              />
            }
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialog(false)}
            closeAlertDialog={() => this.showAlertDialog(false)}
            applyAlertDialog={() => this.showAlertDialog(false)}
          />
        )}
        {this.state.showDelete && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showDelete}
            showTitle={true}
            title={"Delete Event"}
            message={"Are you sure want to delete this Event?"}
            cancelButtonText={"Close"}
            cancelAlertDialog={() => this.showAlertDialogDelete(false)}
            closeAlertDialog={() => this.showAlertDialogDelete(false)}
            applyAlertDialog={() => this.deleteProfileHandler()}
          />
        )}
        {this.state.showEdit && (
          <AlertDialog
            maxWidth={"sm"}
            open={this.state.showEdit}
            showTitle={true}
            title={"Edit Auction Event"}
            message={<EditAuctionEvent currentId={this.state.currentId} />}
            cancelButtonText={"Close"}
            hideApplyButton={true}
            cancelAlertDialog={() => this.showEditAuctionEventDialog(false)}
            closeAlertDialog={() => this.showEditAuctionEventDialog(false)}
            applyAlertDialog={() => this.showEditAuctionEventDialog(false)}
          />
        )}
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <div>
              <NavLink to={"/user/create-event"} className={classes.createlink}>
                Add Event
              </NavLink>
            </div>

            <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
              <div className={classes.selectLang}>
                <Grid item xs={12} md={12}>
                  <div className="fieldwrapper">
                    {/* <span>{fields.EventLanguages.label}</span> */}
                    <ReactSelectValidator
                      id={fields.EventLanguages.name}
                      name={fields.EventLanguages.name}
                      value={languageField.value}
                      placeholder={fields.EventLanguages.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        this.selectFieldChangeHandler(
                          fields.EventLanguages.name,
                          value
                        );
                        this.setState({ languageCode: value });
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      isSearchable={true}
                      options={fields.EventLanguages.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                {/* <Grid item xs={12} md={12} className={classes.gridSelectLang}>
                            <div className="fieldwrapper">

                            <ReactSelectValidator
                                id={fields.Language.name}
                                name={languageField.name}
                                value={languageField.value}
                                placeholder={fields.Language.placeholder}
                                validations={true}
                                fullWidth={true}
                                onChange={value => {
                                    this.selectFieldChangeHandler(
                                        fields.Language.name,
                                        value
                                    );
                                    this.setState({languageCode: value.name})
                                }}
                                // validators={["required"]}
                                // errorMessages={["Required"]}
                                options={fields.Language.options}
                                className="selectfield"
                            />
                            </div>

                        </Grid> */}
              </div>
            </ValidatorForm>

            <p>View all Auction Events</p>
            <MUIDataTable
              title={"All Auction Events"}
              data={allEvents}
              columns={columns}
              options={options}
            />
            <br />
            {eventStopErrorsAlert !== "" ? (
              <Alert severity="error">{eventStopErrorsAlert}</Alert>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    ) : (
      <>
        {" "}
        {isAllowed === "loading" ? (
          <ErrorMessage message="loading" />
        ) : (
          <ErrorMessage message="You are not allowed to see this page" />
        )}
      </>
    );
  }
}

export default withStyles(styles)(ViewAllEvents);
