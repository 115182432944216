import instance, { EVENT_URL } from "../../../api/config";

export const PROFILE_SAVE_ALL_TIMELINE_PROFILES =
  "PROFILE_SAVE_ALL_TIMELINE_PROFILES";
export const PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER =
  "PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER";
export const PROFILE_TIMELINE_PAGE_SELECT_CHANGE_HANDLER =
  "PROFILE_TIMELINE_PAGE_SELECT_CHANGE_HANDLER";

export const TIMELINE_PAGE_TEXT_CHANGE_HANDLER =
  "TIMELINE_PAGE_TEXT_CHANGE_HANDLER";
export const TIMELINE_PAGE_SELECT_CHANGE_HANDLER =
  "TIMELINE_PAGE_SELECT_CHANGE_HANDLER";
export const TIMELINE_PAGE_SAVE_PROFILE_IDS_HANDLER =
  "TIMELINE_PAGE_SAVE_PROFILE_IDS_HANDLER";
export const TIMELINE_EDIT_GET_ID = "TIMELINE_EDIT_GET_ID";
export const VIEW_TIMELINE_EDIT_TEXT_CHANGE_HANDLER =
  "VIEW_TIMELINE_EDIT_TEXT_CHANGE_HANDLER";

export const UPDATE_TIMELINE_PROFILE = "UPDATE_TIMELINE_PROFILE";
export const SET_UPDATE_TIMELINE_PROFILE = "SET_UPDATE_TIMELINE_PROFILE";
export const TIMELINE_UPDATE_ERROR_MESSAGE_HANDLER =
  "TIMELINE_UPDATE_ERROR_MESSAGE_HANDLER";

export const TIMELINE_PAGE_SELECT_CHANGE_HANDLER_EDIT =
  "TIMELINE_PAGE_SELECT_CHANGE_HANDLER_EDIT";
export const TIMELINE_SAVE_ERROR_MESSAGE_HANDLER =
  "TIMELINE_SAVE_ERROR_MESSAGE_HANDLER";
export const TIMELINE_PAGE_EDIT_SELECT_CHANGE_HANDLER =
  "TIMELINE_PAGE_EDIT_SELECT_CHANGE_HANDLER";
export const TIMELINE_PROFILE_EDIT_TEXT_CHANGE_HANDLER =
  "TIMELINE_PROFILE_EDIT_TEXT_CHANGE_HANDLER";
export const TILELINE_DELETE_SESSION_SAVE = "TILELINE_DELETE_SESSION_SAVE";
export const TILELINE_VIEW_UPDATE_SESSION_SAVE =
  "TILELINE_VIEW_UPDATE_SESSION_SAVE";

export const saveALLTimelineProfiles = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [
      item.id,
      item.timelineProfileName,
      String(item.isTimelineProfileDynamic),
      item.bidsCountForGoingToNextStage,
      item.bidsCountForGoingToPreviousStage,
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_TIMELINE_PROFILES,
    payload: newArray,
  });
};

export const textFieldChangeHandler_PT = (name, value) => (dispatch) => {
  dispatch({
    type: PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler_PT = (name, value) => (dispatch) => {
  dispatch({
    type: PROFILE_TIMELINE_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textFieldChangeHandler_T = (name, value) => (dispatch) => {
  dispatch({
    type: TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler_T = (name, value) => (dispatch) => {
  dispatch({
    type: TIMELINE_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler_Edit = (name, value) => (dispatch) => {
  dispatch({
    type: TIMELINE_PAGE_SELECT_CHANGE_HANDLER_EDIT,
    payload: value,
    key: name,
  });
};

export const saveTimelineProfileIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.timelineProfileName };
  });
  dispatch({
    type: TIMELINE_PAGE_SAVE_PROFILE_IDS_HANDLER,
    payload: [...newValue],
  });
};

export const editTimelineChangeHandler = (value, name) => (dispatch) => {
  instance
    .get(EVENT_URL + "timeline-profile/timeline-profiles/" + value)
    .then((res) => {
      let data = res.data.data;

      const timelineProfileName = { value: data.timelineProfileName };
      dispatch({
        type: PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
        payload: timelineProfileName,
        key: "TimelineProfileName",
      });

      const isDynamic = {
        value: data.isTimelineProfileDynamic ? 1 : 0,
        label: data.isTimelineProfileDynamic ? "true" : "false",
      };
      dispatch({
        type: TIMELINE_PAGE_EDIT_SELECT_CHANGE_HANDLER,
        payload: isDynamic,
        key: "IsDynamic",
      });

      const bidsCountForToNext = { value: data.bidsCountForGoingToNextStage };
      dispatch({
        type: PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
        payload: bidsCountForToNext,
        key: "BidsCountForToNext",
      });

      const bidsCountForPrevious = {
        value: data.bidsCountForGoingToPreviousStage,
      };
      dispatch({
        type: PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
        payload: bidsCountForPrevious,
        key: "BidsCountForPrevious",
      });
    })
    .catch((error) => {});

  dispatch({
    type: TIMELINE_PROFILE_EDIT_TEXT_CHANGE_HANDLER,
    payload: name[1],
    key: value,
  });
};

export const editViewTimelineChangeHandler =
  (value, current_id, name) => (dispatch) => {
    dispatch({
      type: TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[0],
      key: "BidStart",
    });
    dispatch({
      type: TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[1],
      key: "BidEnd",
    });
    dispatch({
      type: TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[2],
      key: "DurationFirstRound",
    });
    dispatch({
      type: TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[3],
      key: "DurationSecondRound",
    });
    dispatch({
      type: TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[4],
      key: "DurationThirdRound",
    });
    dispatch({
      type: VIEW_TIMELINE_EDIT_TEXT_CHANGE_HANDLER,
      payloadBidStart: name[1],
      payloadBidEnd: name[2],
      payloadDurationFirst: name[3],
      payloadDurationSecond: name[4],
      payloadDurationThird: name[5],
      key: value,
      viewCurrentKey: current_id,
    });
  };
export const UpdateTimelineProfile = (id, data) => (dispatch) => {
  instance
    .put(EVENT_URL + "timeline-profile/timeline-profiles/update/" + id, data)
    .then((res) => {
      window.location.replace("/user/all-profiletimeline");
    })
    .catch((error) => {});
};

export const updateTimelineViewProfile =
  (id, currentID, data) => (dispatch) => {
    const requestEditUrl =
      EVENT_URL + "timeline-profile/timeline/update/" + currentID + "/" + id;
    instance
      .put(requestEditUrl, data)
      .then((res) => {
        dispatch({
          type: TIMELINE_UPDATE_ERROR_MESSAGE_HANDLER,
          payload: res.data.description,
        });
        window.location.replace("/user/all-profiletimeline");
      })
      .catch((error) => {
        const { response } = error;
        dispatch({
          type: TIMELINE_UPDATE_ERROR_MESSAGE_HANDLER,
          payload: response.data.description,
        });
      });
  };

export const saveTimelineError = (value) => (dispatch) => {
  dispatch({
    type: TIMELINE_SAVE_ERROR_MESSAGE_HANDLER,
    payload: value,
  });
};

export const saveDeleteResponseMsg = (sessionID, status) => (dispatch) => {
  dispatch({
    type: TILELINE_DELETE_SESSION_SAVE,
    payload: sessionID,
    payloadDeleteTimelineMessage: status,
  });
};

export const saveUpdateViewResponseMsg = (sessionID, status) => (dispatch) => {
  dispatch({
    type: TILELINE_VIEW_UPDATE_SESSION_SAVE,
    payload: sessionID,
    payloadUploadTimelineViewMessage: status,
  });
};
