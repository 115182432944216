import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import BusinessIcon from "@material-ui/icons/Business";
import PeopleIcon from "@material-ui/icons/People";
import SettinsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const user_list = [
	{
		label: "Dashboard",
		icon: <HomeIcon />,
		url: "/user"
	},
	{
		label: "Event Management",
		icon: <BusinessIcon />,
		url: "/",
		children: [
			{
			    label: "Timeline Profile",
				icon: <AccountTreeIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "Create Timeline Profile",
						icon: <AccountTreeIcon />,
						isSubMenu: true,
						url: "/user/create-timelinepro",
						secretUrl: '/timeline-profile/timeline-profiles/create'
					},
					{
						label: "Create Timeline",
						icon: <AccountTreeIcon />,
						isSubMenu: true,
						url: "/user/create-timeline",
						secretUrl: '/timeline-profile/timeline/create'
					},
					{
						label: "View Timeline Profile",
						icon: <AccountTreeIcon />,
						isSubMenu: true,
						url: "/user/all-profiletimeline",
						secretUrl: '/timeline-profile/timeline-profiles'
					}
				],
			},
			{
				label: "Biding Profile",
				icon: <PersonAddIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "Create Increment Profile",
						icon: <PersonAddIcon />,
						isSubMenu: true,
						url: "/user/create-biddingpro",
						secretUrl: '/bidding-increment-profile/bidding-increment-profiles/create'
					},
					{
						label: "Create Increment",
						icon: <PersonAddIcon />,
						isSubMenu: true,
						url: "/user/create-biddinginc",
						secretUrl: '/bidding-increment-profile/bidding-increment/create'
					},
					{
						label: "All Increment Profiles",
						icon: <PersonAddIcon />,
						isSubMenu: true,
						url: "/user/all-profilebidding",
						secretUrl: '/bidding-increment-profile/bidding-increment-profiles'
					}
				],
			},
			{
				label: "Event Manager",
				icon: <EventNoteIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "Create Event",
						icon: <EventNoteIcon />,
						isSubMenu: true,
						url: "/user/create-event",
						secretUrl: '/auction-event/auction-events/create'
					},
					{
						label: "View All Events",
						icon: <EventNoteIcon />,
						isSubMenu: true,
						url: "/user/all-events",
						secretUrl: '/auction-event/auction-events'
					},
				],
			}
		]
	},
	{
		label: "User Management",
		icon: <PeopleIcon />,
		url: "/",
		children: [
			{
			    label: "Account Type",
				icon: <PermIdentityIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "Create Account Type",
						icon: <PermIdentityIcon />,
						isSubMenu: true,
						url: "/user/create-usertype",
						secretUrl: '/core-account-types/addAccountType'
					},
					{
						label: "View All Account Types",
						icon: <PermIdentityIcon />,
						isSubMenu: true,
						url: "/user/all-accounttype",
						secretUrl: '/core-account-types/getAccountTypes'
					}
				],
			},
			// {
			//     label: "Accounts",
			// 	icon: <PermIdentityIcon />,
			// 	url: "/",
			// 	isFirst: true,
			// 	children: [
			// 		{
			// 			label: "Create Account",
			// 			icon: <PermIdentityIcon />,
			// 			isSubMenu: true,
			// 			url: "/user/create-account",
			// 			secretUrl: '/account-management/addAccount'
			// 		},
			// 		{
			// 			label: "View All Accounts",
			// 			icon: <PermIdentityIcon />,
			// 			isSubMenu: true,
			// 			url: "/user/all-accounts",
			// 			secretUrl: '/account-management/getAllAccounts'
			// 		}
			// 	],
			// },
			{
			    label: "Users",
				icon: <AccountCircleIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "Create User",
						icon: <AccountCircleIcon />,
						isSubMenu: true,
						url: "/user/create-useraccount",
						secretUrl: ''
					},
					{
						label: "View All Users",
						icon: <AccountCircleIcon />,
						isSubMenu: true,
						url: "/user/all-useraccount",
						secretUrl: '/user-management/addUser'
					}
				],
			},
			{
			    label: "Permissions",
				icon: <VpnKeyIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All List",
						icon: <VpnKeyIcon />,
						isSubMenu: true,
						url: "/user/all-permission",
						secretUrl: ''
					}
				],
			}
		]
	},
	{
		label: "Buyer Management",
		icon: <ShoppingCartIcon />,
		url: "/",
		children: [
			{
			    label: "Buyers",
				icon: <AddShoppingCartIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All Buyers",
						icon: <AddShoppingCartIcon />,
						isSubMenu: true,
						url: "/user/all-buyers",
						secretUrl: '/auction-buyer/getBuyers'
					}
				],
			}
		]
	},
	{
		label: "Seller Management",
		icon: <ShoppingCartIcon />,
		url: "/",
		children: [
			{
			    label: "Seller",
				icon: <AddShoppingCartIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "Add Seller",
						icon: <AddShoppingCartIcon />,
						isSubMenu: true,
						url: "/user/create-seller",
						secretUrl: '/auction-seller/addSeller'
					},
					{
						label: "View All Seller",
						icon: <AddShoppingCartIcon />,
						isSubMenu: true,
						url: "/user/view-allseller",
						secretUrl: '/auction-seller/auction-sellers'
					}
				],
			}
		]
	},
	{
		label: "Reports Management",
		icon: <LocalLibraryIcon />,
		url: "/",
		children: [
			{
			    label: "Login Reports",
				icon: <MenuBookIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All Login Report",
						icon: <MenuBookIcon />,
						isSubMenu: true,
						url: "/user/view-loginreport",
						secretUrl: '/auction-event/auction-events'
					}
				],
			},
			{
			    label: "Items Reports",
				icon: <MenuBookIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All Item Report",
						icon: <MenuBookIcon />,
						isSubMenu: true,
						url: "/user/view-itemreport",
						secretUrl: '/auction-event/auction-events'
					}
				],
			},
			{
			    label: "Event Reports",
				icon: <MenuBookIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All Event Report",
						icon: <MenuBookIcon />,
						isSubMenu: true,
						url: "/user/view-eventreport",
						secretUrl: '/auction-event/auction-events'
					}
				],
			},
			{
			    label: "Transaction Reports",
				icon: <MenuBookIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All Transactions Report",
						icon: <MenuBookIcon />,
						isSubMenu: true,
						url: "/user/view-transactionreport",
						secretUrl: '/auction-event/auction-events'
					}
				],
			},
			{
			    label: "Error Reports",
				icon: <MenuBookIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All Error Reports",
						icon: <MenuBookIcon />,
						isSubMenu: true,
						url: "/user/view-errorreport",
						secretUrl: '/auction-event/auction-events'
					}
				],
			},
			{
			    label: "NewsLetter",
				icon: <MailOutlineIcon />,
				url: "/",
				isFirst: true,
				children: [
					{
						label: "View All NewsLetter Report",
						icon: <MailOutlineIcon />,
						isSubMenu: true,
						url: "/user/view-newsLetter",
						secretUrl: '/auction-event/auction-events'
					}
				],
			},
		]
	},			
	{
		label: "Languages",
		icon: <SettinsIcon />,
		url: "/user/languages"
	},
	{
		label: "Logout",
		icon: <ExitToAppIcon />,
		url: "/user/logout"
	}
];

export default user_list;
