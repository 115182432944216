import { connect } from "react-redux";
import ChangePriority   from "./component";

const mapStateToProps = state => ({
    editViewData: state.EVENT.editViewData,
    fields: state.EVENT.forms.auctionEventForm.fields,
    itemsFields: state.CONTROLLER.forms.ItemsForm.fields,
    updateEventItemError: state.EVENT.UISettings.updateEventItemError

})

const mergeProps = (stateProps, ownProps) => ({
    ...stateProps,
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mergeProps
)(ChangePriority);