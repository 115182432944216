import {
    EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
    EVENT_MANAGER_PAGE_EDIT_TEXT_CHANGE_HANDLER,
    EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
    EVENT_EDIT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
    EVENT_MANAGER_SAVE_AUCTIONS_HANDLER,
    EVENT_MANAGER_SAVE_ID_HANDLER,
    EVENT_BIDDING_INC_SAVE_ID_HANDLER,
    EVENT_TIMLINE_SAVE_ID_HANDLER,
    EVENT_EDIT_TEXT_CHANGE_HANDLER,
    EVENT_EDIT_BIDDING_INC_SAVE_ID_HANDLER,
    SET_UPDATE_EVENT_PROFILE,
    VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER,
    UPDATE_ITEM_EDIT_ERROR_SAVE_HANDLER,
    EVENT_UPDATE_SESSION_SAVE,
    TEASER_IMAGE_UPLOAD_HANDLER,
    UPDATE_TEASER_IMAGE_UPLOAD_HANDLER,
    CREATE_EVENT_ERROR_HANDLER,
    EVENT_LANGUAGE_SAVE_ID_HANDLER,
    STOP_EVENT_ERROR_HANDLER
} from "./actions";

export const auctionEventReducer = (state = [], action) => {
    switch (action.type) {

        case EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            [action.key]: {
                                ...state.forms.auctionEventForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case EVENT_MANAGER_PAGE_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editforms: {
                    ...state.editforms,
                    auctionEventForm: {
                        ...state.editforms.auctionEventForm,
                        fields: {
                            ...state.editforms.auctionEventForm.fields,
                            [action.key]: {
                                ...state.editforms.auctionEventForm.fields[action.key],
                                EventName:action.payload
                            }
                        }
                    }
                }
            };
        }

        case VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editViewData: {
                    ...state.editData,
                    editDataId: action.key,
                    editCurrentDataId: action.viewCurrentKey,
                    edititemName:action.payloaditemName,
                    editheroItem:action.payloadheroItem,
                    edititemEbayIdentifier:action.payloaditemEbayIdentifier,
                    editpublishingTime:action.payloadpublishingTime,
                    edittopItem:action.payloadtopItem,

                }
            }
        }

        case EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            [action.key]: {
                                ...state.forms.auctionEventForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case EVENT_EDIT_MANAGER_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                editforms: {
                    ...state.editforms,
                    auctionEventForm: {
                        ...state.editforms.auctionEventForm,
                        fields: {
                            ...state.editforms.auctionEventForm.fields,
                            [action.key]: {
                                ...state.editforms.auctionEventForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case EVENT_MANAGER_SAVE_AUCTIONS_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allEvents: [...action.payload]
                }
            }
        }

        case EVENT_MANAGER_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            EventTimelineProfileId: {
                                ...state.forms.auctionEventForm.fields.EventTimelineProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }


        case EVENT_TIMLINE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            EventTimelineProfileId: {
                                ...state.forms.auctionEventForm.fields.EventTimelineProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case EVENT_BIDDING_INC_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            BiddingIncProfileId: {
                                ...state.forms.auctionEventForm.fields.BiddingIncProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case SET_UPDATE_EVENT_PROFILE: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    [action.key]: {
                        ...state.editData[action.key],
                        ...action.payload
                    }
                }
            };
        }

        case EVENT_EDIT_BIDDING_INC_SAVE_ID_HANDLER: {
            return {
                ...state,
                editforms: {
                    ...state.editforms,
                    auctionEventForm: {
                        ...state.editforms.auctionEventForm,
                        fields: {
                            ...state.editforms.auctionEventForm.fields,
                            BiddingIncProfileId: {
                                ...state.editforms.auctionEventForm.fields.BiddingIncProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case EVENT_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    // editDataHeadline:{
                    //     editDataHeadline:action.payloadHeadline
                    // },
                    
                    editDataId: action.key,
                    editDataName:action.payload,
                    editDataHeadline:action.payloadHeadline,
                    editDataDescription:action.payloadDescription,
                    editDataTeaser:action.payloadTeaser,
                    editDataBbevent:action.payloadBbevent,
                    editDataLiveStream:action.payloadLiveStream,
                    editDataMainEvent:action.payloadMainEvent,
                    editDataMajorColor:action.payloadMajorColor,
                    editDataBannerColor:action.payloadMinorColor,
                    editDataStartPrice:action.payloadStartPrice
                },
                editforms: {
                    ...state.editforms,
                    auctionEventForm: {
                        ...state.editforms.auctionEventForm,
                        fields: {
                            ...state.editforms.auctionEventForm.fields.EventDescription,
                            EventDescription:{
                                EventDescription:action.payloadDescription
                            },
                            ...state.editforms.auctionEventForm.fields,
                            [action.key]: {
                                ...state.editforms.auctionEventForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            }
        }

        case UPDATE_ITEM_EDIT_ERROR_SAVE_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    updateEventItemError: action.value
                }
            };
        }

        case EVENT_UPDATE_SESSION_SAVE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    updateEventResponseMsg: action.payload,
                    eventUpdateStatus: action.payloadUpdateEventMessage
                }
            }
        }

        case TEASER_IMAGE_UPLOAD_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    teaserImageUploadUrl: action.payload
                }
            }
        }

        case CREATE_EVENT_ERROR_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    eventCreateErrorsAlert: action.payload
                }
            }
        }

        case STOP_EVENT_ERROR_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    eventStopErrorsAlert: action.payload
                }
            }
        }

        case UPDATE_TEASER_IMAGE_UPLOAD_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    updateteaserImageUploadUrl: action.payload
                }
                // ...state,
                // editforms: {
                //     ...state.editforms,
                //     auctionEventForm: {
                //         ...state.editforms.auctionEventForm,
                //         fields: {
                //             ...state.editforms.auctionEventForm.fields,
                //             UpdateTeaserImage: action.payload
                //         }
                //     }
                // }
            }
        }

        case EVENT_LANGUAGE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    auctionEventForm: {
                        ...state.forms.auctionEventForm,
                        fields: {
                            ...state.forms.auctionEventForm.fields,
                            EventLanguages: {
                                ...state.forms.auctionEventForm.fields.EventLanguages,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}