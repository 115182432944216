import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import instance, { EVENT_URL } from "../../../../../../../api/config";
import ls from "local-storage";
import Alert from "@material-ui/lab/Alert";
import ReactSelectValidator from "../../../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import TextEditor from "../../../../../../common/TextEditor/component";

class ChangePriority extends Component {
  state = {
    currentPriority: null,
    selectPriority: null,
    itemsOptions: [],
  };

  SubmitHandler = () => {
    const { editViewData : {editDataId} } = this.props;
    const {
      currentPriority,
      selectPriority
    } = this.state;

    let data = {
      sequencePlaceWhere: currentPriority,
      sequenceToBeChange: selectPriority
    };

    // api call
  const requestEditUrl = EVENT_URL + "auction-event/auction-event-items/update-sequence/" + editDataId

  if(selectPriority != null){
    instance
    .put(requestEditUrl, data)
    .then((res) => {
      console.log("update View Item Event", res);
      window.location.replace("/user/all-events");
    })
    .catch((error) => {
      const { response } = error;
        console.log(response.data.description)
    });
  } else {
    window.location.replace("/user/all-events");
  }
};

  componentDidMount = () => {
    const {currentPrioritySequence, itemsLength} = this.props;
    this.setState({
      currentPriority : currentPrioritySequence
    });

    const itemsLengthArray = [...Array(itemsLength).keys()]
    const newItemsObjectLengthArray = itemsLengthArray.map(item => {
      return {
        label: item+1,
        value: item+1
      }
    })

    this.setState({
      itemsOptions: newItemsObjectLengthArray.filter(item => item.label !== currentPrioritySequence)
    })

  };


  textChangeHandlerDropdown = (name, value) => {
    if (name === "marketPlace") {
      this.setState({
        [name]: value ? value : "",
      });
    } else {
      this.setState({
        [name]: value ? value.label : "",
      });
    }
  };

  render() {
    const { classes, updateEventItemError} = this.props;
  

    const {
      selectPriority
    } = this.state;

    return (
      <div className={classes.editmainrapper}>
        <div className={classes.editfieldswrapper}>
          <p>Change Priority</p>

          <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
            <Grid container>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Current Priorty Sequence</span>
                  <CustomTextValidator
                    id={this.state.currentPriority}
                    fullWidth={true}
                    type="text"
                    name={this.state.currentPriority}
                    value={this.state.currentPriority}
                    disabled={true}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Select Priorty Sequence</span>
                  <ReactSelectValidator
                    id={selectPriority}
                    fullWidth={true}
                    type="text"
                    name={selectPriority}
                    placeholder={selectPriority}
                    validations={true}
                    onChange={(value) => {
                      this.textChangeHandlerDropdown("selectPriority", value);
                    }}
                    options={this.state.itemsOptions}
                    className="selectfield"
                  />
                </div>
              </Grid>


              {updateEventItemError ? (
                <Alert severity="error">{updateEventItemError}</Alert>
              ) : null}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className="submitbtn"
                  type="submit"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ChangePriority);
