import { connect } from "react-redux";
import ViewAllEvents from "./component";
import instance, {
  EVENT_URL,
  BUYER_SELLER_URL,
} from "../../../../../api/config";
import ls from "local-storage";
import {
  saveALLAuctionEvents,
  editEventChangeHandler,
  stopEventChangeHandler,
  selectFieldChangeHandler,
  saveGetLanguages,
} from "../actions";
import Papa from 'papaparse';

import moment from "moment";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";


dayjs.extend(customParseFormat)

const mapStateToProps = (state) => ({
  allEvents: state.EVENT.data.allEvents,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
  fields: state.EVENT.forms.auctionEventForm.fields,
  eventStopErrorsAlert: state.EVENT.data.eventStopErrorsAlert,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (id) => {
    const eventLanguageCode = ls.get("eventLanguageCode");
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Loading Events...", "small")
    );

    let perData = {
      params: {
        languageCode: eventLanguageCode ? eventLanguageCode : "en",
      },
    };

    instance
      .get(EVENT_URL + "auction-event/auction-events", perData)
      .then((res) => {
        dispatch(saveALLAuctionEvents(res.data.data));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  deleteProfileHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Bidding Profile",
        "small"
      )
    );
    instance
      .delete(EVENT_URL + "auction-event/auction-events/remove/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editEventChangeHandler: (value, name) => {
    dispatch(editEventChangeHandler(value, name));
  },
  stopEventChangeHandler: (id, data) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Stoping Event", "small")
    );

    let value = {
      params: {
        eventId: id,
      },
    };
    instance
      .get(BUYER_SELLER_URL + "reports/endEvent/", value)
      .then((res) => {
        dispatch(stopEventChangeHandler(res.data));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/auction-event/auction-events"));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
  getLanguages: () => {
    instance
      .get(EVENT_URL + "core/core-languages")
      .then((res) => {
        dispatch(saveGetLanguages(res.data.data));
      })
      .catch((error) => {});
  },
  bulkUploadItems: async (currentId, e) => {
    const selectedFile = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsText(selectedFile, "ISO-8859-1");
    reader.onload = async (evt) => {
      const result = evt.target.result;
      const languageCodeLookup = {
        "EBAY_DE": "de",
        "EBAY_FR": "fr",
        "EBAY_UK": "uk",
      };
      try {
        const parsedFile = Papa.parse(result, {
          delimiter: "",	// auto-detect
          newline: "",	// auto-detect
          quoteChar: '"',
          escapeChar: '"',
          header: true,
          transformHeader: undefined,
          dynamicTyping: true,
          preview: 0,
          encoding: 'ISO-8859-1',
          worker: false,
          comments: false,
          step: undefined,
          complete: undefined,
          error: undefined,
          download: false,
          downloadRequestHeaders: undefined,
          downloadRequestBody: undefined,
          skipEmptyLines: true,
          chunk: undefined,
          chunkSize: undefined,
          fastMode: undefined,
          beforeFirstChunk: undefined,
          withCredentials: undefined,
          transform: undefined,
          delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
        });
        console.log('bulkUploadItems', currentId, parsedFile);
        const parsedData = parsedFile.data;
        const payload = [];

        let error = null;

        for (let i = 0; i < parsedData.length; i++) {
          const row = parsedData[i];
          const rowNumber = i + 1;
          if (!row.itemName || !row.itemName.length) {
            console.log('itemName is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid value for item name.`;
            break;
          }

          if (!row.itemEbayIdentifier || !row.itemEbayIdentifier.length) {
            console.log('itemEbayIdentifier is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid value for itemEbayIdentifier.`;
            break;
          }

          if (!row.publishingTimeStamp || !row.publishingTimeStamp.length || !dayjs(row.publishingTimeStamp, 'YYYY-MM-DD').isValid()) {
            console.log('publishingTimeStamp is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid Publishing Time Stamp following this format (YYYY-MM-DD).`;
            break;
          }

          if (!row.startingPrice) {
            console.log('startingPrice is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid Starting Price.`;
            break;
          }

          if (!row.minimumPrice) {
            console.log('minimumPrice is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid Minimum Price.`;
            break;
          }

          if (typeof row.minimumPriceCheck !== 'boolean') {
            console.log('minimumPriceCheck is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid Minimum Price Check.`;
            break;
          }

          if (!row.marketValueEstimate) {
            console.log('marketValueEstimate is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid Market Value Estimate.`;
            break;
          }

          if (!row.marketValueEstimateFrontend) {
            console.log('marketValueEstimateFrontend is required', row.marketValueEstimateFrontend);
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid 'marketValueEstimateFrontend'.`;
            break;
          }

          if (typeof row.topItem !== 'boolean') {
            console.log('topItem is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid 'topItem' value.`;
            break;
          }

          const marketPlaceValidValues = ['EBAY_DE', 'EBAY_FR', 'EBAY_UK'];
          if (!row.marketPlace && !marketPlaceValidValues.includes(row.marketPlace)) {
            console.log('marketPlace is required');
            error = `Wrong value at row # ${rowNumber} - Kindly add a valid 'marketPlace' value. These are valid values 'EBAY_DE', 'EBAY_FR', 'EBAY_UK'`;
            break;
          }

          let data = {
            ...row,
            itemName: row.itemName ? row.itemName : "",
            minimumPriceCheck: row.minimumPriceCheck,
            topItem: row.topItem,
            isActive: row.isActive,
            publishingTimeStamp: moment(row.publishingTimeStamp).valueOf(),
            languageCode: row.marketPlace && row.marketPlace.length ? languageCodeLookup[row.marketPlace] : "",
            marketPlace: row.marketPlace === 'EBAY_UK' ? "EBAY_GB" : row.marketPlace,
          };
          payload.push(data);
        }

        if (error) {
          alert(error);
          return;
        }

        const response = await instance.post(EVENT_URL + 'auction-event/auction-event-items/bulk-create-item', payload, {params:{eventId: currentId}});

        // console.log({response});
        window.location.replace("/user/all-events");
      } catch (err) {
        console.info(err);
      }
    }
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllEvents);
