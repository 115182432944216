import { connect } from "react-redux";
import ViewAllBiddingProfiles from "./component";
import instance, { EVENT_URL } from "../../../../../api/config";
import {
  saveALLBiddingProfiles,
  editBiddingChangeHandler,
  saveDeleteResponseMsg,
} from "../actions";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  allProfiles: state.BIDDING.data.allBiddingProfiles,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
  deleteBiddingResponseMsg: state.BIDDING.data.deleteBiddingResponseMsg,
  biddingDeleteStatus: state.BIDDING.data.biddingDeleteStatus,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (id) => {
    instance
      .get(EVENT_URL + "bidding-increment-profile/bidding-increment-profiles")
      .then((res) => {
        dispatch(saveALLBiddingProfiles(res.data.data));
      })
      .catch((err) => {});
  },
  deleteProfileHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Bidding Profile",
        "small"
      )
    );
    instance
      .delete(
        EVENT_URL +
          "bidding-increment-profile/bidding-increment-profiles/remove/" +
          id
      )
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(saveDeleteResponseMsg(id, err.response.data.description));
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editBiddingChangeHandler: (value, name) => {
    dispatch(editBiddingChangeHandler(value, name));
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(
      checkIfUrlIsAllowed(
        "/bidding-increment-profile/bidding-increment-profiles"
      )
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllBiddingProfiles);
