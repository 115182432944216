import BG from "../../../../../resources/images/landingbg.svg";

export const styles = theme => ({
    editmainrapper: {
        background:  `url(${BG})`,
        backgroundSize: "cover",
        padding: "32px 13px",
        width: "100%",
        "& .fieldwrapper": {
            padding: "0px 13px",
        },
        "& .textfield": {
            marginTop: 13,
            marginBottom: 22,
            "& div": {
                height: "61px",
            }
        },
        "& .multilinefield": {
            width: "100%",
            marginTop: 13,
            marginBottom: 22,
            "& div": {
                display: "block",
                resize: "none",
                borderRadius: "4px !important",
                borderColor: "#dee0e2",
                paddingRight: "13px",
                paddingTop:2,
                height:107,
                "& textarea": {
                    textIndent: "0px",
                    paddingLeft: "7px",
                    paddingRight: "7px",
                    height:104
                }
            }
        },
        "& .selectfield": {
            marginTop: 13,
            "& .react-select__value-container": {
                padding: 14,
            }
        },
        "& .submitbtn": {
            margin: 13,
            height: 61,
            width: 230,
            float: "right",
        },
        "& .imageFileChangeHandler": {
            width: "100%",
            padding: "15px 0px",
            color: "rgba(0, 0, 0, 0.87)",
            border: "2px solid #ededed",
            borderRadius: "5px",
            textIndent: "15px"
        },
        "& .teaserUpdateimage": {
            margin: 13,
            maxWidth: 60,
            maxHeight: 60,
        }
    },
    editfieldswrapper: {
        background: "white",
        "& p": {
            color: "#707070",
        },
    }
});