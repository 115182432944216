import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import 'react-image-crop/dist/ReactCrop.css';
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

//Redux
import { Provider } from "react-redux";
import store from "./store/configurestore";

console.log(process.env.REACT_APP_ENV, "App Version:", 1);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
