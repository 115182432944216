import { connect } from "react-redux";
import EditAccountType from "./component";
import {
    textUpdateAccountType,
    UpdateAccountTypeProfile
} from "../actions";

const mapStateToProps = state => ({
    editFields: state.ACCOUNT_TYPE.editData,
    // fields: state.ACCOUNT_TYPE.forms.AccountTypeForm.fields
})

const mapDispatchToProps = (dispatch, props) => ({
    textUpdateTimelineProfile: (name, e) => {
        dispatch(textUpdateAccountType(name, e));
    },
  
    UpdateAccountTypeProfile: (id,data) => {
        dispatch(UpdateAccountTypeProfile(id, data));

    }
})

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    submitTimelines: () => {
        dispatchProps.submitTimelines(
            stateProps.fields.FirstName,
            stateProps.fields.LastName,
            stateProps.fields.ActivationToken
            )
    },
    ...ownProps,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(EditAccountType);