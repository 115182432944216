import instance, { EVENT_URL } from "../../../../api/config";
export const BIDDING_EDIT_ID_HANDLER = "BIDDING_EDIT_ID_HANDLER";

export const BIDDING_PROFILE_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER =
  "BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER";
export const BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER =
  "BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER";
export const BIDDING_EDIT_TEXT_CHANGE_HANDLER =
  "BIDDING_EDIT_TEXT_CHANGE_HANDLER";
export const VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER =
  "VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER";
export const PROFILE_SAVE_ALL_BIDDING_PROFILES =
  "PROFILE_SAVE_ALL_BIDDING_PROFILES";
export const BIDDING_DELETE_SESSION_SAVE = "BIDDING_DELETE_SESSION_SAVE";
export const BIDDING_VIEW_UPDATE_SESSION_SAVE =
  "BIDDING_VIEW_UPDATE_SESSION_SAVE";
export const BIDDING_INCREMENT_PROFILE_ERROR_HANDLER =
  "BIDDING_INCREMENT_PROFILE_ERROR_HANDLER";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};
export const textFieldChangeHandler_PB = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textFieldChangeHandler_I = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const saveALLBiddingProfiles = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [item.id, item.biddingIncrementProfileName, ""];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_BIDDING_PROFILES,
    payload: newArray,
  });
};

export const saveBiddingProfileIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.biddingIncrementProfileName };
  });
  dispatch({
    type: BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const editBiddingChangeHandler = (value, name) => (dispatch) => {
  dispatch({
    type: BIDDING_EDIT_TEXT_CHANGE_HANDLER,
    payload: name[1],
    key: "ProfileName",
  });
  dispatch({
    type: BIDDING_EDIT_ID_HANDLER,
    key: value,
  });
};

export const editViewBiddingChangeHandler =
  (value, current_id, name) => (dispatch) => {
    dispatch({
      type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[1],
      key: "StartBiddingPrice",
    });
    dispatch({
      type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[2],
      key: "EndBiddingPrice",
    });
    dispatch({
      type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[3],
      key: "FirstIncrement",
    });
    dispatch({
      type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
      payload: name[4],
      key: "SecondIncrement",
    });
    dispatch({
      type: VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER,
      key: value,
      viewCurrentKey: current_id,
    });
  };
export const UpdateViewBiddingProfile = (id, currentID, data) => (dispatch) => {
  const requestEditUrl =
    EVENT_URL +
    "bidding-increment-profile/bidding-increment/update/" +
    currentID +
    "/" +
    id;
  instance
    .put(requestEditUrl, data)
    .then((res) => {
      console.log("update View bidding", res);
      window.location.replace("/user/all-profilebidding");
    })
    .catch((error) => {});
};
export const biddingIncrementError = (value) => (dispatch) => {
  dispatch({
    type: BIDDING_INCREMENT_PROFILE_ERROR_HANDLER,
    payload: value,
  });
};

export const saveDeleteResponseMsg = (sessionID, status) => (dispatch) => {
  dispatch({
    type: BIDDING_DELETE_SESSION_SAVE,
    payload: sessionID,
    payloadDeleteBiddingMessage: status,
  });
};

export const saveUpdateViewResponseMsg = (sessionID, status) => (dispatch) => {
  dispatch({
    type: BIDDING_VIEW_UPDATE_SESSION_SAVE,
    payload: sessionID,
    payloadUpdateViewBiddingMessage: status,
  });
};
