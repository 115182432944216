import {
    PROFILE_SAVE_ALL_TIMELINE_PROFILES,
    PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
    PROFILE_TIMELINE_PAGE_SELECT_CHANGE_HANDLER,
    TIMELINE_PAGE_TEXT_CHANGE_HANDLER,
    TIMELINE_PAGE_SELECT_CHANGE_HANDLER,
    TIMELINE_PAGE_SAVE_PROFILE_IDS_HANDLER,
    TIMELINE_EDIT_GET_ID,
    TIMELINE_PAGE_SELECT_CHANGE_HANDLER_EDIT,
    VIEW_TIMELINE_EDIT_TEXT_CHANGE_HANDLER,
    TIMELINE_UPDATE_ERROR_MESSAGE_HANDLER,
    TIMELINE_SAVE_ERROR_MESSAGE_HANDLER,
    TIMELINE_PAGE_EDIT_SELECT_CHANGE_HANDLER,
    TIMELINE_PROFILE_EDIT_TEXT_CHANGE_HANDLER,
    TILELINE_DELETE_SESSION_SAVE,
    TILELINE_VIEW_UPDATE_SESSION_SAVE
} from "./actions";

export const userDashboardReducer = (state = [], action) => {
    switch (action.type) {

        case PROFILE_SAVE_ALL_TIMELINE_PROFILES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allTImelineProfiles: [...action.payload]
                }
            }
        }
     /// How to save value from get edit data from id 
        case TIMELINE_EDIT_GET_ID: {
            return {
                ...state,
                    editData: {
                        ...state.editData,
                        editDataId: action.key,
                    }
            }
        }

        case VIEW_TIMELINE_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editViewData: {
                    ...state.editData,
                    editDataId: action.key,
                    editCurrentDataId: action.viewCurrentKey,
                    editBidStart:action.payloadBidStart,
                    editBidEnd:action.payloadBidEnd,
                    editDurationFirst:action.payloadDurationFirst,
                    editDurationSecond:action.payloadDurationSecond,
                    editDurationThird:action.payloadDurationThird

                }
            }
        }

        case PROFILE_TIMELINE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    timelineProfileForm: {
                        ...state.forms.timelineProfileForm,
                        fields: {
                            ...state.forms.timelineProfileForm.fields,
                            [action.key]: {
                                ...state.forms.timelineProfileForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case PROFILE_TIMELINE_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    timelineProfileForm: {
                        ...state.forms.timelineProfileForm,
                        fields: {
                            ...state.forms.timelineProfileForm.fields,
                            [action.key]: {
                                ...state.forms.timelineProfileForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case TIMELINE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    timelineForm: {
                        ...state.forms.timelineForm,
                        fields: {
                            ...state.forms.timelineForm.fields,
                            [action.key]: {
                                ...state.forms.timelineForm.fields[action.key],
                                value: action.payload
                            }
                        }
                    }
                }
            };
        }

        case TIMELINE_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    timelineForm: {
                        ...state.forms.timelineForm,
                        fields: {
                            ...state.forms.timelineForm.fields,
                            [action.key]: {
                                ...state.forms.timelineForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }


        case TIMELINE_PAGE_SELECT_CHANGE_HANDLER_EDIT: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    timelineProfileForm: {
                        ...state.forms.timelineProfileForm,
                        fields: {
                            ...state.forms.timelineProfileForm.fields,
                            [action.key]: {
                                ...state.forms.timelineProfileForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case TIMELINE_PAGE_SAVE_PROFILE_IDS_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    timelineForm: {
                        ...state.forms.timelineForm,
                        fields: {
                            ...state.forms.timelineForm.fields,
                            TimelineProfileId: {
                                ...state.forms.timelineForm.fields.TimelineProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case TIMELINE_UPDATE_ERROR_MESSAGE_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    timelineEditError: action.payload
                }
            }
        }

        case TIMELINE_SAVE_ERROR_MESSAGE_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    saveTimelineError: action.payload
                }
            }
        }

        case TIMELINE_PAGE_EDIT_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    timelineProfileForm: {
                        ...state.forms.timelineProfileForm,
                        fields: {
                            ...state.forms.timelineProfileForm.fields,
                            [action.key]: {
                                ...state.forms.timelineProfileForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case TIMELINE_PROFILE_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    editDataId: action.key,
                }
            }
        }

        case TILELINE_DELETE_SESSION_SAVE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    deleteTimelineResponseMsg: action.payload,
                    timelineDeleteStatus: action.payloadDeleteTimelineMessage
                }
            }
        }

        case TILELINE_VIEW_UPDATE_SESSION_SAVE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    updateViewTimelineResponseMsg: action.payload,
                    timelineViewUpdateStatus: action.payloadUploadTimelineViewMessage
                }
            }
        }
        
        default: {
            return {
                ...state
            };
        }
    }
}