import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";

class EditAccount extends Component {
      state={
          editFirstName:'',
          editLastName:'',
          editEmail:'',
          editStatus:'',
          editAccountType: '',
          editPassword: '',
      }

    SubmitHandler = () => {
        const {editFields } = this.props;
        const { editFirstName,editLastName,editEmail, editStatus, editAccountType, editPassword} = this.state;
        let data ={
            firstName :editFirstName,
            lastName :editLastName,
            email :editEmail,
            isActive :editStatus,
            accountTypeId: editAccountType.value ? editAccountType.value : "",
            password: editPassword ? editPassword.value : "",
        }

        this.props.UpdateAccountTypeProfile(editFields.editDataId, data);
    }

    componentDidMount = () => {
        const { editFields } = this.props;
        this.setState({
            editFirstName: editFields.editDataFirstName ? editFields.editDataFirstName : '',
            editLastName: editFields.editDataLastName ? editFields.editDataLastName : '',
            editEmail: editFields.editDataEmail ? editFields.editDataEmail : '',
            editStatus: editFields.editDataStatus ? editFields.editDataStatus : '',
            editAccountType: editFields.editDataAccountType ? editFields.editDataAccountType : '',
        })
        this.props.getAccountUserIDs();
    }

    textChangeHandler = (name, value) => {
        this.setState({
            [name]: value ? value.value : ""
        })
    }

    textChangeHandlerStatus = (name, value) => {
        this.setState({
            [name]: value ? value.label : ""
        })
    }

    evaluateStrength = (aValue) => {
        //is less than 10 characters
        if (aValue.length < 7) {
        return 0;
        }
        //has at least 10 characters but is only numbers or letters
        if (/^[a-zA-Z]+$/i.test(aValue) || /^[0-9]+$/i.test(aValue)) {
        return 1;
        }
        //is greater than 10 characters and has at least one number and letter
        if (/\d/.test(aValue) && /[a-zA-Z]/.test(aValue)) {
        return 2;
        }
        
    }

    handleChange = (e) => {
        let password = e;
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

        this.evaluateStrength(e)
        console.log(e, "Event Value");
        console.log(this.evaluateStrength(e), "evaluateStrength(e)")
    }

    render() {
        const {
            classes,
            fields,
            selectFieldChangeHandler,
            textFieldChangeHandler
        } = this.props;

        const {
            editFirstName,
            editLastName,
            editEmail,
            editStatus,
            editAccountType
        } = this.state;
        
        return (
            <>
                <div className={classes.editmainrapper}>
                <div className={classes.editfieldswrapper}>
                    <p>Edit User Account</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>First Name</span>
                                <CustomTextValidator
                                    id={editFirstName}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editFirstName}
                                    value={editFirstName}
                                    onChange={value => {
                                        this.textChangeHandler('editFirstName', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Last Name</span>
                                <CustomTextValidator
                                    id={editLastName}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editLastName}
                                    value={editLastName}
                                    onChange={value => {
                                        this.textChangeHandler('editLastName', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Email</span>
                                <CustomTextValidator
                                    id={editEmail}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editEmail}
                                    value={editEmail}
                                    onChange={value => {
                                        this.textChangeHandler('editEmail', value)
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Status</span>
                                <ReactSelectValidator
                                    id={editStatus}
                                    name={editStatus}
                                    validations={true}
                                    placeholder={editStatus}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            this.textChangeHandlerStatus('editStatus', value)
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    options={fields.Status.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountTypeid.label}</span>
                                <ReactSelectValidator
                                    id={fields.AccountTypeid.name}
                                    name={fields.AccountTypeid.name}
                                    value={fields.AccountTypeid.value ? fields.AccountTypeid.value : editAccountType}
                                    placeholder={editAccountType}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.AccountTypeid.name,
                                            value,
                                            this.setState({editAccountType: value})
                                        );
                                    }}
                                    isSearchable={true}
                                    options={fields.AccountTypeid.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.Password.label}</span>
                                <CustomTextValidator
                                    id={fields.Password.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="password"
                                    placeholder={fields.Password.placeholder}
                                    name={fields.Password.name}
                                    value={fields.Password.value}
                                    onChange={value => {
                                        this.handleChange(value)
                                        textFieldChangeHandler(
                                            fields.Password.name,
                                            value,
                                            this.setState({editPassword: value})
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>
                          
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditAccount);
