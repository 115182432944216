import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "../../common/PrivateRouteContainer";
import DashboardLanding from "./Landing/container";
import MyAccount from "./MyAccount/container";
import LoginHeader from "../../OmniConnect/Dashboard/common/Header/container";
import Footer from "../../common/Footer/component";
import ViewAllTimelineProfiles from "./Profiles/ViewAllTimelineProfiles/container";
import CreateTimelineProfile from "./Profiles/CreateTimelineProfile/container";
import CreateTimeline from "./Profiles/CreateTimeline/container";
import CreateBidingProfile from "./BidingProfile/CreateProfile/container";
import CreateBidingIncrement from "./BidingProfile/CreateIncrement/container";
import CreateBidingAllIncrement from "./BidingProfile/CreateIncAllProfiles/container";
import CreateItem from "./ItemsController/CreateItem/container";
import CreateAuctionEvent from "./Event/CreateEvent/container";
import ViewAllEvents from "./Event/AllEvents/container";

import ViewAllAccountType from "./AccountType/AllAccountType/container";
import CreateAccountType from "./AccountType/CreateAccountType/container";
import ViewAllUserAccounts from "./UserAccount/AllUserAccount/container";
import CreateUserAccount from "./UserAccount/CreateUserAccount/container";
import ViewAllAccounts from "./Accounts/AllAccounts/container";
import CreateAccount from "./Accounts/CreateAccount/container";
import PermissionList from "./Permissions/PermissionList/container";
import ViewAllBuyers from "./Buyers/AllBuyers/container";
import ViewAllLoginReports from "./ReportsManagement/LoginReports/container";
import ViewItemsReports from "./ReportsManagement/ItemsReports/container";
import ViewAllEventReports from "./ReportsManagement/EventReports/container";
import ViewAllTransactionReports from "./ReportsManagement/TransactionReports/container";
import ViewAllErrorReports from "./ReportsManagement/ErrorReports/container";
import NewsLetter from "./ReportsManagement/NewsLetter/container";

import CreateSeller from "./SellerManagement/CreateSeller/container";
// import ViewSeller from "./SellerManagement/ViewSeller/container"
import ViewAllSeller from "./SellerManagement/ViewAllSeller/container";
import AllLanguages from "./Languages/container";
import CreateLanguage from "./Languages/CreateLanguage/container";
import Logout from "../../Logout/container";
import instance, { USER_URL } from "../../../api/config";
import ls from "local-storage";

class UserDashboard extends Component {
  componentDidMount = () => {
    let data = {
      params: {
        accountTypeId: ls.get("AfterLoginAccountTypeid"),
      },
    };
    let AfterLoginAccountTypeid = ls.get("AfterLoginAccountTypeid");

    instance
      .post(USER_URL + "apps/getAppActions", {}, data)
      .then((res) => {
        const EventFilter = res.data.data.filter((value) => {
          return (
            AfterLoginAccountTypeid === AfterLoginAccountTypeid &&
            value.appName === "eventManagement"
          );
        });
        const UserFilter = res.data.data.filter((value) => {
          return (
            AfterLoginAccountTypeid === AfterLoginAccountTypeid &&
            value.appName === "userManagement"
          );
        });
        const SellerFilter = res.data.data.filter((value) => {
          return (
            AfterLoginAccountTypeid === AfterLoginAccountTypeid &&
            value.appName === "sellerManagement"
          );
        });

        // ls.set('AclGetAccountTypedata', EventFilter[0].accountTypes.coreAccountType);
        ls.set("AclGetEventAlloweddata", EventFilter[0].isAllowed);
        ls.set("AclGetUserAlloweddata", UserFilter[0].isAllowed);
        ls.set("AclGetSellerAlloweddata", SellerFilter[0].isAllowed);
      })
      .catch((error) => {
        console.log(error, "ACL Load Api Error");
      });
  };

  render() {
    return (
      <>
        <LoginHeader />
        <Switch>
          <PrivateRoute path={`/user/dashboard`} component={DashboardLanding} />
          <PrivateRoute path={`/user/myaccount`} component={MyAccount} />
          <PrivateRoute
            path={`/user/all-profiletimeline`}
            component={ViewAllTimelineProfiles}
          />
          <PrivateRoute
            path={`/user/create-timelinepro`}
            component={CreateTimelineProfile}
          />
          <PrivateRoute
            path={`/user/create-timeline`}
            component={CreateTimeline}
          />
          <PrivateRoute
            path={`/user/create-biddingpro`}
            component={CreateBidingProfile}
          />
          <PrivateRoute
            path={`/user/create-biddinginc`}
            component={CreateBidingIncrement}
          />
          <PrivateRoute
            path={`/user/all-profilebidding`}
            component={CreateBidingAllIncrement}
          />
          <PrivateRoute path={`/user/create-items`} component={CreateItem} />
          {/* <PrivateRoute path={`/user/all-itemscontroller`} component={CreateControllerAllItems} /> */}
          <PrivateRoute
            path={`/user/create-event`}
            component={CreateAuctionEvent}
          />
          <PrivateRoute path={`/user/all-events`} component={ViewAllEvents} />

          <PrivateRoute
            path={`/user/all-accounttype`}
            component={ViewAllAccountType}
          />
          <PrivateRoute
            path={`/user/create-usertype`}
            component={CreateAccountType}
          />
          <PrivateRoute
            path={`/user/all-useraccount`}
            component={ViewAllUserAccounts}
          />
          <PrivateRoute
            path={`/user/create-useraccount`}
            component={CreateUserAccount}
          />
          <PrivateRoute
            path={`/user/all-accounts`}
            component={ViewAllAccounts}
          />
          <PrivateRoute
            path={`/user/create-account`}
            component={CreateAccount}
          />
          <PrivateRoute
            path={`/user/all-permission`}
            component={PermissionList}
          />

          <PrivateRoute path={`/user/create-seller`} component={CreateSeller} />
          {/* <PrivateRoute path={`/user/view-seller`} component={ViewSeller} /> */}
          <PrivateRoute
            path={`/user/view-allseller`}
            component={ViewAllSeller}
          />
          <PrivateRoute path={`/user/all-buyers`} component={ViewAllBuyers} />
          <PrivateRoute
            path={`/user/view-loginreport`}
            component={ViewAllLoginReports}
          />
          <PrivateRoute
            path={`/user/view-itemreport`}
            component={ViewItemsReports}
          />
          <PrivateRoute
            path={`/user/view-eventreport`}
            component={ViewAllEventReports}
          />
          <PrivateRoute
            path={`/user/view-transactionreport`}
            component={ViewAllTransactionReports}
          />
          <PrivateRoute
            path={`/user/view-errorreport`}
            component={ViewAllErrorReports}
          />
          <PrivateRoute path={`/user/view-newsLetter`} component={NewsLetter} />
          <PrivateRoute path={`/user/logout`} component={Logout} />
          <PrivateRoute path={`/user/languages`} component={AllLanguages} />
          <PrivateRoute
            path={`/user/create-language`}
            component={CreateLanguage}
          />

          <Redirect to="/user/dashboard" />
        </Switch>
        <Footer />
      </>
    );
  }
}

export default withStyles(styles)(UserDashboard);
