import {
    ACCOUNT_USER_PAGE_TEXT_CHANGE_HANDLER,
    ACCOUNT_USER_PAGE_SELECT_CHANGE_HANDLER,
    PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES,
    ACCOUNT_USER_PAGE_SAVE_ID_HANDLER,
    BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    ACCOUNT_USER_EDIT_TEXT_CHANGE_HANDLER,
    PROFILE_ACCOUNT_ERROR_HANDLER
} from "./actions";

export const userAccountUserReducer = (state = [], action) => {
    switch (action.type) {

        case ACCOUNT_USER_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountUserForm: {
                        ...state.forms.AccountUserForm,
                        fields: {
                            ...state.forms.AccountUserForm.fields,
                            [action.key]: {
                                ...state.forms.AccountUserForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case ACCOUNT_USER_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountUserForm: {
                        ...state.forms.AccountUserForm,
                        fields: {
                            ...state.forms.AccountUserForm.fields,
                            [action.key]: {
                                ...state.forms.AccountUserForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allAccountTypeProfiles: [...action.payload]
                }
            }
        }

        case ACCOUNT_USER_PAGE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountUserForm: {
                        ...state.forms.AccountUserForm,
                        fields: {
                            ...state.forms.AccountUserForm.fields,
                            AccountTypeid: {
                                ...state.forms.AccountUserForm.fields.AccountTypeid,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingIncrementForm: {
                        ...state.forms.biddingIncrementForm,
                        fields: {
                            ...state.forms.biddingIncrementForm.fields,
                            [action.key]: {
                                ...state.forms.biddingIncrementForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case ACCOUNT_USER_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    editDataId: action.key,
                    editDataFirstName:action.payloadFirstName,
                    editDataLastName:action.payloadLastName,
                    editDataEmail:action.payloadEmail,
                    editDataStatus:action.payloadStatus,
                    editDataAccountType:action.payloadAccountType
                }
            }
        }

        case PROFILE_ACCOUNT_ERROR_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    userAccountError: action.payload
                }
            }
        }
        default: {
            return {
                ...state
            };
        }
    }
}