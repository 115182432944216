import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';

class CreateUserAccount extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.getAccountUserIDs();
    }
    evaluateStrength = (aValue) => {
        //is less than 10 characters
        if (aValue.length < 7) {
        return 0;
        }
        //has at least 10 characters but is only numbers or letters
        if (/^[a-zA-Z]+$/i.test(aValue) || /^[0-9]+$/i.test(aValue)) {
        return 1;
        }
        //is greater than 10 characters and has at least one number and letter
        if (/\d/.test(aValue) && /[a-zA-Z]/.test(aValue)) {
        return 2;
        }

        // switch(aValue) {
        //     case aValue.length < 7:
        //         return 0;
        //     case y:
        //       // code block
        //       break;
        //     default:
        //       // code block
        //   }

        
    }

    handleChange = (e) => {
        let password = e;
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

        this.evaluateStrength(e)
        console.log(e, "Event Value");
        console.log(this.evaluateStrength(e), "evaluateStrength(e)")
    }

    SubmitHandler = () => {
        this.props.submitAccountUser();
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            fields,
            userAccountError,
            isAllowed
        } = this.props;
        
        return (
            ls.get('AclGetUserAlloweddata') === true &&
            isAllowed === 'allowed' ? <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Create New User Account</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.FirstName.label}</span>
                                <CustomTextValidator
                                    id={fields.FirstName.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.FirstName.placeholder}
                                    name={fields.FirstName.name}
                                    value={fields.FirstName.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.FirstName.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.LastName.label}</span>
                                <CustomTextValidator
                                    id={fields.LastName.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.LastName.placeholder}
                                    name={fields.LastName.name}
                                    value={fields.LastName.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.LastName.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.Email.label}</span>
                                <CustomTextValidator
                                    id={fields.Email.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="email"
                                    placeholder={fields.Email.placeholder}
                                    name={fields.Email.name}
                                    value={fields.Email.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.Email.name,
                                            value
                                        );
                                    }}
                                    validators={["required",
                                        "matchRegexp:^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$"
                                    ]}
                                    errorMessages={[
                                        "Required",
                                        "Please enter a valid email"
                                    ]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            {/* <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountName.label}</span>
                                <CustomTextValidator
                                    id={fields.AccountName.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.AccountName.placeholder}
                                    name={fields.AccountName.name}
                                    value={fields.AccountName.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.AccountName.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid> */}

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.Password.label}</span>
                                <CustomTextValidator
                                    id={fields.Password.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="password"
                                    placeholder={fields.Password.placeholder}
                                    name={fields.Password.name}
                                    value={fields.Password.value}
                                    onChange={value => {
                                        this.handleChange(value)
                                        textFieldChangeHandler(
                                            fields.Password.name,
                                            value
                                        );
                                    }}
                                    validators={["required","matchRegexp:(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
                                    ]}
                                    errorMessages={["Required", "Password should be at least 8 characters long 1 uppercase & 1 lowercase at least 1 character and 1 number" ]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            {/* <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.ConfirmPassword.label}</span>
                                <CustomTextValidator
                                    id={fields.ConfirmPassword.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="password"
                                    placeholder={fields.ConfirmPassword.placeholder}
                                    name={fields.ConfirmPassword.name}
                                    value={fields.ConfirmPassword.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.ConfirmPassword.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid> */}

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountTypeid.label}</span>
                                <ReactSelectValidator
                                    id={fields.AccountTypeid.name}
                                    name={fields.AccountTypeid.name}
                                    value={fields.AccountTypeid.value}
                                    placeholder={fields.AccountTypeid.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.AccountTypeid.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    isSearchable={true}
                                    options={fields.AccountTypeid.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.Language.label}</span>
                                <ReactSelectValidator
                                    id={fields.Language.name}
                                    name={fields.Language.name}
                                    value={fields.Language.value}
                                    placeholder={fields.Language.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.Language.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    options={fields.Language.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.Status.label}</span>
                                <ReactSelectValidator
                                    id={fields.Status.name}
                                    name={fields.Status.name}
                                    value={fields.Status.value}
                                    placeholder={fields.Status.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.Status.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    options={fields.Status.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>
                            

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.RedirectUrl.label}</span>
                                <CustomTextValidator
                                    id={fields.RedirectUrl.name}
                                    fullWidth={true}
                                    validations={true}
                                    type="text"
                                    placeholder={fields.RedirectUrl.placeholder}
                                    name={fields.RedirectUrl.name}
                                    value={fields.RedirectUrl.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.RedirectUrl.name,
                                            value
                                        );
                                    }}
                                    // validators={["required"]}
                                    // errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>
                            {
                                userAccountError ? <span className={classes.error}>{userAccountError}</span> : null
                            }
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Add</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(CreateUserAccount);