import instance, { BUYER_SELLER_URL } from "../../../../api/config";
import moment from "moment";

export const LOGIN_REPORT_SAVE_AUCTIONS_HANDLER =
  "LOGIN_REPORT_SAVE_AUCTIONS_HANDLER";
export const ITEM_REPORT_SAVE_AUCTIONS_HANDLER =
  "ITEM_REPORT_SAVE_AUCTIONS_HANDLER";
export const VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER =
  "VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER";
export const EVENT_REPORT_SAVE_AUCTIONS_HANDLER =
  "EVENT_REPORT_SAVE_AUCTIONS_HANDLER";
export const TRANSACTION_REPORT_SAVE_AUCTIONS_HANDLER =
  "TRANSACTION_REPORT_SAVE_AUCTIONS_HANDLER";
export const ERROR_REPORT_SAVE_AUCTIONS_HANDLER =
  "ERROR_REPORT_SAVE_AUCTIONS_HANDLER";
export const TRANSACTION_VIEW_DETAILS = "TRANSACTION_VIEW_DETAILS";
export const NEWSLETTER_SAVE_AUCTIONS_HANDLER =
  "NEWSLETTER_SAVE_AUCTIONS_HANDLER";
export const REDO_RESPONCE_HANDLER = "REDO_RESPONCE_HANDLER";
export const TRANSACTION_TOTAL_RECORDS = "TRANSACTION_TOTAL_RECORDS";
export const TRANSACTION_SELECT_CHANGE_HANDLER =
  "TRANSACTION_SELECT_CHANGE_HANDLER";

export const saveAllLoginReports = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    let datetime;

    if (item.datetime) {
      datetime = moment(item.datetime, "DD.MM.YYYY HH:mm:ss").valueOf();
    } else {
      datetime = item.datetime;
    }

    return [
      item.eBayUserId,
      item.eBayUsername,
      item.liveAuctionUserId,
      item.event,
      item.userAgent,
      datetime,
    ];
  });
  dispatch({
    type: LOGIN_REPORT_SAVE_AUCTIONS_HANDLER,
    payload: newArray,
  });
};

export const saveAllItemReports = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    let auctionStartTime;
    let auctionEndTime;
    let datetime;

    if (item.datetime) {
      datetime = moment(item.datetime, "DD.MM.YYYY HH:mm:ss").valueOf();
    } else {
      datetime = item.datetime;
    }

    if (item.auctionStartTime) {
      auctionStartTime = moment(
        item.auctionStartTime,
        "DD.MM.YYYY HH:mm:ss"
      ).valueOf();
    } else {
      auctionStartTime = item.auctionStartTime;
    }

    if (item.auctionEndTime) {
      auctionEndTime = moment(
        item.auctionEndTime,
        "DD.MM.YYYY HH:mm:ss"
      ).valueOf();
    } else {
      auctionEndTime = item.auctionEndTime;
    }

    return [
      item.eBayUserId,
      item.eBayUsername,
      item.itemEbayIdentifier,
      item.liveAuctionUserId,
      item.liveAuctionEventId,
      item.eventName,
      item.liveAuctionItemId,
      item.event,
      item.auctionPreStartPrice,
      item.previousPrice,
      item.minimumPrice,
      String(item.minimumPriceCheck),
      item.auctionStartPrice,
      item.marketPrice,
      item.auctionCurrency,
      item.itemPosition,
      item.bidAmount,
      item.bidType,
      item.bidAccepted,
      item.userAgent,
      datetime,
      auctionStartTime,
      auctionEndTime,
    ];
  });
  dispatch({
    type: ITEM_REPORT_SAVE_AUCTIONS_HANDLER,
    payload: newArray,
  });
};

export const saveAllEventReports = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    let datetime;

    if (item.datetime) {
      datetime = moment(item.datetime, "DD.MM.YYYY HH:mm:ss").valueOf();
    } else {
      datetime = item.datetime;
    }

    return [
      item.eBayUserId,
      item.eBayUsername,
      item.liveAuctionEventId,
      item.eventName,
      item.liveAuctionUserId,
      item.event,
      item.userAgent,
      datetime,
    ];
  });
  dispatch({
    type: EVENT_REPORT_SAVE_AUCTIONS_HANDLER,
    payload: newArray,
  });
};

export const saveAllTransactionReports = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    let purchaseTime;
    let winningTime;
    let redoCondition;

    if (item.purchaseTime) {
      purchaseTime = moment(item.purchaseTime, "DD.MM.YYYY HH:mm:ss").valueOf();
    } else {
      purchaseTime = item.purchaseTime;
    }

    if (item.winningTime) {
      winningTime = moment(item.winningTime, "DD.MM.YYYY HH:mm:ss").valueOf();
    } else {
      winningTime = item.winningTime;
    }

    redoCondition =
      item.bestOfferStatus === "Failure" || item.errorDescription !== ""
        ? true
        : false;

    return [
      item.itemEbayIdentifier,
      item.eBayTransactionId,
      item.eBayUserId,
      item.eBayUsername,
      item.ebayCheckoutStatus,
      item.ebayOrderStatus,
      item.ebayCancelStatus,
      item.sellerEbayName,
      item.sellerEmail,
      item.itemPosition,
      item.liveAuctionEventId,
      item.eventName,
      item.liveAuctionUserId,
      item.bestOfferId,
      item.bestOfferStatus,
      // item.errorMessage === true ? 1 : 0,
      item.errorDescription,
      item.bidAmount,
      item.auctionCurrency,
      item.userAgent,
      winningTime,
      purchaseTime,
      redoCondition,
    ];
  });
  dispatch({
    type: TRANSACTION_REPORT_SAVE_AUCTIONS_HANDLER,
    payload: newArray,
  });
};

export const saveAllErrorReports = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [
      item.id,
      item.eventId,
      item.eventName,
      item.itemEbayIdentifier,
      item.errorMessage,
      item.createdTimeStamp,
    ];
  });
  dispatch({
    type: ERROR_REPORT_SAVE_AUCTIONS_HANDLER,
    payload: newArray,
  });
};

export const editViewItemChangeHandler =
  (value, current_id, name) => (dispatch) => {
    dispatch({
      type: VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER,
      payloaditemName: name[1],
      key: value,
      viewCurrentKey: current_id,
    });
  };

export const transactionViewFilterChangeHandler =
  (value, current_id, name) => (dispatch) => {
    let data = {
      params: {
        reportType: "TRANSACTION",
      },
    };

    instance.get(BUYER_SELLER_URL + "reports", data).then((res) => {
      let newArray = res.data.filter(
        (value) => name[1] === value.itemEbayIdentifier
      );

      dispatch({
        type: TRANSACTION_VIEW_DETAILS,
        payload: newArray,
      });
    });
  };

export const saveAllNewsLetter = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [item.email, item.languageCode, item.createdDate];
  });
  dispatch({
    type: NEWSLETTER_SAVE_AUCTIONS_HANDLER,
    payload: newArray,
  });
};

export const saveRedoResMessage = (value) => (dispatch) => {
  dispatch({
    type: REDO_RESPONCE_HANDLER,
    payload: value,
  });
};

export const saveTotalTransactions = (data) => (dispatch) => {
  dispatch({
    type: TRANSACTION_TOTAL_RECORDS,
    payload: data,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: TRANSACTION_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};
