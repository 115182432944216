import { connect } from "react-redux";
import ViewAllTimelineProfiles from "./component";
import instance, { EVENT_URL } from "../../../../../api/config";
import {
  saveALLTimelineProfiles,
  editTimelineChangeHandler,
  saveDeleteResponseMsg,
} from "../../actions";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  allProfiles: state.DASHBOARD.data.allTImelineProfiles,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
  deleteTimelineResponseMsg: state.DASHBOARD.data.deleteTimelineResponseMsg,
  timelineDeleteStatus: state.DASHBOARD.data.timelineDeleteStatus,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Loading Timeline Profile",
        "small"
      )
    );
    instance
      .get(EVENT_URL + "timeline-profile/timeline-profiles/")
      .then((res) => {
        dispatch(saveALLTimelineProfiles(res.data.data));
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  deleteProfileHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Timeline Profile",
        "small"
      )
    );
    instance
      .delete(EVENT_URL + "timeline-profile/timeline-profiles/remove/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(saveDeleteResponseMsg(id, err.response.data.description));
        dispatch(handleLoadingSpinnerClose());
      });
  },
  editTimelineChangeHandler: (value, name) => {
    dispatch(editTimelineChangeHandler(value, name));
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/timeline-profile/timeline-profiles"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllTimelineProfiles);
