import {
  LOGIN_REPORT_SAVE_AUCTIONS_HANDLER,
  VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER,
  ITEM_REPORT_SAVE_AUCTIONS_HANDLER,
  EVENT_REPORT_SAVE_AUCTIONS_HANDLER,
  TRANSACTION_REPORT_SAVE_AUCTIONS_HANDLER,
  TRANSACTION_VIEW_DETAILS,
  ERROR_REPORT_SAVE_AUCTIONS_HANDLER,
  NEWSLETTER_SAVE_AUCTIONS_HANDLER,
  REDO_RESPONCE_HANDLER,
  TRANSACTION_TOTAL_RECORDS,
  TRANSACTION_SELECT_CHANGE_HANDLER,
} from "./actions";

export const allReportsReducer = (state = [], action) => {
  switch (action.type) {
    case LOGIN_REPORT_SAVE_AUCTIONS_HANDLER: {
      return {
        ...state,
        data: {
          ...state.data,
          allLoginReports: [...action.payload],
        },
      };
    }

    case ITEM_REPORT_SAVE_AUCTIONS_HANDLER: {
      return {
        ...state,
        data: {
          ...state.data,
          allItemReports: [...action.payload],
        },
      };
    }

    case EVENT_REPORT_SAVE_AUCTIONS_HANDLER: {
      return {
        ...state,
        data: {
          ...state.data,
          allEventReports: [...action.payload],
        },
      };
    }

    case TRANSACTION_REPORT_SAVE_AUCTIONS_HANDLER: {
      return {
        ...state,
        data: {
          ...state.data,
          allTransactionReports: [...action.payload],
        },
      };
    }

    case ERROR_REPORT_SAVE_AUCTIONS_HANDLER: {
      return {
        ...state,
        data: {
          ...state.data,
          allErrorReports: [...action.payload],
        },
      };
    }

    case VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER: {
      return {
        ...state,
        editViewData: {
          ...state.editData,
          editDataId: action.key,
          editCurrentDataId: action.viewCurrentKey,
        },
      };
    }

    case TRANSACTION_VIEW_DETAILS: {
      return {
        ...state,
        data: {
          ...state.data,
          allViewTransactionDetails: [...action.payload],
        },
      };
    }

    case NEWSLETTER_SAVE_AUCTIONS_HANDLER: {
      return {
        ...state,
        data: {
          ...state.data,
          allNewsLetter: [...action.payload],
        },
      };
    }

    case REDO_RESPONCE_HANDLER: {
      return {
        ...state,
        data: {
          ...state.data,
          redoResTransaction: [action.payload],
        },
      };
    }

    case TRANSACTION_TOTAL_RECORDS: {
      return {
        ...state,
        data: {
          ...state.data,
          totalCountsTransactions: action.payload,
        },
      };
    }

    case TRANSACTION_SELECT_CHANGE_HANDLER: {
      return {
        ...state,
        forms: {
          ...state.forms,
          auctionEventForm: {
            ...state.forms.auctionEventForm,
            fields: {
              ...state.forms.auctionEventForm.fields,
              [action.key]: {
                ...state.forms.auctionEventForm.fields[action.key],
                value: action.payload === null ? [] : [action.payload],
              },
            },
          },
        },
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};
