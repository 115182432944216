export const CONTROLER_PROFILE_PAGE_TEXT_CHANGE_HANDLER = "CONTROLER_PROFILE_PAGE_TEXT_CHANGE_HANDLER";
export const CONTROLER_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER = "CONTROLER_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const CONTROLER_PROFILE_PAGE_SELECT_CHANGE_HANDLER = "CONTROLER_PROFILE_PAGE_SELECT_CHANGE_HANDLER";
export const CONTROLER_PROFILE_PAGE_SAVE_ID_HANDLER = "CONTROLER_PROFILE_PAGE_SAVE_ID_HANDLER";
export const PROFILE_SAVE_ALL_CONTROLLER_PROFILES = "PROFILE_SAVE_ALL_CONTROLLER_PROFILES";
export const ITEM_ADD_SESSION_SAVE = "ITEM_ADD_SESSION_SAVE";
export const ITEM_PAGE_SAVE_EVENT_IDS_HANDLER = "ITEM_PAGE_SAVE_EVENT_IDS_HANDLER"


export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: CONTROLER_PROFILE_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};



export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: CONTROLER_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};


export const saveBiddingProfileIds = (value) => dispatch => {
    let newValue = value.map((item) => {
        return {value: item.id, label: item.biddingIncrementProfileName}
    })
    dispatch({
        type: CONTROLER_PROFILE_PAGE_SAVE_ID_HANDLER,
        payload: [...newValue],
    });
}

export const saveALLControllerItems = (value) => dispatch => {
    let newArray = value.map((item) => {return [item.id, item.itemName, String(item.heroItem), item.itemEbayIdentifier, item.publishingTime, String(item.topItem), item.additionalItemDescription, item.category, item.createdTime, item.startingPrice, item.additionalItemName, item.certificateAdditionalDocument, item.images, String(item.isActive), item.itemConditionIndividual, item.marketValueEstimate, item.teaserItem]})
    dispatch({
        type: PROFILE_SAVE_ALL_CONTROLLER_PROFILES,
        payload: newArray,
    });
};

export const saveControllerItemEventIds = (value) => dispatch => {
    let newValue = value.map((item) => {
        return {value: item.id, label: item.eventName}
    })
    dispatch({
        type: ITEM_PAGE_SAVE_EVENT_IDS_HANDLER,
        payload: [...newValue],
    });
};

export const saveAddResponseMsg = (sessionID, status) => dispatch => {
    dispatch({
        type: ITEM_ADD_SESSION_SAVE,
        payload: sessionID,
        payloadAddItemMessage: status,

    });
};