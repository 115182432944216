import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../common/ReduxBased/LoadingSpinner/actions";
import ls from "local-storage";

import instance, { USER_URL, LOGIN_URL } from "../../api/config";
import { reverseString } from "./../common/Helpers";
export const LOGIN_PAGE_TEXT_CHANGE_HANDLER = "LOGIN_PAGE_TEXT_CHANGE_HANDLER";
export const LOGIN_SUBMIT_FORM_HANDLER = "LOGIN_SUBMIT_FORM_HANDLER";
export const LOGIN_PAGE_ERROR_HANDLER = "LOGIN_PAGE_ERROR_HANDLER";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: LOGIN_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const submitFormHandler = (value) => (dispatch) => {
  let resObj = {};
  let keys_needed = ["sessionToken", "firstName", "user_id"];

  dispatch(handleLoadingSpinnerOpenWithOptions(true, "Loading...", "large"));
  instance
    .post(LOGIN_URL + "login", value)
    .then((res) => {
      dispatch({
        type: LOGIN_PAGE_ERROR_HANDLER,
        payload: res.data.description,
      });
      dispatch(handleLoadingSpinnerClose());
      keys_needed.map((item) => {
        resObj[reverseString(item)] = res.data.data[item];
        return item;
      });
      resObj[reverseString("user_id")] = res.data.data.id;
      resObj[reverseString("sessionToken")] = res.data.data.userToken
        .sessionToken
        ? res.data.data.userToken.sessionToken
        : "";
      ls.set("user_id", res.data.data.id);
      ls.set("LoginStatus", res.status);
      ls.set("SessionTokenCreatedTime", res.data.data.userToken.createdDate);
      ls.set("SessionTokenExpiry", res.data.data.userToken.sessionTokenExpiry);
      resObj[reverseString("firstName")] = res.data.data.firstName
        ? res.data.data.firstName
        : res.data.user_name.toUpperCase();
      localStorage.setItem(reverseString("app_store"), JSON.stringify(resObj));
      dispatch({
        type: LOGIN_SUBMIT_FORM_HANDLER,
        payload: res.data.data,
      });

      ls.set("AfterLoginAccountTypeid", res.data.data.userAccountTypeId);

      //getting urls

      let perData = {
        params: {
          accountTypeId: res.data.data ? res.data.data.userAccountTypeId : "",
        },
      };
      instance
        .post(USER_URL + "apps/getAppActions", {}, perData)
        .then((res) => {
          ls.set("sidebarAllowedURLs", res.data.data ? res.data.data : []);
          window.location.replace("/user");
        })
        .catch((error) => {
          dispatch(handleLoadingSpinnerClose());
        });
    })
    .catch((error) => {
      dispatch(handleLoadingSpinnerClose());
    });
};
