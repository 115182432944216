import { connect } from "react-redux";
import CreateLanguage from "./component";

import { textFieldChangeHandler } from "../actions";

import instance, { EVENT_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  fields: state.USER_LANGUAGES.forms.languageForm.fields,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler(name, e.value));
  },
  submitLanguageProfile: (Language, LanguageCode) => {
    let data = {
      params: {
        languageCode: LanguageCode.value,
        languageName: Language.value,
      },
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Saving Language...", "small")
    );

    instance
      .post(EVENT_URL + "core/core-languages/create", {}, data)
      .then((res) => {
        window.location.replace("/user/languages");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(
      checkIfUrlIsAllowed(
        "/bidding-increment-profile/bidding-increment-profiles/create"
      )
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitLanguageProfile: () => {
    dispatchProps.submitLanguageProfile(
      stateProps.fields.Language,
      stateProps.fields.LanguageCode
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateLanguage);
