import { connect } from "react-redux";
import EditSeller from "./component";
import { sellerEditTextFieldChangeHandler } from "../actions";
import ls from "local-storage";
import instance, { BUYER_SELLER_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = (state) => ({
  editFields: state.SELLER.editData,
  sellerFields: state.SELLER.forms.sellerFormEdit.fields,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(sellerEditTextFieldChangeHandler(name, e.value));
  },

  submitSellerEdit: (
    SellerFirstNameCreate,
    SellerLastNameCreate,
    SellerUsernameCreate,
    SellerEmailCreate
  ) => {
    let EditsellerId = ls.get("EditSellerID");
    let data = {
      firstName: SellerFirstNameCreate ? SellerFirstNameCreate.value : "",
      lastName: SellerLastNameCreate ? SellerLastNameCreate.value : "",
      eBayUserName: SellerUsernameCreate ? SellerUsernameCreate.value : "",
      email: SellerEmailCreate ? SellerEmailCreate.value : "",
    };
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Update Seller", "small")
    );

    instance
      .put(BUYER_SELLER_URL + "auction-seller/update/" + EditsellerId, data)
      .then((res) => {
        window.location.replace("/user/view-allseller");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitSellerEdit: () => {
    dispatchProps.submitSellerEdit(
      stateProps.sellerFields.SellerFirstNameCreate,
      stateProps.sellerFields.SellerLastNameCreate,
      stateProps.sellerFields.SellerUsernameCreate,
      stateProps.sellerFields.SellerEmailCreate
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditSeller);
