import React from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";

class ErrorMessage extends React.Component {
    render() {
        const {
            classes,
            message
        } = this.props;

        return (
            <div className={classes.errorwrapper}>
                {message}
            </div>
        )
    }
}

export default withStyles(styles)(ErrorMessage);
