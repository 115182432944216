import {
    ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER,
    PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER,
    PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES,
    ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER,
    BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    APP_PAGE_SAVE_ID_HANDLER,
    SAVE_ALL_FILTER_DATA,
    APP_PAGE_IS_ALLOWED_HANDLER

} from "./actions";

export const userPermissionsReducer = (state = [], action) => {
    switch (action.type) {

        case ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountTypeForm: {
                        ...state.forms.AccountTypeForm,
                        fields: {
                            ...state.forms.AccountTypeForm.fields,
                            [action.key]: {
                                ...state.forms.AccountTypeForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case PERMISSIONS_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountTypeForm: {
                        ...state.forms.AccountTypeForm,
                        fields: {
                            ...state.forms.AccountTypeForm.fields,
                            [action.key]: {
                                ...state.forms.AccountTypeForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allAccountTypeProfiles: [...action.payload]
                }
            }
        }
        
        case SAVE_ALL_FILTER_DATA: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allFilterData: [...action.payload]
                }
            }
        }

        case ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountTypeForm: {
                        ...state.forms.AccountTypeForm,
                        fields: {
                            ...state.forms.AccountTypeForm.fields,
                            AccountType: {
                                ...state.forms.AccountTypeForm.fields.AccountType,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case APP_PAGE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountTypeForm: {
                        ...state.forms.AccountTypeForm,
                        fields: {
                            ...state.forms.AccountTypeForm.fields,
                            App: {
                                ...state.forms.AccountTypeForm.fields.App,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingIncrementForm: {
                        ...state.forms.biddingIncrementForm,
                        fields: {
                            ...state.forms.biddingIncrementForm.fields,
                            [action.key]: {
                                ...state.forms.biddingIncrementForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case APP_PAGE_IS_ALLOWED_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    isAllowed: action.payload
                }
            }
        }
        default: {
            return {
                ...state
            };
        }
    }
}