import instance, { EVENT_URL } from "../../../../api/config";
import moment from "moment";
import ls from "local-storage";

export const EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER =
  "EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER";
export const EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER =
  "EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER";
export const EVENT_EDIT_MANAGER_PAGE_SELECT_CHANGE_HANDLER =
  "EVENT_EDIT_MANAGER_PAGE_SELECT_CHANGE_HANDLER";
export const EVENT_MANAGER_SAVE_AUCTIONS_HANDLER =
  "EVENT_MANAGER_SAVE_AUCTIONS_HANDLER";
export const EVENT_MANAGER_SAVE_ID_HANDLER = "EVENT_MANAGER_SAVE_ID_HANDLER";
export const EVENT_EDIT_TEXT_CHANGE_HANDLER = "EVENT_EDIT_TEXT_CHANGE_HANDLER";
export const SET_UPDATE_EVENT_PROFILE = "SET_UPDATE_EVENT_PROFILE";
export const VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER =
  "VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER";

export const EVENT_TIMLINE_SAVE_ID_HANDLER = "EVENT_TIMLINE_SAVE_ID_HANDLER";
export const EVENT_BIDDING_INC_SAVE_ID_HANDLER =
  "EVENT_BIDDING_INC_SAVE_ID_HANDLER";
export const EVENT_EDIT_BIDDING_INC_SAVE_ID_HANDLER =
  "EVENT_EDIT_BIDDING_INC_SAVE_ID_HANDLER";
export const UPDATE_ITEM_EDIT_ERROR_SAVE_HANDLER =
  "UPDATE_ITEM_EDIT_ERROR_SAVE_HANDLER";
export const EVENT_UPDATE_SESSION_SAVE = "EVENT_UPDATE_SESSION_SAVE";
export const EVENT_MANAGER_PAGE_EDIT_TEXT_CHANGE_HANDLER =
  "EVENT_MANAGER_PAGE_EDIT_TEXT_CHANGE_HANDLER";
export const CREATE_EVENT_ERROR_HANDLER = "CREATE_EVENT_ERROR_HANDLER";

export const TEASER_IMAGE_UPLOAD_HANDLER = "TEASER_IMAGE_UPLOAD_HANDLER";
export const UPDATE_TEASER_IMAGE_UPLOAD_HANDLER =
  "UPDATE_TEASER_IMAGE_UPLOAD_HANDLER";
export const EVENT_LANGUAGE_SAVE_ID_HANDLER = "EVENT_LANGUAGE_SAVE_ID_HANDLER";
export const STOP_EVENT_ERROR_HANDLER = "STOP_EVENT_ERROR_HANDLER";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};
export const textEditFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: EVENT_MANAGER_PAGE_EDIT_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectEditFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: EVENT_EDIT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const saveALLAuctionEvents = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    let publishtimevalue = moment(item.publishingTimeStamp).format(
      "ddd MMM DD YYYY HH:mm:ss"
    );
    let startingtimevalue = moment(item.startingTimeStamp).format(
      "ddd MMM DD YYYY HH:mm:ss"
    );
    return [
      item.id,
      item.eventName,
      item.eventHeadline,
      item.eventDescription,
      String(item.biddingBeforeEvent),
      item.biddingIncrementProfile.biddingIncrementProfileName,
      item.timelineProfile.timelineProfileName,
      String(item.liveStream),
      String(item.mainEvent),
      item.eventTeaserImageURL,
      publishtimevalue.toString(),
      startingtimevalue.toString(),
    ];
  });

  dispatch({
    type: EVENT_MANAGER_SAVE_AUCTIONS_HANDLER,
    payload: newArray,
  });
};

export const saveEventProfileIds = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [
      item.eventName,
      item.category,
      item.startingPrice,
      item.createdTime,
    ];
  });
  dispatch({
    type: EVENT_MANAGER_SAVE_ID_HANDLER,
    payload: newArray,
  });
};

export const saveEventTimelineProfileIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.timelineProfileName };
  });
  dispatch({
    type: EVENT_TIMLINE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const saveBiddingIncrementIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.biddingIncrementProfileName };
  });
  dispatch({
    type: EVENT_BIDDING_INC_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const editsaveBiddingIncrementIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.biddingIncrementProfileName };
  });
  dispatch({
    type: EVENT_BIDDING_INC_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const editEventChangeHandler = (value, name) => (dispatch) => {
  const eventLanguageCode = ls.get("eventLanguageCode");

  let perData = {
    params: {
      languageCode: eventLanguageCode ? eventLanguageCode : "en",
    },
  };

  instance
    .get(EVENT_URL + "auction-event/auction-events/" + value, perData)
    .then((res) => {
      let data = res.data.data;

      const eventName = { value: data.eventName };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: eventName,
        key: "EventName",
      });

      const eventHeadline = { value: data.eventHeadline };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: eventHeadline,
        key: "EventHeadline",
      });

      const eventDescription = { value: data.eventDescription };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: eventDescription,
        key: "EventDescription",
      });

      const mainEvent = {
        value: data.mainEvent ? 1 : 0,
        label: data.mainEvent ? "true" : "false",
      };
      dispatch({
        type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
        payload: mainEvent,
        key: "MainEvent",
      });

      const biddingBeforeEvent = {
        value: data.biddingBeforeEvent ? 1 : 0,
        label: data.biddingBeforeEvent ? "true" : "false",
      };
      dispatch({
        type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
        payload: biddingBeforeEvent,
        key: "BiddingBeforeEvent",
      });

      const startingTime = { value: moment(data.startingTimeStamp).toString() };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: startingTime,
        key: "StartingTime",
      });

      const publishingTime = {
        value: moment(data.publishingTimeStamp).toString(),
      };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: publishingTime,
        key: "PublishingTime",
      });

      const liveStream = {
        value: data.liveStream ? 1 : 0,
        label: data.liveStream ? "true" : "false",
      };
      dispatch({
        type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
        payload: liveStream,
        key: "LiveStream",
      });

      const sellingEvent = {
        value: data.sellingEvent ? 1 : 0,
        label: data.sellingEvent ? "true" : "false",
      };
      dispatch({
        type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
        payload: sellingEvent,
        key: "SellingEvent",
      });

      const eventAuctionTimeFrame = { value: data.eventAuctionTimeFrame };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: eventAuctionTimeFrame,
        key: "EventAuctionTimeFrame",
      });

      const liveChatEmbeddedCode = { value: data.liveChatEmbeddedCode };
      console.log({ liveChatEmbeddedCode });
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: liveChatEmbeddedCode,
        key: "LiveChatEmbeddedCode",
      });

      const liveStreamEmbeddedCode = { value: data.liveStreamEmbeddedCode };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: liveStreamEmbeddedCode,
        key: "LiveStreamEmbeddedCode",
      });

      const maximumParallelAuctions = { value: data.maximumParallelAuctions };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: maximumParallelAuctions,
        key: "ParallelAuctions",
      });

      const biddingIncrementProfile = {
        value: data.biddingIncrementProfile.id,
        label: data.biddingIncrementProfile.biddingIncrementProfileName,
      };
      dispatch({
        type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
        payload: biddingIncrementProfile,
        key: "BiddingIncProfileId",
      });

      const timelineProfile = {
        value: data.timelineProfile.id,
        label: data.timelineProfile.timelineProfileName,
      };
      dispatch({
        type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
        payload: timelineProfile,
        key: "EventTimelineProfileId",
      });

      const maximumItemsCount = { value: data.maximumItemsCount };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: maximumItemsCount,
        key: "MaximumItemsCount",
      });

      const minimumItemsCount = { value: data.minimumItemsCount };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: minimumItemsCount,
        key: "MinimumItemsCount",
      });

      const breakBetween = { value: data.delayForNextItemActivation };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: breakBetween,
        key: "BreakBetween",
      });

      const languageCodeName = data.allowedCountryCodes.map((item) => {
        return item.countryCode;
      });

      const languageCodeId1 =
        languageCodeName[0] === "en" ||
        languageCodeName[1] === "en" ||
        languageCodeName[2] === "en"
          ? { countryCode: "en" }
          : null;
      const languageCodeId2 =
        languageCodeName[0] === "de" ||
        languageCodeName[1] === "de" ||
        languageCodeName[2] === "de"
          ? { countryCode: "de" }
          : null;
      const languageCodeId3 =
        languageCodeName[0] === "fr" ||
        languageCodeName[1] === "fr" ||
        languageCodeName[2] === "fr"
          ? { countryCode: "fr" }
          : null;

      const languageCodeId = [
        languageCodeId1,
        languageCodeId2,
        languageCodeId3,
      ];

      const newlanguageCodeId = languageCodeId.filter((a) => a);

      const allowedCountryCodes = { value: [newlanguageCodeId] };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: allowedCountryCodes,
        key: "EventLanguages",
      });

      const eventTeaserImageURL = { value: data.eventTeaserImageURL };
      dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: eventTeaserImageURL,
        key: "UpdateTeaserImage",
      });
    })
    .catch((error) => {});

  dispatch({
    type: EVENT_EDIT_TEXT_CHANGE_HANDLER,
    payload: name[1],
    payloadHeadline: name[2],
    payloadDescription: name[3],
    payloadTeaser: name[4],
    payloadBbevent: name[5],
    payloadBprofile: name[6],
    payloadLiveStream: name[8],
    payloadMainEvent: name[9],
    payloadMajorColor: name[10],
    payloadMinorColor: name[11],
    payloadStartPrice: name[14],
    key: value,
  });
};

export const textUpdateEvent = (name, value) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_EVENT_PROFILE,
    payload: value,
    key: name,
  });
};

export const UpdateEventProfile = (id, data) => (dispatch) => {
  instance
    .put(EVENT_URL + "auction-event/auction-events/update/" + id, data)
    .then((res) => {
      window.location.replace("/user/all-events");
    })
    .catch((error) => {
      dispatch({
        type: EVENT_UPDATE_SESSION_SAVE,
        payload: id,
        payloadUpdateEventMessage: error.response.data.description,
      });
    });
};

export const editViewItemChangeHandler =
  (value, current_id, name) => (dispatch) => {
    dispatch({
      type: VIEW_ITEM_EDIT_TEXT_CHANGE_HANDLER,
      payloaditemName: name[1],
      payloadheroItem: name[2],
      payloaditemEbayIdentifier: name[3],
      payloadpublishingTime: name[4],
      payloadtopItem: name[5],
      key: value,
      viewCurrentKey: current_id,
    });
  };

export const UpdateItemEvent = (id, currentID, data) => (dispatch) => {
  const requestEditUrl =
    EVENT_URL +
    "auction-event/auction-event-items/update/" +
    currentID +
    "/" +
    id;
  instance
    .put(requestEditUrl, data)
    .then((res) => {
      console.log("update View Item Event", res);
      window.location.replace("/user/all-events");
    })
    .catch((error) => {
      const { response } = error;
      dispatch({
        type: UPDATE_ITEM_EDIT_ERROR_SAVE_HANDLER,
        value: response.data.description,
      });
    });
};

export const imageTeaserEventUrl = (file) => (dispatch) => {
  dispatch({
    type: TEASER_IMAGE_UPLOAD_HANDLER,
    payload: file,
  });
};

export const updateimageTeaserEventUrl = (file) => (dispatch) => {
  dispatch({
    type: UPDATE_TEASER_IMAGE_UPLOAD_HANDLER,
    payload: file,
  });
};

export const eventCreateError = (value) => (dispatch) => {
  dispatch({
    type: CREATE_EVENT_ERROR_HANDLER,
    payload: value,
  });
};
export const stopEventChangeHandler = (value) => (dispatch) => {
  dispatch({
    type: STOP_EVENT_ERROR_HANDLER,
    payload: value,
  });
};

export const saveGetLanguages = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return {
      value: item.id,
      label: item.languageName,
      name: item.languageCode,
    };
  });
  dispatch({
    type: EVENT_LANGUAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};
