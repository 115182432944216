export const styles = theme => ({
    dashboardwrapper: {
        padding: 38,
        minHeight: "calc(100vh - 116px)",
    },
    dashboardboxwrapper: {
        background: "#f4f7ff",
        padding: 22,
        textAlign: "center",
        margin: 13,
        borderRadius: 13,
        "&:hover": {
            boxShadow: "0px 0px 11px -4px #c5bfbf",
        },
        "& svg": {
            background: "white",
            color: "#5d33d0",
            padding: 4,
            width: 32,
            height: 32,
            borderRadius: "50%",
        },
        "& h1": {
            fontSize: 30,
            fontWeight: 700,
            marginTop: 11,
            marginBottom: 0,
        },
        "& span": {
            display: "block",
            marginBottom: 13,
            color: "#b0b0c3",
        },
        "& a": {
            textDecoration: "none",
        }
    }
});