import { connect } from "react-redux";
import EditViewTimeline from "./component";
import {
  textFieldChangeHandler_T,
  saveUpdateViewResponseMsg,
} from "../../../actions";
import ls from "local-storage";
import instance, { EVENT_URL } from "../../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = (state) => ({
  editViewFields: state.DASHBOARD.editViewData,
  fields: state.DASHBOARD.forms.timelineForm.fields,
  timelineEditError: state.DASHBOARD.UISettings.timelineEditError,
  updateViewTimelineResponseMsg:
    state.DASHBOARD.data.updateViewTimelineResponseMsg,
  timelineViewUpdateStatus: state.DASHBOARD.data.timelineViewUpdateStatus,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler_T(name, e.value));
  },

  updateTimelineViewProfile: (profID, id, data) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Update View Timeline Profile",
        "small"
      )
    );
    const requestEditUrl =
      EVENT_URL + "timeline-profile/timeline/update/" + id + "/" + profID;
    instance
      .put(requestEditUrl, data)
      .then((res) => {
        window.location.replace("/user/all-profiletimeline");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(
          saveUpdateViewResponseMsg(profID, error.response.data.description)
        );
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditViewTimeline);
