import { connect } from "react-redux";
import CreateBidingProfile from "./component";

import {
  textFieldChangeHandler,
  selectFieldChangeHandler,
  saveBiddingProfileIds,
} from "../actions";

import instance, { EVENT_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  fields: state.BIDDING.forms.biddingProfileForm.fields,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler(name, e.value));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
  getBiddingProfileIDs: () => {
    instance
      .get(EVENT_URL + "bidding-increment-profile/bidding-increment-profiles")
      .then((res) => {
        dispatch(saveBiddingProfileIds(res.data.data));
      })
      .catch((error) => {});
  },
  submitBiddingProfile: (ProfileName) => {
    let data = {
      biddingIncrementProfileName: ProfileName ? ProfileName.value : "",
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Saving Bidding Profile...",
        "small"
      )
    );

    instance
      .post(
        EVENT_URL +
          "bidding-increment-profile/bidding-increment-profiles/create",
        data
      )
      .then((res) => {
        window.location.replace("/user/all-profilebidding");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(
      checkIfUrlIsAllowed(
        "/bidding-increment-profile/bidding-increment-profiles/create"
      )
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitBiddingProfile: () => {
    dispatchProps.submitBiddingProfile(stateProps.fields.ProfileName);
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateBidingProfile);
