import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import Alert from '@material-ui/lab/Alert';

class EditViewTimeline extends Component {
      state={
        editBidStart:'',
        editBidEnd:"",
        editDurationFirst:"",
        editDurationSecond:"",
        editDurationThird:"",
      }

    SubmitHandler = () => {
        const { editBidStart,editBidEnd,editDurationFirst,editDurationSecond,editDurationThird} = this.state;
        const { editViewFields } = this.props;
        let data ={
            bidEnd :editBidEnd,
            bidStart :editBidStart,
            durationFirstRound :editDurationFirst,
            durationSecondRound :editDurationSecond,
            durationThirdRound :editDurationThird,

        }
        this.props.updateTimelineViewProfile(editViewFields.editDataId,editViewFields.editCurrentDataId, data);
    }

    componentDidMount = () => {
        const { editViewFields } = this.props;
        this.setState({
            editBidStart: editViewFields.editBidStart ? editViewFields.editBidStart : 0,
            editBidEnd: editViewFields.editBidEnd ? editViewFields.editBidEnd : 0,
            editDurationFirst: editViewFields.editDurationFirst ? editViewFields.editDurationFirst : 0,
            editDurationSecond: editViewFields.editDurationSecond ?  editViewFields.editDurationSecond : 0,
            editDurationThird: editViewFields.editDurationThird ? editViewFields.editDurationThird : 0
        })
    }

    textChangeHandler = (name, value) => {
        this.setState({
            [name]: value ? value.value : ""
        })
    }

    render() {
        const {
            classes,
            editViewFields,
            timelineEditError,
            updateViewTimelineResponseMsg,
            timelineViewUpdateStatus
        } = this.props;

        const {
            editBidStart,
            editBidEnd,
            editDurationFirst,
            editDurationSecond,
            editDurationThird,
        } = this.state;
        
        return (
            <>
                <div className={classes.editmainrapper}>
                <div className={classes.editfieldswrapper}>
                    <p>Edit Timeline - ID: {editViewFields.editDataId}</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Bid Start</span>
                                <CustomTextValidator
                                    id={editBidStart}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editBidStart}
                                    value={editBidStart}
                                    onChange={value => {
                                        this.textChangeHandler('editBidStart', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Bid End</span>
                                <CustomTextValidator
                                    id={editBidEnd}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editBidEnd}
                                    value={editBidEnd}
                                    onChange={value => {
                                        this.textChangeHandler('editBidEnd', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Duration First Round</span>
                                <CustomTextValidator
                                    id={editDurationFirst}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editDurationFirst}
                                    value={editDurationFirst}
                                    onChange={value => {
                                        this.textChangeHandler('editDurationFirst', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Duration Second Round</span>
                                <CustomTextValidator
                                    id={editDurationSecond}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editDurationSecond}
                                    value={editDurationSecond}
                                    onChange={value => {
                                        this.textChangeHandler('editDurationSecond', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>Duration Third Round</span>
                                <CustomTextValidator
                                    id={editDurationThird}
                                    validations={true}
                                    fullWidth={true}
                                    type="text"
                                    name={editDurationThird}
                                    value={editDurationThird}
                                    onChange={value => {
                                        this.textChangeHandler('editDurationThird', value)
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    className="textfield"
                                />
                                </div>
                            </Grid>
                            
                          
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Update</Button>
                            </Grid>

                            {
                                timelineEditError ? <span className={classes.error}>{timelineEditError}</span> : null
                            }
                            {updateViewTimelineResponseMsg ? 
                            <Alert severity="error">{timelineViewUpdateStatus}</Alert>
                            :null}
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </>
        )
    }
}

export default withStyles(styles)(EditViewTimeline);