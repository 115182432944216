export const EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER = "EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER";
export const EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER = "EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER";
export const BUYER_MANAGER_SAVE_ALL_HANDLER = "BUYER_MANAGER_SAVE_ALL_HANDLER";
export const EVENT_MANAGER_SAVE_ID_HANDLER = "EVENT_MANAGER_SAVE_ID_HANDLER";

export const EVENT_TIMLINE_SAVE_ID_HANDLER = "EVENT_TIMLINE_SAVE_ID_HANDLER";
export const EVENT_BIDDING_INC_SAVE_ID_HANDLER = "EVENT_BIDDING_INC_SAVE_ID_HANDLER";


export const textFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: EVENT_MANAGER_PAGE_TEXT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const selectFieldChangeHandler = (name, value) => dispatch => {
    dispatch({
        type: EVENT_MANAGER_PAGE_SELECT_CHANGE_HANDLER,
        payload: value,
        key: name
    });
};

export const saveALLBuyers = (value) => dispatch => {
    let newArray = value.map((item) => {return [item.userId, item.firstName, item.lastName, item.email, item.isVerified]});
    dispatch({
        type: BUYER_MANAGER_SAVE_ALL_HANDLER,
        payload: newArray,
    });
};

export const saveEventProfileIds = (value) => dispatch => {
    let newArray = value.map((item) => {return [item.eventName, item.category, item.startingPrice, item.createdTime]})
    dispatch({
        type: EVENT_MANAGER_SAVE_ID_HANDLER,
        payload: newArray,
    });
};

export const saveEventTimelineProfileIds = (value) => dispatch => {
    let newValue = value.map((item) => {
        return {value: item.id, label: item.timelineProfileName}
    })
    dispatch({
        type: EVENT_TIMLINE_SAVE_ID_HANDLER,
        payload: [...newValue],
    });
}

export const saveBiddingIncrementIds = (value) => dispatch => {
    let newValue = value.map((item) => {
        return {value: item.id, label: item.biddingIncrementProfileName}
    })
    dispatch({
        type: EVENT_BIDDING_INC_SAVE_ID_HANDLER,
        payload: [...newValue],
    });
}

