import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Popover, MenuItem, ListItemText, Select, FormGroup, FormLabel, TextField, Checkbox, FormControlLabel, OutlinedInput } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { NavLink } from "react-router-dom";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ErrorMessage from "../../common/ErrorMessage/component";
import moment from "moment";

class ViewAllLoginReports extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    }

    render() {

        const {
            classes,
            allItemReports,
            isAllowed
        } = this.props;

        const filterDateTime = allItemReports.filter((val) => val[19])
        const filterStartTime = allItemReports.filter((val) => val[20])
        const filterEndTime = allItemReports.filter((val) => val[21])

        const mapDateTime = filterDateTime.map((val) => {return val[19]})
        const sortDateTime = mapDateTime.sort((a, b) => a - b)
        const uniqueDateTime = sortDateTime.filter((val,id,array) => array.indexOf(val) == id);
        const sortDate = uniqueDateTime.map((item) => {return moment(new Date(item)).utcOffset('+0700').format("DD.MM.YYYY HH:mm:ss") });

        const mapStartDateTime = filterStartTime.map((val) => {return val[20]})
        const sortStartDateTime = mapStartDateTime.sort((a, b) => a - b)
        const uniqueStartDateTime = sortStartDateTime.filter((val,id,array) => array.indexOf(val) == id);
        const sortStartDate = uniqueStartDateTime.map((item) => {return moment(new Date(item)).utcOffset('+0700').format("DD.MM.YYYY HH:mm:ss") });

        const mapEndDateTime = filterEndTime.map((val) => {return val[21]})
        const sortEndDateTime = mapEndDateTime.sort((a, b) => a - b)
        const uniqueEndDateTime = sortEndDateTime.filter((val,id,array) => array.indexOf(val) == id);
        const sortEndDate = uniqueEndDateTime.map((item) => {return moment(new Date(item)).utcOffset('+0700').format("DD.MM.YYYY HH:mm:ss") });

        // const id = open ? 'simple-popover' : undefined;
        
        const columns = ["eBay UserID", "eBay Username", "Ebay Identifier", "Auction UserID", "Auction EventID", "Event Name", "Auction ItemID", "Event", "Auction Pre Start Price", "Previous Price", "Minimum Price", "Minimum Price Check", "Auction Start Price", "Market Price", "Currency", "Item Position", "Bid Amount", "Bid Type", "Bid Accepted", "User Agent", 
        
        {
            name: 'Datetime MST',
            label: "Datetime MST",
            options: {
              filter: true,
              filterType: 'custom',

              customBodyRender: value => {

                let newValue = "";
                if(value){
                  newValue = moment(new Date(value)).utcOffset('+0700').format("DD.MM.YYYY HH:mm:ss")             
                }
                return newValue
              },
              
    
              // if the below value is set, these values will be used every time the table is rendered.
              // it's best to let the table internally manage the filterList
              //filterList: [25, 50],
              
              customFilterListOptions: {
                render: v => {
                  if (v[0]) {
                    return `Datetime MST: ${v[0]}`;
                  } 
                  return [];
                },
                update: (filterList, filterPos, index) => {
                  if (filterPos === 0) {
                    filterList[index].splice(filterPos, 1, '');
                  } else if (filterPos === 1) {
                    filterList[index].splice(filterPos, 1);
                  } else if (filterPos === -1) {
                    filterList[index] = [];
                  }
    
                  return filterList;
                },
              },
              filterOptions: {
                names: [],
                logic(date, filters) {
                  if (filters[0]) {
                    return [date] < filters[0] || [date] > filters[0];
                  }else{
                    return false;
                  } 
                  
                },
                display: (filterList, onChange, index, column) => (
                  <div>
                    <FormLabel>Datetime MST</FormLabel>
                    <br />

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth={true}
                        value={filterList[index][0] ? filterList[index][0] : <div style={{color: "gray"}}>Select Datetime MST</div>}
                        onChange={event => {
                            filterList[index][0] = event.target.value;
                            onChange(filterList[index], index, column);
                        }}
                        input={<OutlinedInput id="" />}
                        displayEmpty={true}
                        renderValue={(selected) => selected == "" ? <div style={{color: "gray"}}>Datetime MST</div> : selected}
                        >
                        {sortDate.map((date) => (
                            <MenuItem key={date} value={date}>
                            <ListItemText primary={date} />
                            </MenuItem>
                        ))}
                    </Select>

                  </div>
                ),
              },
              print: false,
            },
        },
        
        {
          name: 'Auction Start Time MST',
          label: "Auction Start Time MST",
          options: {
            filter: true,
            filterType: 'custom',

            customBodyRender: value => {

              let newValue = "";
              if(value){
                newValue = moment(new Date(value)).utcOffset('+0700').format("DD.MM.YYYY HH:mm:ss")               
              }
              return newValue
            },
            
  
            // if the below value is set, these values will be used every time the table is rendered.
            // it's best to let the table internally manage the filterList
            //filterList: [25, 50],
            
            customFilterListOptions: {
              render: v => {
                if (v[0]) {
                  return `Auction Start Time MST: ${v[0]}`;
                } 
                return [];
              },
              update: (filterList, filterPos, index) => {
                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }
  
                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(date, filters) {
                if (filters[0]) {
                  return [date] < filters[0] || [date] > filters[0];
                }else{
                  return false;
                } 
                
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Auction Start Time MST</FormLabel>
                  <br />

                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      fullWidth={true}
                      value={filterList[index][0] ? filterList[index][0] : <div style={{color: "gray"}}>Select Auction Start Time MST</div>}
                      onChange={event => {
                          filterList[index][0] = event.target.value;
                          onChange(filterList[index], index, column);
                      }}
                      input={<OutlinedInput id="" />}
                      displayEmpty={true}
                      renderValue={(selected) => selected == "" ? <div style={{color: "gray"}}>Auction Start Time MST</div> : selected}
                      >
                      {sortStartDate.map((date) => (
                          <MenuItem key={date} value={date}>
                          <ListItemText primary={date} />
                          </MenuItem>
                      ))}
                  </Select>

                </div>
              ),
            },
            print: false,
          },
        },

        {
          name: 'Auction End Time MST',
          label: "Auction End Time MST",
          options: {
            filter: true,
            filterType: 'custom',

            customBodyRender: value => {

              let newValue = "";
              if(value){
                newValue = moment(new Date(value)).utcOffset('+0700').format("DD.MM.YYYY HH:mm:ss")               
              }
              return newValue
            },
            
  
            // if the below value is set, these values will be used every time the table is rendered.
            // it's best to let the table internally manage the filterList
            //filterList: [25, 50],
            
            customFilterListOptions: {
              render: v => {
                if (v[0]) {
                  return `Auction End Time MST: ${v[0]}`;
                } 
                return [];
              },
              update: (filterList, filterPos, index) => {
                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }
  
                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(date, filters) {
                if (filters[0]) {
                  return [date] < filters[0] || [date] > filters[0];
                }else{
                  return false;
                } 
                
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Auction End Time MST</FormLabel>
                  <br />

                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      fullWidth={true}
                      value={filterList[index][0] ? filterList[index][0] : <div style={{color: "gray"}}>Select Auction End Time MST</div>}
                      onChange={event => {
                          filterList[index][0] = event.target.value;
                          onChange(filterList[index], index, column);
                      }}
                      input={<OutlinedInput id="" />}
                      displayEmpty={true}
                      renderValue={(selected) => selected == "" ? <div style={{color: "gray"}}>Auction End Time MST</div> : selected}
                      >
                      {sortEndDate.map((date) => (
                          <MenuItem key={date} value={date}>
                          <ListItemText primary={date} />
                          </MenuItem>
                      ))}
                  </Select>

                </div>
              ),
            },
            print: false,
          },
        },
      
      ];

        const options = {
        fixedSelectColumn: false,
        // filterType: 'checkbox',
        downloadOptions: { filename: 'ItemsReport.csv', separator: ";", filterOptions: { useDisplayedRowsOnly: true, useDisplayedColumnsOnly: true } },
        sortOrder: {
            name: 'Datetime MST',
            direction: 'asc'
          }
        };

        return (
            isAllowed === 'allowed' ?  <>
            
                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                        <p>View all Items Report</p>
                        <MUIDataTable 
                            title={"Item Reports"} 
                            data={allItemReports} 
                            columns={columns} 
                            options={options}
                        />
                    </div>
                </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(ViewAllLoginReports);