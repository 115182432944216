import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import instance, { EVENT_URL } from "../../../../../../../api/config";
import ls from "local-storage";
import Alert from "@material-ui/lab/Alert";
import ReactSelectValidator from "../../../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import TextEditor from "../../../../../../common/TextEditor/component";

class EditItem extends Component {
  state = {
    title: "",
    itemCondition: "",
    shortDescription: "",
    color: "",
    brand: "",
    edititemName: "",
    edititemEbayIdentifier: "",
    edittopItem: "",
    losNr: "",
    startingPrice: "",
    minimumPrice: "",
    minimumPriceCheck: "",
    marketValueEstimate: "",
    authenticityGuarantee: "",
    marketPlace: "",
  };

  SubmitHandler = () => {
    const { editViewData } = this.props;
    let paramCode = ls.get("languageCode");
    const {
      edititemName,
      edititemEbayIdentifier,
      edittopItem,
      title,
      itemCondition,
      shortDescription,
      color,
      brand,
      losNr,
      startingPrice,
      minimumPrice,
      marketValueEstimate,
      marketValueEstimateFrontend,
      minimumPriceCheck,
      authenticityGuarantee,
      marketPlace,
    } = this.state;

    let data = {
      itemName: edititemName,
      itemEbayIdentifier: edititemEbayIdentifier,
      topItem: edittopItem,
      title: title,
      itemCondition: itemCondition,
      shortDescription: shortDescription,
      color: color,
      brand: brand,
      losNr: losNr,
      startingPrice: startingPrice,
      minimumPrice: minimumPrice,
      minimumPriceCheck: minimumPriceCheck,
      authenticityGuarantee: authenticityGuarantee,
      marketValueEstimate: marketValueEstimate,
      marketValueEstimateFrontend: marketValueEstimateFrontend,
      marketPlace: marketPlace.value,
      // languageCode: ls.get('languageCode') ? ls.get('languageCode') : "de",
      languageCode: paramCode ? paramCode : "de",
    };

    this.props.UpdateItemEvent(
      editViewData.editDataId,
      editViewData.editCurrentDataId,
      data
    );
  };

  componentDidMount = () => {
    const { editViewData } = this.props;
    let paramCode = ls.get("languageCode");
    instance
      .get(
        EVENT_URL +
          "auction-event/auction-event-items/get/" +
          editViewData.editCurrentDataId +
          "/" +
          editViewData.editDataId +
          "?languageCode=" +
          paramCode
      )
      .then((res) => {
        const response = res.data.data ? res.data.data : [];
        const selectedMarketPlace =
          this.props.itemsFields.MarketPlace.options.filter(
            (item) => item.value === response.marketPlace
          );
        this.setState({
          edititemName: response.itemName ? response.itemName : "",
          edititemEbayIdentifier: response.itemEbayIdentifier
            ? response.itemEbayIdentifier
            : "",
          edittopItem: response.topItem ? "true" : "false",
          title: response.itemDetail ? response.itemDetail.title : "",
          itemCondition: response.itemDetail
            ? response.itemDetail.itemCondition
            : "",
          shortDescription: response.itemDetail
            ? response.itemDetail.shortDescription
            : "",
          color: response.itemDetail ? response.itemDetail.color : "",
          brand: response.itemDetail ? response.itemDetail.brand : "",
          losNr: response.losNr ? response.losNr : "",
          startingPrice: response.startingPrice ? response.startingPrice : "",
          minimumPrice: response.minimumPrice ? response.minimumPrice : "",
          minimumPriceCheck: response.minimumPriceCheck ? "true" : "false",
          marketValueEstimate: response.marketValueEstimate
            ? response.marketValueEstimate
            : "",
          marketValueEstimateFrontend: response.marketValueEstimateFrontend
            ? response.marketValueEstimateFrontend
            : "",
          authenticityGuarantee: response.authenticityGuarantee
            ? "true"
            : "false",
          marketPlace: selectedMarketPlace[0],
        });
      })
      .catch((err) => {});
  };

  textChangeHandler = (name, value) => {
    this.setState({
      [name]: value ? value.value : "",
    });
  };

  textChangeHandlerDropdown = (name, value) => {
    if (name === "marketPlace") {
      this.setState({
        [name]: value ? value : "",
      });
    } else {
      this.setState({
        [name]: value ? value.label : "",
      });
    }
  };

  render() {
    const { classes, updateEventItemError, itemsFields } = this.props;

    const {
      edititemName,
      edititemEbayIdentifier,
      edittopItem,
      title,
      itemCondition,
      shortDescription,
      color,
      brand,
      losNr,
      startingPrice,
      minimumPrice,
      minimumPriceCheck,
      marketValueEstimate,
      marketValueEstimateFrontend,
      authenticityGuarantee,
      marketPlace,
    } = this.state;

    return (
      <div className={classes.editmainrapper}>
        <div className={classes.editfieldswrapper}>
          <p>Edit Event Item - {edititemName}</p>

          <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
            <Grid container>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Item ebay Identifier</span>
                  <CustomTextValidator
                    id={edititemEbayIdentifier}
                    fullWidth={true}
                    type="text"
                    name={edititemEbayIdentifier}
                    value={edititemEbayIdentifier}
                    onChange={(value) => {
                      this.textChangeHandler("edititemEbayIdentifier", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Item Name</span>
                  <CustomTextValidator
                    id={edititemName}
                    fullWidth={true}
                    type="text"
                    name={edititemName}
                    value={edititemName}
                    onChange={(value) => {
                      this.textChangeHandler("edititemName", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Top Item</span>
                  <ReactSelectValidator
                    id={edittopItem}
                    fullWidth={true}
                    type="text"
                    name={edittopItem}
                    placeholder={edittopItem}
                    validations={true}
                    onChange={(value) => {
                      this.textChangeHandlerDropdown("edittopItem", value);
                    }}
                    options={itemsFields.TopItem.options}
                    className="selectfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>LosNr</span>
                  <CustomTextValidator
                    id={losNr}
                    fullWidth={true}
                    type="text"
                    name={losNr}
                    value={losNr}
                    onChange={(value) => {
                      this.textChangeHandler("losNr", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Starting Price</span>
                  <CustomTextValidator
                    id={startingPrice}
                    fullWidth={true}
                    type="number"
                    name={startingPrice}
                    value={startingPrice}
                    onChange={(value) => {
                      this.textChangeHandler("startingPrice", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Minimum Price</span>
                  <CustomTextValidator
                    id={minimumPrice}
                    fullWidth={true}
                    type="number"
                    name={minimumPrice}
                    value={minimumPrice}
                    onChange={(value) => {
                      this.textChangeHandler("minimumPrice", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Minimum Price Check</span>
                  <ReactSelectValidator
                    id={minimumPriceCheck}
                    fullWidth={true}
                    type="text"
                    name={minimumPriceCheck}
                    placeholder={minimumPriceCheck}
                    validations={true}
                    onChange={(value) => {
                      this.textChangeHandlerDropdown(
                        "minimumPriceCheck",
                        value
                      );
                    }}
                    options={itemsFields.TopItem.options}
                    className="selectfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Market Value Estimate</span>
                  <CustomTextValidator
                    id={marketValueEstimate}
                    fullWidth={true}
                    type="number"
                    name={marketValueEstimate}
                    value={marketValueEstimate}
                    onChange={(value) => {
                      this.textChangeHandler("marketValueEstimate", value);
                    }}
                    validators={[
                      "required",
                      "matchRegexp:(^[0-9]+(-[0-9]+)+$|^[0-9]*$)",
                    ]}
                    errorMessages={[
                      "Required",
                      "Market Value should be in Range i.e (10 OR 100-500)",
                    ]}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Market Value Estimate (Frontend)</span>
                  <CustomTextValidator
                    id={marketValueEstimateFrontend}
                    fullWidth={true}
                    type="text"
                    name={marketValueEstimateFrontend}
                    value={marketValueEstimateFrontend}
                    onChange={(value) => {
                      this.textChangeHandler("marketValueEstimateFrontend", value);
                    }}
                    placeholder="100-200"
                    validators={[
                      "required",
                    ]}
                    errorMessages={[
                      "Required",
                    ]}
                    className="textfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Market Place</span>
                  <ReactSelectValidator
                    id={marketPlace.label}
                    fullWidth={true}
                    type="text"
                    name={marketPlace.label}
                    placeholder={marketPlace.label}
                    validations={true}
                    onChange={(value) => {
                      this.textChangeHandlerDropdown("marketPlace", value);
                    }}
                    options={itemsFields.MarketPlace.options}
                    className="selectfield"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Authenticity Guarantee</span>
                  <ReactSelectValidator
                    id={authenticityGuarantee}
                    fullWidth={true}
                    type="text"
                    name={authenticityGuarantee}
                    placeholder={authenticityGuarantee}
                    validations={true}
                    onChange={(value) => {
                      this.textChangeHandlerDropdown(
                        "authenticityGuarantee",
                        value
                      );
                    }}
                    options={itemsFields.AuthenticityGuarantee.options}
                    className="selectfield"
                  />
                </div>
              </Grid>

              {/* new fields */}
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Title</span>
                  <CustomTextValidator
                    id={title}
                    fullWidth={true}
                    type="text"
                    name={title}
                    value={title}
                    onChange={(value) => {
                      this.textChangeHandler("title", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Item Condition</span>
                  <CustomTextValidator
                    id={itemCondition}
                    fullWidth={true}
                    type="text"
                    name={itemCondition}
                    value={itemCondition}
                    onChange={(value) => {
                      this.textChangeHandler("itemCondition", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Short Description</span>
                  <TextEditor
                    onChange={this.textChangeHandler}
                    fieldName={"shortDescription"}
                    value={shortDescription}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Color</span>
                  <CustomTextValidator
                    id={color}
                    fullWidth={true}
                    type="text"
                    name={color}
                    value={color}
                    onChange={(value) => {
                      this.textChangeHandler("color", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="fieldwrapper">
                  <span>Brand</span>
                  <CustomTextValidator
                    id={brand}
                    fullWidth={true}
                    type="text"
                    name={brand}
                    value={brand}
                    onChange={(value) => {
                      this.textChangeHandler("brand", value);
                    }}
                    className="textfield"
                  />
                </div>
              </Grid>

              {updateEventItemError ? (
                <Alert severity="error">{updateEventItemError}</Alert>
              ) : null}

              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className="submitbtn"
                  type="submit"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(EditItem);
