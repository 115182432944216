import { connect } from "react-redux";
import CreateBidingIncrement from "./component";
import {
  textFieldChangeHandler_I,
  selectFieldChangeHandler,
  saveBiddingProfileIds,
  biddingIncrementError,
} from "../actions";
import instance, { EVENT_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  fields: state.BIDDING.forms.biddingIncrementForm.fields,
  error: state.BIDDING.UISettings.biddingIncrementError,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler_I(name, e.value));
  },
  getBiddingProfileIDs: () => {
    instance
      .get(EVENT_URL + "bidding-increment-profile/bidding-increment-profiles")
      .then((res) => {
        dispatch(saveBiddingProfileIds(res.data.data));
      })
      .catch((error) => {});
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },
  submitBiddingIncrements: (
    ProfileId,
    EndBiddingPrice,
    FirstIncrement,
    SecondIncrement,
    StartBiddingPrice
  ) => {
    let data = {
      biddingIncrementProfileId: ProfileId.value[0]
        ? ProfileId.value[0].value
        : "",
      endBiddingPrice: EndBiddingPrice ? EndBiddingPrice.value : "",
      firstBiddingIncrement: FirstIncrement ? FirstIncrement.value : "",
      secondBiddingIncrement: SecondIncrement ? SecondIncrement.value : "",
      startBiddingPrice: StartBiddingPrice ? StartBiddingPrice.value : "",
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Saving Increments", "small")
    );

    instance
      .post(
        EVENT_URL + "bidding-increment-profile/bidding-increment/create",
        data
      )
      .then((res) => {
        window.location.replace("/user/all-profilebidding");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(handleLoadingSpinnerClose());
        const { response } = error;
        dispatch(biddingIncrementError(response.data.description));
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(
      checkIfUrlIsAllowed("/bidding-increment-profile/bidding-increment/create")
    );
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitBiddingIncrements: () => {
    dispatchProps.submitBiddingIncrements(
      stateProps.fields.ProfileId,
      stateProps.fields.EndBiddingPrice,
      stateProps.fields.FirstIncrement,
      stateProps.fields.SecondIncrement,
      stateProps.fields.StartBiddingPrice
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateBidingIncrement);
