import { green } from "@material-ui/core/colors";
import BG from "../../../../../resources/images/landingbg.svg";

export const styles = theme => ({
    mainrapper: {
        background:  `url(${BG})`,
        backgroundSize: "cover",
        padding: "32px 13px",
        minHeight: "calc(100vh - 115px)",
        width: "100%",
        "& .fieldwrapper": {
            padding: "0px 13px",
        },
        "& .textfield": {
            marginTop: 13,
            marginBottom: 22,
            "& div": {
                height: "61px",
            }
        },
        "& .multilinefield": {
            width: "100%",
            marginTop: 13,
            marginBottom: 22,
            "& div": {
                display: "block",
                resize: "none",
                borderRadius: "4px !important",
                borderColor: "#dee0e2",
                paddingRight: "13px",
                paddingTop:2,
                height:107,
                "& textarea": {
                    textIndent: "0px",
                    paddingLeft: "7px",
                    paddingRight: "7px",
                    height:104
                }
            }
        },
        "& .selectfield": {
            marginTop: 13,
            border: "none !important",
            boxShadow: "none",
            outline: "none !important",
            "& .react-select__value-container": {
                padding: 14,
            },
            "&:hover": {
                border: "none !important",
                boxShadow: "none",
                outline: "none !important",
            },
            "&:active": {
                border: "none !important",
                outline: "none !important",
            },
            "&:visited": {
                border: "none !important",
                outline: "none !important",
            },
            "&:focus": {
                backgroundColor: '#fff',
                color: '#000',
                borderColor: "none !important",
                outline: "none !important",
            },
            "&:focus-visible": {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
            "&:focus-within": {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
            "&:target": {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
            '&:before': {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
              '&:after': {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            }
        },
        "& .submitbtn": {
            margin: 13,
            height: 61,
            width: 230,
            float: "right",
        },
        "& .imageFileChangeHandler": {
            width: "100%",
            padding: "15px 0px",
            color: "rgba(0, 0, 0, 0.87)",
            border: "2px solid #ededed",
            borderRadius: "5px",
            textIndent: "15px"
        },
        "& .teaserUpdateimage": {
            margin: 13,
            maxWidth: 60,
            maxHeight: 60,
        },
        "& #EventLanguages": {
            border: "none",
            outline: "none !important",
            "&:hover": {
                border: "none !important",
                boxShadow: "none",
                outline: "none !important",
            },
            "&:active": {
                border: "none !important",
                outline: "none !important",
            },
            "&:visited": {
                border: "none !important",
                outline: "none !important",
            },
            "&:focus": {
                backgroundColor: '#fff',
                color: '#000',
                borderColor: "none !important",
                outline: "none !important",
            },
            "&:focus-visible": {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
            "&:focus-within": {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
            "&:target": {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
            '&:before': {
                outline: "none !important",
                border: "none !important",
                fill: "none",
                stroke: "none"
            },
            "&: .select": {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'red',
                },
            },
            outline: {
                "&:before": {
                    borderColor: "red"
                },
                "&:after": {
                    borderColor: "red"
                },
            }
            
        },
        "&: .select": {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'red',
            },
          },
        
        
    },
    fieldswrapper: {
        background: "white",
        padding: 38,
        margin: "0px 38px",
        "& p": {
            color: "#707070",
        },
    },
    cropWrapper: {
        maxWidth: '600px',
        "& .cropBtn": {
            height: 40,
            width: 130,
        },
    }
});