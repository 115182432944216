import {LOGIN} from "../components/Login/initialState";
import {ALERT_DIALOG} from "../components/common/ReduxBased/confirmationDialog/initialState";
import {LOADING_SPINNER} from "./../components/common/ReduxBased/LoadingSpinner/initialState";
import {DASHBOARD} from "../components/OmniConnect/Dashboard/initialState";
import {BIDDING} from "../components/OmniConnect/Dashboard/BidingProfile/initialState";
import {EVENT} from "../components/OmniConnect/Dashboard/Event/initialState";
import {CONTROLLER} from "../components/OmniConnect/Dashboard/ItemsController/initialState";
import {ACCOUNT_TYPE} from "../components/OmniConnect/Dashboard/AccountType/initialState";
import {PERMISSIONS} from "../components/OmniConnect/Dashboard/Permissions/initialState";
import {ACCOUNT_USER} from "../components/OmniConnect/Dashboard/UserAccount/initialState";
import {ACCOUNTS} from "../components/OmniConnect/Dashboard/Accounts/initialState";
import {LANDING_DASHBOARD} from "../components/OmniConnect/Dashboard/Landing/initialState";
import {BUYERS} from "../components/OmniConnect/Dashboard/Buyers/initialState";
import {SELLER} from "../components/OmniConnect/Dashboard/SellerManagement/initialState";
import {USER_LANGUAGES} from "../components/OmniConnect/Dashboard/Languages/initialState";
import {REPORTS} from "../components/OmniConnect/Dashboard/ReportsManagement/initialState";

const initalState = {
    LOGIN: LOGIN,
    ALERT_DIALOG: ALERT_DIALOG,
    LOADING_SPINNER: LOADING_SPINNER,
    DASHBOARD: DASHBOARD,
    BIDDING: BIDDING,
    CONTROLLER: CONTROLLER,
    EVENT: EVENT,
    ACCOUNT_TYPE: ACCOUNT_TYPE,
    ACCOUNT_USER: ACCOUNT_USER,
    ACCOUNTS: ACCOUNTS,
    PERMISSIONS: PERMISSIONS,
    BUYERS: BUYERS,
    SELLER: SELLER,
    LANDING_DASHBOARD: LANDING_DASHBOARD,
    USER_LANGUAGES: USER_LANGUAGES,
    REPORTS: REPORTS
};

export default initalState;
