import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Popover } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { NavLink } from "react-router-dom";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ErrorMessage from "../../common/ErrorMessage/component";

class NewsLetter extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    }

    render() {

        const {
            classes,
            allNewsLetter,
            isAllowed
        } = this.props;

        // const id = open ? 'simple-popover' : undefined;
        
        const columns = ["Email", "Language Code", "Subscription Date"];

        const options = {
        // filterType: 'checkbox',
        downloadOptions: { filename: 'NewsLetter.csv', separator: ";", filterOptions: { useDisplayedRowsOnly: true, useDisplayedColumnsOnly: true } },
        sortOrder: {
            name: 'Created Date',
            direction: 'asc'
          }
        };

        return (
            isAllowed === 'allowed' ?  <>
            
                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                        <p>View all NewsLetter</p>
                        <MUIDataTable 
                            title={"NewsLetter"} 
                            data={allNewsLetter} 
                            columns={columns} 
                            options={options}
                        />
                    </div>
                </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(NewsLetter);