import { connect } from "react-redux";
import CreateTimeline from "./component";
import {
  textFieldChangeHandler_T,
  selectFieldChangeHandler_T,
  saveTimelineProfileIds,
  saveTimelineError,
} from "../../actions";
import instance, { EVENT_URL } from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  fields: state.DASHBOARD.forms.timelineForm.fields,
  error: state.DASHBOARD.UISettings.saveTimelineError,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler_T(name, e.value));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler_T(name, e));
  },
  getTimelineProfileIDs: () => {
    instance
      .get(EVENT_URL + "timeline-profile/timeline-profiles")
      .then((res) => {
        dispatch(saveTimelineProfileIds(res.data.data));
      })
      .catch((error) => {});
  },
  submitTimelines: (
    BidEnd,
    BidStart,
    DurationFirstRound,
    DurationSecondRound,
    DurationThirdRound,
    TimelineProfileId
  ) => {
    let data = {
      bidEnd: BidEnd ? parseInt(BidEnd.value) : "",
      bidStart: BidStart ? parseInt(BidStart.value) : "",
      durationFirstRound: DurationFirstRound
        ? parseInt(DurationFirstRound.value)
        : "",
      durationSecondRound: DurationSecondRound
        ? parseInt(DurationSecondRound.value)
        : "",
      durationThirdRound: DurationThirdRound
        ? parseInt(DurationThirdRound.value)
        : "",
      timelineProfileId: TimelineProfileId.value[0]
        ? TimelineProfileId.value[0].value
        : "",
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Saving Timelines", "small")
    );

    instance
      .post(EVENT_URL + "timeline-profile/timeline/create", data)
      .then((res) => {
        window.location.replace("/user/all-profiletimeline");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((e) => {
        dispatch(handleLoadingSpinnerClose());
        const { response } = e;
        dispatch(saveTimelineError(response.data.description));
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/timeline-profile/timeline/create"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitTimelines: () => {
    dispatchProps.submitTimelines(
      stateProps.fields.BidEnd,
      stateProps.fields.BidStart,
      stateProps.fields.DurationFirstRound,
      stateProps.fields.DurationSecondRound,
      stateProps.fields.DurationThirdRound,
      stateProps.fields.TimelineProfileId
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateTimeline);
