export const DASHBOARD_LANDING_COUNT_HANDLER = "DASHBOARD_LANDING_COUNT_HANDLER";
export const DASHBOARD_LANDING_DATA_COUNT_HANDLER = "DASHBOARD_LANDING_DATA_COUNT_HANDLER";

export const saveDashboardCount = (value) => dispatch => {
    dispatch({
        type: DASHBOARD_LANDING_COUNT_HANDLER,
        payload: value,
    });
};

export const saveDashboardData = (value) => dispatch => {
    dispatch({
        type: DASHBOARD_LANDING_DATA_COUNT_HANDLER,
        payload: value[0],
    });
};