import { connect } from "react-redux";
import CreateSeller from "./component";

import {
  textFieldChangeHandler,
  selectFieldChangeHandler,
  saveAddSellerResponse,
} from "../actions";
import ls from "local-storage";

import instance, {
  BUYER_SELLER_URL,
  CURRENT_ENV,
} from "../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  fields: state.SELLER.forms.sellerForm.fields,
  addSellerResponseMsg: state.SELLER.data.addSellerResponseMsg,
  sellerAddStatus: state.SELLER.data.sellerAddStatus,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler(name, e));
  },
  selectFieldChangeHandler: (name, e) => {
    dispatch(selectFieldChangeHandler(name, e));
  },

  submitSellerProfile: (SellerUsernameCreate, SellerEmailCreate) => {
    let data = {
      eBayUserName: SellerUsernameCreate ? SellerUsernameCreate.value : "",
      email: SellerEmailCreate ? SellerEmailCreate.value : "",
    };

    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Saving Seller", "small")
    );
    //todo STAGING, PROTOTYPE, LIVE
    let ENV_PROP = "LIVE";
    const ENV_PROP_LOOKUP = {
      development: "DEV",
      prototype: "PROTOTYPE",
      staging: "STAGING",
      live: "LIVE",
      production: "LIVE"
    };

    ENV_PROP = ENV_PROP_LOOKUP[CURRENT_ENV.toLowerCase()];

    instance
      .post(
        BUYER_SELLER_URL + `auction-seller/addSeller?env=${ENV_PROP.toUpperCase()}`,
        data
      )
      .then((res) => {
        console.log("add seler", res);
        ls.set("addsellerEmail", res.data.data.eBayUserName);
        dispatch(saveAddSellerResponse(res.data, res.data.description));

        // window.location.replace('/user/view-seller')
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        console.log("error add seller", error);
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/auction-seller/addSeller"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  submitSellerProfile: () => {
    dispatchProps.submitSellerProfile(
      stateProps.fields.SellerUsernameCreate,
      stateProps.fields.SellerEmailCreate
    );
  },
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateSeller);
