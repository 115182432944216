export const styles = theme => ({
    mainrapper: {
        width: "100%",
    },
    fieldswrapper: {
        background: "white",
        padding: 38,
        margin: "0px 38px",
        "& p": {
            color: "#707070",
        },
    },
    createlink: {
        background: "#074880",
        color: "white",
        textDecoration: "none",
        padding: 8,
        marginBottom: 13,
        display: "block",
        width: 178,
        textAlign: "center"
    }
});