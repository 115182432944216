import { connect } from "react-redux";
import ViewAllBuyers from "./component";
import instance, { BUYER_SELLER_URL } from "../../../../../api/config";
import { saveALLBuyers } from "../actions";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";
import { checkIfUrlIsAllowed } from "../../Permissions/actions";

const mapStateToProps = (state) => ({
  allBuyers: state.BUYERS.data.allBuyers,
  isAllowed: state.PERMISSIONS.UISettings.isAllowed,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchDataIfNeeded: (id) => {
    instance
      .get(BUYER_SELLER_URL + "auction-buyer/getBuyers")
      .then((res) => {
        dispatch(saveALLBuyers(res.data));
      })
      .catch((err) => {});
  },
  deleteProfileHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Bidding Profile",
        "small"
      )
    );
    instance
      .delete(BUYER_SELLER_URL + "auction-event/auction-events/remove/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
  checkIfUrlIsAllowed: async () => {
    await dispatch(checkIfUrlIsAllowed("/auction-buyer/getBuyers"));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewAllBuyers);
