import { connect } from "react-redux";
import ViewSeller from "./component";
import instance, { BUYER_SELLER_URL } from "../../../../../api/config";
import { saveSellerTokenResponse } from "../actions";
import ls from "local-storage";

import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../common/ReduxBased/LoadingSpinner/actions";

const mapStateToProps = (state) => ({
  sellerTokenRes: state.SELLER.data.sellerTokenRes,
  fetchTokenStatus: state.SELLER.data.fetchTokenStatus,
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchToken: () => {
    // let addsellerEmail= ls.get('addsellerEmail');
    let username = ls.get("username");

    instance
      .get(BUYER_SELLER_URL + "login/fetchToken?&userId=" + username)
      .then((res) => {
        console.log(res, "res token status");
        dispatch(saveSellerTokenResponse(res, res.data.description));
      })
      .catch((err) => {});
  },
  deleteSellerHandler: (id) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(
        true,
        "Deleting Seller Profile",
        "small"
      )
    );
    instance
      .delete(BUYER_SELLER_URL + "auction-seller/delete/" + id)
      .then((res) => {
        dispatch(handleLoadingSpinnerClose());
        window.location.reload();
      })
      .catch((err) => {
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ViewSeller);
