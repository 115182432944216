import {
    SAVE_ALL_LANGUAGES_HANDLER,
    CREATE_LANGUAGE_PAGE_TEXT_CHANGE_HANDLER
} from "./actions";

export const userLanguagesReducer = (state = [], action) => {
    switch (action.type) {

        case SAVE_ALL_LANGUAGES_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    langauges: [...action.payload]
                }
            }
        }

        case CREATE_LANGUAGE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    languageForm: {
                        ...state.forms.languageForm,
                        fields: {
                            ...state.forms.languageForm.fields,
                            [action.key]: {
                                ...state.forms.languageForm.fields[action.key],
                                value:action.payload
                            }
                        }
                    }
                }
            };
        }
        
        default: {
            return {
                ...state
            };
        }
    }
}