import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";
import ls from 'local-storage';


class CreateBidingIncrement extends Component {

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.getBiddingProfileIDs();
    }

    SubmitHandler = () => {
        this.props.submitBiddingIncrements();
    }
    render() {

        const {
            classes,
            textFieldChangeHandler,
            selectFieldChangeHandler,
            fields,
            error,
            isAllowed
        } = this.props;
        
        return (
            ls.get('AclGetEventAlloweddata') === true &&
            isAllowed === 'allowed' ? <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>Create a New Bidding Profile Increment</p>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>
                           <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.ProfileId.label}</span>
                                <ReactSelectValidator
                                    id={fields.ProfileId.name}
                                    name={fields.ProfileId.name}
                                    value={fields.ProfileId.value}
                                    placeholder={fields.ProfileId.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.ProfileId.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    isSearchable={true}
                                    options={fields.ProfileId.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.FirstIncrement.label}</span>
                                <CustomTextValidator
                                    id={fields.FirstIncrement.name}
                                    fullWidth={true}
                                    type="number"
                                    placeholder={fields.FirstIncrement.placeholder}
                                    name={fields.FirstIncrement.name}
                                    value={fields.FirstIncrement.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.FirstIncrement.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.SecondIncrement.label}</span>
                                <CustomTextValidator
                                    id={fields.SecondIncrement.name}
                                    fullWidth={true}
                                    type="number"
                                    placeholder={fields.SecondIncrement.placeholder}
                                    name={fields.SecondIncrement.name}
                                    value={fields.SecondIncrement.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.SecondIncrement.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.StartBiddingPrice.label}</span>
                                <CustomTextValidator
                                    id={fields.StartBiddingPrice.name}
                                    fullWidth={true}
                                    type="number"
                                    placeholder={fields.StartBiddingPrice.placeholder}
                                    name={fields.StartBiddingPrice.name}
                                    value={fields.StartBiddingPrice.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.StartBiddingPrice.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.EndBiddingPrice.label}</span>
                                <CustomTextValidator
                                    id={fields.EndBiddingPrice.name}
                                    fullWidth={true}
                                    type="number"
                                    placeholder={fields.EndBiddingPrice.placeholder}
                                    name={fields.EndBiddingPrice.name}
                                    value={fields.EndBiddingPrice.value}
                                    onChange={value => {
                                        textFieldChangeHandler(
                                            fields.EndBiddingPrice.name,
                                            value
                                        );
                                    }}
                                    className="textfield"
                                />
                                </div>
                            </Grid>

                            

                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Add</Button>
                            </Grid>
                            {
                                error ? <span className={classes.error}>{error}</span> : null
                            }
                        </Grid>
                    </ValidatorForm>
                </div>
            </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(CreateBidingIncrement);