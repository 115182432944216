import instance, { USER_URL } from "../../../../api/config";

export const ACCOUNT_PAGE_TEXT_CHANGE_HANDLER =
  "ACCOUNT_PAGE_TEXT_CHANGE_HANDLER";
export const BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER =
  "BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER";
export const ACCOUNT_PAGE_SELECT_CHANGE_HANDLER =
  "ACCOUNT_PAGE_SELECT_CHANGE_HANDLER";
export const ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER =
  "ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER";
export const SET_UPDATE_ACCOUNT_PROFILE = "SET_UPDATE_ACCOUNT_PROFILE";
export const ACCOUNT_EDIT_TEXT_CHANGE_HANDLER =
  "ACCOUNT_EDIT_TEXT_CHANGE_HANDLER";

export const PROFILE_SAVE_ALL_ACCOUNTS_PROFILES =
  "PROFILE_SAVE_ALL_ACCOUNTS_PROFILES";

export const textFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: ACCOUNT_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const textFieldChangeHandler_I = (name, value) => (dispatch) => {
  dispatch({
    type: BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const selectFieldChangeHandler = (name, value) => (dispatch) => {
  dispatch({
    type: ACCOUNT_PAGE_SELECT_CHANGE_HANDLER,
    payload: value,
    key: name,
  });
};

export const saveAllAccounts = (value) => (dispatch) => {
  let newArray = value.map((item) => {
    return [
      item.accountId,
      item.firstName,
      item.lastName,
      item.coreAccountType,
      item.createdDate,
      String(item.isActive),
      String(item.isDeleted),
    ];
  });
  dispatch({
    type: PROFILE_SAVE_ALL_ACCOUNTS_PROFILES,
    payload: newArray,
  });
};

export const saveAccountTypeIds = (value) => (dispatch) => {
  let newValue = value.map((item) => {
    return { value: item.id, label: item.coreAccountType };
  });
  dispatch({
    type: ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER,
    payload: [...newValue],
  });
};

export const editAccountChangeHandler = (value, name) => (dispatch) => {
  dispatch({
    type: ACCOUNT_EDIT_TEXT_CHANGE_HANDLER,
    payloadFirstName: name[1],
    payloadLastName: name[2],
    payloadToken: name[4],
    key: value,
  });
};

export const textUpdateAccount = (name, value) => (dispatch) => {
  dispatch({
    type: SET_UPDATE_ACCOUNT_PROFILE,
    payload: value,
    key: name,
  });
};

export const UpdateAccountProfile = (id, data) => (dispatch) => {
  let updatedData = {
    ...data,
    id,
  };
  instance
    .put(USER_URL + "account-management/updateAccount/", updatedData)
    .then((res) => {
      window.location.replace("/user/all-accounts");
    })
    .catch((error) => {});
};
