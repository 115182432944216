import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import instance, { EVENT_URL } from "../../../../../../api/config";
import { NavLink } from "react-router-dom";

class ViewItems extends Component {
  state = {
    alltimelines: [],
  };

  componentDidMount = () => {
    instance
      .get(
        EVENT_URL +
          "auction-event/auction-event-items/" +
          this.props.currentId +
          "?languageCode=en"
      )
      .then((res) => {
        let newArray = res.data.data.map((item) => {
          return [
            item.id,
            item.itemName,
            String(item.heroItem),
            item.itemEbayIdentifier,
            item.publishingTime,
            String(item.topItem),
          ];
        });
        this.setState({
          alltimelines: [...newArray],
        });
      })
      .catch((err) => {});
  };

  viewTimelines = (value) => {};

  render() {
    const { classes } = this.props;

    const columns = [
      "ID",
      "Title",
      "Hero Item",
      "Item Ebay Identifier",
      "Publishing Time",
      "Top Item",
    ];

    const options = {};

    return (
      <>
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>View all Items</p>
            <NavLink to={"/user/create-items"} className={classes.createlink}>
              Create Item
            </NavLink>
            <MUIDataTable
              title={"Item Details"}
              data={this.state.alltimelines}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ViewItems);
