import React, { Component } from "react";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import moment from "moment";
import Alert from "@material-ui/lab/Alert";
import TextEditor from "../../../../common/TextEditor/component";

class EditEvent extends Component {
  state = {
    languageName: [],
  };

  componentDidMount = () => {
    this.props.getBiddingIncIDs();
    this.props.getTimeLineProfileIDs();
  };

  SubmitHandler = () => {
    const { editFields } = this.props;
    this.props.UpdateEventProfile(editFields.editDataId);
  };

  imageFileChangeHandler = (e) => {
    if (e.target.files) {
      /* Get files in array form */

      let formData = new FormData();

      formData.append("event-teaser-image", e.target.files[0]);

      this.props.UploadTeaserImg(formData);
    }
  };

  render() {
    const {
      classes,
      textFieldChangeHandler,
      selectFieldChangeHandler,
      updateEventResponseMsg,
      eventUpdateStatus,
      editFields,
      updateteaserImageUploadUrl,
      fields,
    } = this.props;

    const langHandleChange = async (event) => {
      const {
        target: { value },
      } = event;

      this.setState({
        languageName:
          (await typeof value) === "string" ? value.split(",") : value,
      });

      const { languageName } = this.state;

      const languageCodeId1 =
        languageName[0] === "en" ||
        languageName[1] === "en" ||
        languageName[2] === "en"
          ? { countryCode: "en" }
          : null;
      const languageCodeId2 =
        languageName[0] === "de" ||
        languageName[1] === "de" ||
        languageName[2] === "de"
          ? { countryCode: "de" }
          : null;
      const languageCodeId3 =
        languageName[0] === "fr" ||
        languageName[1] === "fr" ||
        languageName[2] === "fr"
          ? { countryCode: "fr" }
          : null;

      const languageCodeId = [
        languageCodeId1,
        languageCodeId2,
        languageCodeId3,
      ];

      const newlanguageCodeId = languageCodeId.filter((a) => a);

      selectFieldChangeHandler(fields.EventLanguages.name, newlanguageCodeId);
    };

    const { languageName } = this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return (
      <>
        <div className={classes.editmainrapper}>
          <div className={classes.editfieldswrapper}>
            <p>Edit Event {editFields.editDataId}</p>
            <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventName.label}</span>
                    <CustomTextValidator
                      id={fields.EventName.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.EventName.placeholder}
                      name={fields.EventName.name}
                      value={fields.EventName.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.EventName.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventHeadline.label}</span>
                    <CustomTextValidator
                      id={fields.EventHeadline.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.EventHeadline.placeholder}
                      name={fields.EventHeadline.name}
                      value={fields.EventHeadline.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.EventHeadline.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="fieldwrapper">
                    <span>{fields.EventDescription.label}</span>
                    {fields.EventDescription.value && (
                      <TextEditor
                        onChange={textFieldChangeHandler}
                        fieldName={fields.EventDescription.name}
                        value={fields.EventDescription.value}
                      />
                    )}
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MainEvent.label}</span>
                    <ReactSelectValidator
                      id={fields.MainEvent.name}
                      name={fields.MainEvent.name}
                      value={fields.MainEvent.value}
                      placeholder={fields.MainEvent.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.MainEvent.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.MainEvent.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.BiddingBeforeEvent.label}</span>
                    <ReactSelectValidator
                      id={fields.BiddingBeforeEvent.name}
                      name={fields.BiddingBeforeEvent.name}
                      value={fields.BiddingBeforeEvent.value}
                      placeholder={fields.BiddingBeforeEvent.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.BiddingBeforeEvent.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.BiddingBeforeEvent.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.StartingTime.label}</span>
                    <CustomTextValidator
                      id={fields.StartingTime.name}
                      validations={true}
                      fullWidth={true}
                      type="datetime-local"
                      placeholder={fields.StartingTime.placeholder}
                      name={fields.StartingTime.name}
                      value={moment(fields.StartingTime.value).format(
                        "YYYY-MM-DDTHH:mm"
                      )}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.StartingTime.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.PublishingTime.label}</span>
                    <CustomTextValidator
                      id={fields.PublishingTime.name}
                      validations={true}
                      fullWidth={true}
                      type="datetime-local"
                      placeholder={fields.PublishingTime.placeholder}
                      name={fields.PublishingTime.name}
                      value={moment(fields.PublishingTime.value).format(
                        "YYYY-MM-DDTHH:mm"
                      )}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.PublishingTime.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.LiveStream.label}</span>
                    <ReactSelectValidator
                      id={fields.LiveStream.name}
                      name={fields.LiveStream.name}
                      value={fields.LiveStream.value}
                      placeholder={fields.LiveStream.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.LiveStream.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.LiveStream.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.SellingEvent.label}</span>
                    <ReactSelectValidator
                      id={fields.SellingEvent.name}
                      name={fields.SellingEvent.name}
                      value={fields.SellingEvent.value}
                      placeholder={fields.SellingEvent.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.SellingEvent.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.SellingEvent.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventAuctionTimeFrame.label}</span>
                    <CustomTextValidator
                      id={fields.EventAuctionTimeFrame.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.EventAuctionTimeFrame.placeholder}
                      name={fields.EventAuctionTimeFrame.name}
                      value={fields.EventAuctionTimeFrame.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.EventAuctionTimeFrame.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.LiveStreamEmbeddedCode.label}</span>
                    <CustomTextValidator
                      id={fields.LiveStreamEmbeddedCode.name}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.LiveStreamEmbeddedCode.placeholder}
                      name={fields.LiveStreamEmbeddedCode.name}
                      value={fields.LiveStreamEmbeddedCode.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.LiveStreamEmbeddedCode.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                {/*<Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.LiveChatEmbeddedCode.label}</span>
                    <CustomTextValidator
                      id={fields.LiveChatEmbeddedCode.name}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.LiveChatEmbeddedCode.placeholder}
                      name={fields.LiveChatEmbeddedCode.name}
                      value={fields.LiveChatEmbeddedCode.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.LiveChatEmbeddedCode.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>*/}

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.ParallelAuctions.label}</span>
                    <CustomTextValidator
                      id={fields.ParallelAuctions.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.ParallelAuctions.placeholder}
                      name={fields.ParallelAuctions.name}
                      value={fields.ParallelAuctions.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.ParallelAuctions.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.BiddingIncProfileId.label}</span>
                    <ReactSelectValidator
                      id={fields.BiddingIncProfileId.name}
                      name={fields.BiddingIncProfileId.name}
                      value={fields.BiddingIncProfileId.value}
                      placeholder={fields.BiddingIncProfileId.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.BiddingIncProfileId.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      isSearchable={true}
                      options={fields.BiddingIncProfileId.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventTimelineProfileId.label}</span>
                    <ReactSelectValidator
                      id={fields.EventTimelineProfileId.name}
                      name={fields.EventTimelineProfileId.name}
                      value={fields.EventTimelineProfileId.value}
                      placeholder={fields.EventTimelineProfileId.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.EventTimelineProfileId.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      isSearchable={true}
                      options={fields.EventTimelineProfileId.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MaximumItemsCount.label}</span>
                    <CustomTextValidator
                      id={fields.MaximumItemsCount.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.MaximumItemsCount.placeholder}
                      name={fields.MaximumItemsCount.name}
                      value={fields.MaximumItemsCount.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.MaximumItemsCount.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MinimumItemsCount.label}</span>
                    <CustomTextValidator
                      id={fields.MinimumItemsCount.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.MinimumItemsCount.placeholder}
                      name={fields.MinimumItemsCount.name}
                      value={fields.MinimumItemsCount.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.MinimumItemsCount.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.BreakBetween.label}</span>
                    <CustomTextValidator
                      id={fields.BreakBetween.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.BreakBetween.placeholder}
                      name={fields.BreakBetween.name}
                      value={fields.BreakBetween.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.BreakBetween.name, value);
                      }}
                      className="textfield"
                      validators={["required", "minNumber:5", "maxNumber:180"]}
                      errorMessages={[
                        "This field is required",
                        "Time of breaks after each item can only be 5-180 seconds.",
                        "Time of breaks after each item can only be 5-180 seconds.",
                      ]}
                    />
                  </div>
                </Grid>

                {/* <Grid item xs={12} md={6}>
                               <div className="fieldwrapper">
                                <span>{fields.Language.label}</span>
                                <ReactSelectValidator
                                    id={fields.Language.name}
                                    name={fields.Language.name}
                                    value={fields.Language.value}
                                    placeholder={fields.Language.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.Language.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    options={fields.Language.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid> */}

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>Allow Country Codes</span>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id={fields.EventLanguages.name}
                      placeholder={fields.EventLanguages.placeholder}
                      multiple
                      value={languageName}
                      name={fields.EventLanguages.name}
                      validations={true}
                      fullWidth={true}
                      onChange={langHandleChange}
                      input={<OutlinedInput id="Language Code" />}
                      displayEmpty={true}
                      renderValue={(selected) =>
                        selected == "" ? (
                          <div style={{ color: "gray" }}>
                            Select Country Codes
                          </div>
                        ) : (
                          selected.join(", ")
                        )
                      }
                      MenuProps={MenuProps}
                      className="selectfield select"
                      style={{ border: "none", boxShadow: "none" }}
                    >
                      {fields.EventLanguages.options.map((name) => (
                        <MenuItem key={name.name} value={name.name}>
                          <Checkbox
                            checked={languageName.indexOf(name.name) > -1}
                          />
                          <ListItemText primary={name.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <label htmlFor="myfile">Update Teaser Image</label>
                    <input
                      type="file"
                      encType="multipart/form-data"
                      id="myfile"
                      name="myfile"
                      className="imageFileChangeHandler"
                      onChange={this.imageFileChangeHandler}
                    />
                    <img
                      className="teaserUpdateimage"
                      src={
                        updateteaserImageUploadUrl === ""
                          ? fields.UpdateTeaserImage.value
                          : updateteaserImageUploadUrl
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="submitbtn"
                    type="submit"
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
              {updateEventResponseMsg ? (
                <Alert severity="error">{eventUpdateStatus}</Alert>
              ) : null}
            </ValidatorForm>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(EditEvent);
