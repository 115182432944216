import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, Grid } from "@material-ui/core";
// import ViewAllIncrements from "./AllIncrements/container";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import { ValidatorForm } from "react-material-ui-form-validator";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import ls from 'local-storage';



class ViewAllAccountType extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null
    }

    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id
        })
    }

    handleClick = (event,dataValue) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteProfileHandler(this.state.currentId)
    }

    SubmitSearchHandler = () => {
        this.props.submitSearch();
    }
    
    SubmitHandler = () => {
        // const {allFilter} = this.props;
        this.props.SubmitHandler();
    }

    componentDidMount = () => {
        // this.props.fetchDataIfNeeded();
        this.props.getAccountTypeIDs();
        this.props.getAppIDs();
    }
    render() {

        const {
            classes,
            selectFieldChangeHandler,
            fields,
            allFilter
        } = this.props;
        
        const columns = ["ID", "Action Name", "Action URL", "App Name", "ACL / Permission"];
        return (
            <>
                <div className={classes.mainrapper}>
                <div className={classes.fieldswrapper}>
                    <p>View All Permissions</p>

                    <ValidatorForm
                        onSubmit={this.SubmitSearchHandler}
                        autoComplete="off"
                    >
                       <Grid container>

                            <Grid item xs={12} md={4}>
                               <div className="fieldwrapper">
                                <span>{fields.AccountType.label}</span>
                                <ReactSelectValidator
                                    id={fields.AccountType.name}
                                    name={fields.AccountType.name}
                                    value={fields.AccountType.value}
                                    placeholder={fields.AccountType.placeholder}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.AccountType.name,
                                            value
                                        );
                                    }}
                                    isSearchable={true}
                                    options={fields.AccountType.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={4}>
                               <div className="fieldwrapper">
                                <span>{fields.App.label}</span>
                                <ReactSelectValidator
                                    id={fields.App.name}
                                    name={fields.App.name}
                                    value={fields.App.value}
                                    placeholder={fields.App.placeholder}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.App.name,
                                            value
                                        );
                                    }}
                                    isSearchable={true}
                                    options={fields.App.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={4}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit" disabled={(fields.AccountType.value[0] || fields.App.value[0] ) ? false : true}>Filter</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>

                    <ValidatorForm
                        onSubmit={this.SubmitHandler}
                        autoComplete="off"
                    >
                       <Grid container>

                            <Grid item xs={12} md={4}>
                               <div className="fieldwrapper">
                                <span>{fields.AllowDeny.label}</span>
                                <ReactSelectValidator
                                    id={fields.AllowDeny.name}
                                    name={fields.AllowDeny.name}
                                    value={fields.AllowDeny.value}
                                    placeholder={fields.AllowDeny.placeholder}
                                    validations={true}
                                    fullWidth={true}
                                    onChange={value => {
                                        selectFieldChangeHandler(
                                            fields.AllowDeny.name,
                                            value
                                        );
                                    }}
                                    validators={["required"]}
                                    errorMessages={["Required"]}
                                    options={fields.AllowDeny.options}
                                    className="selectfield"
                                />
                                </div>
                            </Grid>

                            <Grid item xs={4}>
                                <Button variant="contained" color="primary" className="submitbtn" type="submit">Send</Button>
                            </Grid>
                        </Grid>
                    </ValidatorForm>

                </div>
            </div>

            {
                this.state.show &&
                <AlertDialog
                    maxWidth={"lg"}
                    open={this.state.show}
                    showTitle={false}
                    hideApplyButton={true}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialog(false)}
                    closeAlertDialog={() => this.showAlertDialog(false)}
                    applyAlertDialog={() => this.showAlertDialog(false)}
                />
            }

                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                        <p>View all Permissions</p>
                        <MUIDataTable 
                            title={"ACL"} 
                            data={allFilter} 
                            columns={columns} 
                        />
                    </div>
                </div>

            </>

            
        )
    }


    
}
export default withStyles(styles)(ViewAllAccountType);