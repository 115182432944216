import {
    BIDDING_PROFILE_PAGE_TEXT_CHANGE_HANDLER,
    BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER,
    PROFILE_SAVE_ALL_BIDDING_PROFILES,
    BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER,
    BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    BIDDING_EDIT_TEXT_CHANGE_HANDLER,
    VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER,
    BIDDING_EDIT_ID_HANDLER,
    BIDDING_INCREMENT_PROFILE_ERROR_HANDLER,
    BIDDING_DELETE_SESSION_SAVE,
    BIDDING_VIEW_UPDATE_SESSION_SAVE
} from "./actions";

export const userbiddingReducer = (state = [], action) => {
    switch (action.type) {

        case BIDDING_PROFILE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingProfileForm: {
                        ...state.forms.biddingProfileForm,
                        fields: {
                            ...state.forms.biddingProfileForm.fields,
                            [action.key]: {
                                ...state.forms.biddingProfileForm.fields[action.key],
                                value:action.payload
                            }
                        }
                    }
                }
            };
        }
        case BIDDING_EDIT_ID_HANDLER: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    editDataId: action.key,
                }
            }
        }

        case BIDDING_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingProfileForm: {
                        ...state.forms.biddingProfileForm,
                        fields: {
                            ...state.forms.biddingProfileForm.fields,
                            [action.key]: {
                                ...state.forms.biddingProfileForm.fields[action.key],
                                value:action.payload
                            }
                        }
                    }
                }
            };
        }

        case VIEW_BIDDING_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editViewData: {
                    ...state.editData,
                    editDataId: action.key,
                    editCurrentDataId: action.viewCurrentKey,
                    editstartBiddingPrice:action.payloadstartBiddingPrice,
                    editendBiddingPrice:action.payloadendBiddingPrice,
                    editfirstBiddingIncrement:action.payloadfirstBiddingIncrement,
                    editsecondBiddingIncrement:action.payloadsecondBiddingIncrement,
                }
            }
        }


        case BIDDING_PROFILE_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingIncrementForm: {
                        ...state.forms.biddingIncrementForm,
                        fields: {
                            ...state.forms.biddingIncrementForm.fields,
                            [action.key]: {
                                ...state.forms.biddingIncrementForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case PROFILE_SAVE_ALL_BIDDING_PROFILES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allBiddingProfiles: [...action.payload]
                }
            }
        }

        case BIDDING_PROFILE_PAGE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingIncrementForm: {
                        ...state.forms.biddingIncrementForm,
                        fields: {
                            ...state.forms.biddingIncrementForm.fields,
                            ProfileId: {
                                ...state.forms.biddingIncrementForm.fields.ProfileId,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingIncrementForm: {
                        ...state.forms.biddingIncrementForm,
                        fields: {
                            ...state.forms.biddingIncrementForm.fields,
                            [action.key]: {
                                ...state.forms.biddingIncrementForm.fields[action.key],
                                value:action.payload
                            }
                        }
                    }
                }
            };
        }

        case BIDDING_INCREMENT_PROFILE_ERROR_HANDLER: {
            return {
                ...state,
                UISettings: {
                    ...state.UISettings,
                    biddingIncrementError: action.payload
                }
            }
        }

        case BIDDING_DELETE_SESSION_SAVE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    deleteBiddingResponseMsg: action.payload,
                    biddingDeleteStatus: action.payloadDeleteBiddingMessage
                }
            }
        }

        case BIDDING_VIEW_UPDATE_SESSION_SAVE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    updateViewBiddingResponseMsg: action.payload,
                    biddingViewUpdateStatus: action.payloadUpdateViewBiddingMessage
                }
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}