import React, { Component } from "react";
// import ReactCrop, {
//   centerCrop,
//   makeAspectCrop,
//   PixelCrop,
// } from "react-image-crop";
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@material-ui/core";
import CustomTextValidator from "../../../../common/ReduxBased/CustomTextValidator/component";
import ReactSelectValidator from "../../../../common/ReduxBased/CustomReactSelectValidator/ReactSelectValidator";
import { ValidatorForm } from "react-material-ui-form-validator";
import ErrorMessage from "../../common/ErrorMessage/component";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ls from "local-storage";
import Alert from "@material-ui/lab/Alert";
import TextEditor from "../../../../common/TextEditor/component";
import instance, { EVENT_URL } from "../../../../../api/config";

class CreateAuctionEvent extends Component {
  state = {
    minDate: new Date(),
    teaserimage: "",
    files: [],
    languageName: [],
    // aspectRatio: 16 / 9,
    // completedCrop: undefined,
    // crop: {
    //   unit: "%",
    //   width: 100,
    //   height: 100,
    //   x: 0,
    //   y: 0,
    // },
  };

  componentDidMount = () => {
    this.props.checkIfUrlIsAllowed();
    this.props.getEventProfileIDs();
    this.props.getBiddingIncIDs();
    this.props.getLanguages();
  };

  SubmitHandler = () => {
    this.props.submitEvent();
  };

  // centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
  //   return centerCrop(
  //     makeAspectCrop(
  //       {
  //         unit: "%",
  //         width: 90,
  //       },
  //       aspect,
  //       mediaWidth,
  //       mediaHeight
  //     ),
  //     mediaWidth,
  //     mediaHeight
  //   );
  // };

  imageFileChangeHandler = (e) => {
    this.setState({ files: [...this.state.files, ...e.target.files] })
    this.props.UploadTeaserImg(this.state.files);

    if (e.target.files) {
      console.log(e.target.files[0]);
      console.log(window.URL.createObjectURL(e.target.files[0]))
      this.setState({ teaserimage: window.URL.createObjectURL(e.target.files[0]) });
      /* Get files in array form */

      let formData = new FormData();

      formData.append("event-teaser-image", e.target.files[0]);

      this.props.UploadTeaserImg(formData);
    }

    // if (e.target.files && e.target.files.length > 0) {
    //   this.setCrop(undefined); // Makes crop preview update between images.
    //   const reader = new FileReader();
    //   reader.addEventListener("load", () =>
    //     this.setState({ teaserimage: reader.result?.toString() || "" })
    //   );
    //   reader.readAsDataURL(e.target.files[0]);
    // }
  };

  // setCrop = (value) => {
  //   this.setState({ crop: value });
  // };

  // setCompletedCrop = (value) => {
  //   this.setState({ completedCrop: value });
  // };

  // onImageLoad = (e) => {
  //   if (this.state.aspectRatio) {
  //     const { width, height } = e.currentTarget;
  //     this.setCrop(
  //       this.centerAspectCrop(width, height, this.state.aspectRatio)
  //     );
  //   }
  // };

  // onDownloadCropClick = async (e) => {
  //   e.preventDefault();
  //   const { crop, completedCrop, teaserimage } = this.state;
  //   const canvas = document.createElement("canvas");
  //   const cropImagePreview = document.getElementById("cropImagePreview");

  //   console.log(
  //     cropImagePreview.naturalHeight,
  //     cropImagePreview.width,
  //     cropImagePreview.naturalHeight,
  //     cropImagePreview.height
  //   );

  //   const scaleX = cropImagePreview.naturalWidth / cropImagePreview.width;
  //   const scaleY = cropImagePreview.naturalHeight / cropImagePreview.height;
  //   canvas.width = completedCrop.width;
  //   canvas.height = completedCrop.height;
  //   const ctx = canvas.getContext("2d");

  //   const pixelRatio = window.devicePixelRatio;
  //   canvas.width = completedCrop.width * pixelRatio;
  //   canvas.height = completedCrop.height * pixelRatio;
  //   ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  //   ctx.imageSmoothingQuality = "high";

  //   console.log(
  //     cropImagePreview,
  //     completedCrop.x * scaleX,
  //     completedCrop.y * scaleY,
  //     completedCrop.width * scaleX,
  //     completedCrop.height * scaleY,
  //     0,
  //     0,
  //     completedCrop.width,
  //     completedCrop.height
  //   );

  //   ctx.drawImage(
  //     cropImagePreview,
  //     completedCrop.x * scaleX,
  //     completedCrop.y * scaleY,
  //     completedCrop.width * scaleX,
  //     completedCrop.height * scaleY,
  //     0,
  //     0,
  //     completedCrop.width,
  //     completedCrop.height
  //   );

  //   // const canvasContainer = document.getElementById("canvasContainer");
  //   // canvasContainer.appendChild(canvas);

  //   canvas.toBlob((blob) => {
  //     if (!blob) {
  //       throw new Error('Failed to create blob')
  //     }
  //     console.log({blob});
  //     // const blobURL = URL.createObjectURL(blob);
  //     // const anchor = document.createElement('a');
  //     // anchor.href = blobURL;
  //     // anchor.target = "_blank";
  //     // anchor.click();
  //     let formData = new FormData();

  //     formData.append("event-teaser-image", blob);

  //     this.props.UploadTeaserImg(formData);
  //   })
  // };

  render() {
    const {
      classes,
      textFieldChangeHandler,
      selectFieldChangeHandler,
      fields,
      isAllowed,
      teaserImageUploadUrl,
      error,
    } = this.props;

    const langHandleChange = async (event) => {
      const {
        target: { value },
      } = event;

      this.setState({
        languageName:
          (await typeof value) === "string" ? value.split(",") : value,
      });

      const { languageName } = this.state;

      const languageCodeId1 =
        languageName[0] === "en" ||
        languageName[1] === "en" ||
        languageName[2] === "en"
          ? { countryCode: "en" }
          : null;
      const languageCodeId2 =
        languageName[0] === "de" ||
        languageName[1] === "de" ||
        languageName[2] === "de"
          ? { countryCode: "de" }
          : null;
      const languageCodeId3 =
        languageName[0] === "fr" ||
        languageName[1] === "fr" ||
        languageName[2] === "fr"
          ? { countryCode: "fr" }
          : null;

      const languageCodeId = [
        languageCodeId1,
        languageCodeId2,
        languageCodeId3,
      ];

      const newlanguageCodeId = languageCodeId.filter((a) => a);

      selectFieldChangeHandler(fields.EventLanguages.name, newlanguageCodeId);
    };

    const { languageName, crop, teaserimage, aspectRatio, completedCrop } =
      this.state;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };

    return ls.get("AclGetEventAlloweddata") === true &&
      isAllowed === "allowed" ? (
      <>
        <div className={classes.mainrapper}>
          <div className={classes.fieldswrapper}>
            <p>Create a New Auction Event</p>

            <ValidatorForm onSubmit={this.SubmitHandler} autoComplete="off">
              <Grid container>
                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventName.label}</span>
                    <CustomTextValidator
                      id={fields.EventName.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.EventName.placeholder}
                      name={fields.EventName.name}
                      value={fields.EventName.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.EventName.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventHeadline.label}</span>
                    <CustomTextValidator
                      id={fields.EventHeadline.name}
                      validations={true}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.EventHeadline.placeholder}
                      name={fields.EventHeadline.name}
                      value={fields.EventHeadline.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.EventHeadline.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={12}>
                  <div className="fieldwrapper">
                    <span>{fields.EventDescription.label}</span>
                    <TextEditor
                      fieldName={fields.EventDescription.name}
                      onChange={textFieldChangeHandler}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MainEvent.label}</span>
                    <ReactSelectValidator
                      id={fields.MainEvent.name}
                      name={fields.MainEvent.name}
                      value={fields.MainEvent.value}
                      placeholder={fields.MainEvent.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.MainEvent.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.MainEvent.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.BiddingBeforeEvent.label}</span>
                    <ReactSelectValidator
                      id={fields.BiddingBeforeEvent.name}
                      name={fields.BiddingBeforeEvent.name}
                      value={fields.BiddingBeforeEvent.value}
                      placeholder={fields.BiddingBeforeEvent.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.BiddingBeforeEvent.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.BiddingBeforeEvent.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.PublishingTime.label}</span>
                    <CustomTextValidator
                      id={fields.PublishingTime.name}
                      validations={true}
                      fullWidth={true}
                      type="datetime-local"
                      placeholder={fields.PublishingTime.placeholder}
                      name={fields.PublishingTime.name}
                      value={fields.PublishingTime.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.PublishingTime.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.StartingTime.label}</span>
                    <CustomTextValidator
                      id={fields.StartingTime.name}
                      validations={true}
                      fullWidth={true}
                      type="datetime-local"
                      placeholder={fields.StartingTime.placeholder}
                      name={fields.StartingTime.name}
                      value={fields.StartingTime.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.StartingTime.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.LiveStream.label}</span>
                    <ReactSelectValidator
                      id={fields.LiveStream.name}
                      name={fields.LiveStream.name}
                      value={fields.LiveStream.value}
                      placeholder={fields.LiveStream.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.LiveStream.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.LiveStream.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.SellingEvent.label}</span>
                    <ReactSelectValidator
                      id={fields.SellingEvent.name}
                      name={fields.SellingEvent.name}
                      value={fields.SellingEvent.value}
                      placeholder={fields.SellingEvent.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.SellingEvent.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.SellingEvent.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventAuctionTimeFrame.label}</span>
                    <CustomTextValidator
                      id={fields.EventAuctionTimeFrame.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.EventAuctionTimeFrame.placeholder}
                      name={fields.EventAuctionTimeFrame.name}
                      value={fields.EventAuctionTimeFrame.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.EventAuctionTimeFrame.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.LiveStreamEmbeddedCode.label}</span>
                    <CustomTextValidator
                      id={fields.LiveStreamEmbeddedCode.name}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.LiveStreamEmbeddedCode.placeholder}
                      name={fields.LiveStreamEmbeddedCode.name}
                      value={fields.LiveStreamEmbeddedCode.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.LiveStreamEmbeddedCode.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                {/*<Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.LiveChatEmbeddedCode.label}</span>
                    <CustomTextValidator
                      id={fields.LiveChatEmbeddedCode.name}
                      fullWidth={true}
                      type="text"
                      placeholder={fields.LiveChatEmbeddedCode.placeholder}
                      name={fields.LiveChatEmbeddedCode.name}
                      value={fields.LiveChatEmbeddedCode.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.LiveChatEmbeddedCode.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>*/}

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.ParallelAuctions.label}</span>
                    <CustomTextValidator
                      id={fields.ParallelAuctions.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.ParallelAuctions.placeholder}
                      name={fields.ParallelAuctions.name}
                      value={fields.ParallelAuctions.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.ParallelAuctions.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.BiddingIncProfileId.label}</span>
                    <ReactSelectValidator
                      id={fields.BiddingIncProfileId.name}
                      name={fields.BiddingIncProfileId.name}
                      value={fields.BiddingIncProfileId.value}
                      placeholder={fields.BiddingIncProfileId.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.BiddingIncProfileId.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      isSearchable={true}
                      options={fields.BiddingIncProfileId.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.EventTimelineProfileId.label}</span>
                    <ReactSelectValidator
                      id={fields.EventTimelineProfileId.name}
                      name={fields.EventTimelineProfileId.name}
                      value={fields.EventTimelineProfileId.value}
                      placeholder={fields.EventTimelineProfileId.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(
                          fields.EventTimelineProfileId.name,
                          value
                        );
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      isSearchable={true}
                      options={fields.EventTimelineProfileId.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MaximumItemsCount.label}</span>
                    <CustomTextValidator
                      id={fields.MaximumItemsCount.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.MaximumItemsCount.placeholder}
                      name={fields.MaximumItemsCount.name}
                      value={fields.MaximumItemsCount.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.MaximumItemsCount.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.MinimumItemsCount.label}</span>
                    <CustomTextValidator
                      id={fields.MinimumItemsCount.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.MinimumItemsCount.placeholder}
                      name={fields.MinimumItemsCount.name}
                      value={fields.MinimumItemsCount.value}
                      onChange={(value) => {
                        textFieldChangeHandler(
                          fields.MinimumItemsCount.name,
                          value
                        );
                      }}
                      className="textfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.BreakBetween.label}</span>
                    <CustomTextValidator
                      id={fields.BreakBetween.name}
                      fullWidth={true}
                      type="number"
                      placeholder={fields.BreakBetween.placeholder}
                      name={fields.BreakBetween.name}
                      value={fields.BreakBetween.value}
                      onChange={(value) => {
                        textFieldChangeHandler(fields.BreakBetween.name, value);
                      }}
                      className="textfield"
                      validators={["required", "minNumber:5", "maxNumber:180"]}
                      errorMessages={[
                        "This field is required",
                        "Time of breaks after each item can only be 5-180 seconds.",
                        "Time of breaks after each item can only be 5-180 seconds.",
                      ]}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>{fields.Language.label}</span>
                    <ReactSelectValidator
                      id={fields.Language.name}
                      name={fields.Language.name}
                      value={fields.Language.value}
                      placeholder={fields.Language.placeholder}
                      validations={true}
                      fullWidth={true}
                      onChange={(value) => {
                        selectFieldChangeHandler(fields.Language.name, value);
                      }}
                      validators={["required"]}
                      errorMessages={["Required"]}
                      options={fields.Language.options}
                      className="selectfield"
                    />
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <span>Allow Country Codes</span>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id={fields.EventLanguages.name}
                      placeholder={fields.EventLanguages.placeholder}
                      multiple
                      value={languageName}
                      name={fields.EventLanguages.name}
                      validations={true}
                      fullWidth={true}
                      onChange={langHandleChange}
                      input={<OutlinedInput id="Language Code" />}
                      displayEmpty={true}
                      renderValue={(selected) =>
                        selected == "" ? (
                          <div style={{ color: "gray" }}>
                            Select Country Codes
                          </div>
                        ) : (
                          selected.join(", ")
                        )
                      }
                      MenuProps={MenuProps}
                      className="selectfield select"
                      style={{
                        height: "58px",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      {fields.EventLanguages.options.map((name) => (
                        <MenuItem key={name.name} value={name.name}>
                          <Checkbox
                            checked={languageName.indexOf(name.name) > -1}
                          />
                          <ListItemText primary={name.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>

                <Grid item xs={12} md={6}>
                  <div className="fieldwrapper">
                    <label htmlFor="myfile">Upload Teaser Image</label>

                    <input
                      type="file"
                      // multiple
                      encType="multipart/form-data"
                      id="myfile"
                      name="myfile"
                      accept="image/*"
                      className="imageFileChangeHandler"
                      onChange={this.imageFileChangeHandler}
                    />

                    <img
                      className="teaserUpdateimage"
                      src={teaserImageUploadUrl ? teaserImageUploadUrl : ""}
                    />
                  </div>
                </Grid>

                {/* {teaserimage && (
                  <div className={classes.cropWrapper}>
                    <ReactCrop
                      crop={crop}
                      onChange={this.setCrop}
                      aspect={aspectRatio}
                      onComplete={this.setCompletedCrop}
                    >
                      <img
                        id="cropImagePreview"
                        src={teaserimage}
                        onLoad={this.onImageLoad}
                      />
                    </ReactCrop>

                    <Button
                      variant="contained"
                      color="primary"
                      className="cropBtn"
                      onClick={this.onDownloadCropClick}
                    >
                      Crop
                    </Button>
                  </div>
                )} */}


                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="submitbtn"
                    type="submit"
                  >
                    Add
                  </Button>
                </Grid>
                {error ? <Alert severity="error">{error}</Alert> : null}
              </Grid>
            </ValidatorForm>
          </div>
        </div>
      </>
    ) : (
      <>
        {" "}
        {isAllowed === "loading" ? (
          <ErrorMessage message="loading" />
        ) : (
          <ErrorMessage message="You are not allowed to see this page" />
        )}
      </>
    );
  }
}

export default withStyles(styles)(CreateAuctionEvent);
