import React, {Component} from 'react';
import { styles } from "./styles";
import { withStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { Button, Popover } from "@material-ui/core";
import AlertDialog from "../../../../common/ReduxBased/confirmationDialog/container";
import ViewAllTimelines from "./ViewTimelines/container";
import EditTimeline from "../EditTimeline/container"
import ErrorMessage from "../../common/ErrorMessage/component";
import Alert from '@material-ui/lab/Alert';
import ls from 'local-storage';


class ViewAllTimelineProfiles extends Component {

    state = {
        show: false,
        showDelete: false,
        currentId: "",
        anchorEl: null,
        editData:'',
        showEdit:false
    }

    handleClick = (event,dataValue,rowData) => {
        this.setState({
            anchorEl: event.currentTarget,
            currentId:dataValue,
            editData:rowData
        })
    };

    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    }
    
    showAlertDialog = (value, id) => {
        this.setState({
            show: value,
            currentId: id,
        })
    }

    showAlertDialogClose = (value) => {
        this.setState({
            showEdit:value
        })
    }
    

    showAlertDialogDelete = (value, id) => {
        this.setState({
            showDelete: value,
            currentId: id
        })
    }

    editHandler = (show,id,editData) => {
        this.props.editTimelineChangeHandler(id,editData)
        this.setState({
            showEdit:show
        })
    }

    deleteProfileHandler = () => {
        this.setState({
            showDelete: false
        })
        this.props.deleteProfileHandler(this.state.currentId)
    }

    componentDidMount = () => {
        this.props.checkIfUrlIsAllowed();
        this.props.fetchDataIfNeeded();
    }

    showEditTimelineDialog = (value) => {
        this.setState({
            showEdit: false
        })
    }

    render() {

        const {
            classes,
            allProfiles,
            isAllowed,
            deleteTimelineResponseMsg,
            timelineDeleteStatus
        } = this.props;
        const {currentId,editData}= this.state;
        
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined
        
        const columns = ["ID", "Title", "Is Dynamic", "Bid Count For Next Stage", "Bid Count For Previous Stage",
            {
                name: "Action",
                label: "Action",
                options: {
                    customBodyRender: (props, data) => {
                        let rowDataValue = data.rowData[0]
                        let rowData = data.rowData

                        return (
                            <div>
                                <Button aria-describedby={id} variant="contained" color="primary" onClick={(e)=>this.handleClick(e,rowDataValue,rowData)}>
                                    Actions
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                    }}
                                >
                                    <div className={classes.actions}>
                                        <Button color="primary" variant="outlined" onClick={() => this.showAlertDialog(true, currentId)}>View Timelines</Button>
                                        <Button color="primary" variant="outlined" onClick={()=>this.editHandler(true, currentId, editData)}>Edit</Button>
                                        <Button color="primary" variant="outlined" onClick={() => this.showAlertDialogDelete(true, currentId)}>Delete</Button>
                                    </div>
                                </Popover>
                            </div>
                        )
                    },
                }
            }
        ];

        const options = {
            downloadOptions: { filename: 'TimelineProfiles.csv', separator: ";", filterOptions: { useDisplayedRowsOnly: true, useDisplayedColumnsOnly: true } }
        };

        return (
            ls.get('AclGetEventAlloweddata') === true &&
            isAllowed === 'allowed' ? <>
            {
                this.state.show &&
                <AlertDialog
                    maxWidth={"lg"}
                    open={this.state.show}
                    showTitle={false}
                    hideApplyButton={true}
                    message={<ViewAllTimelines currentId={this.state.currentId} showAlertDialog={this.showAlertDialog} />}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialog(false)}
                    closeAlertDialog={() => this.showAlertDialog(false)}
                    applyAlertDialog={() => this.showAlertDialog(false)}
                />
            }

            {
                this.state.showDelete &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showDelete}
                    showTitle={true}
                    title={"Delete Profile"}
                    message={"Are you sure want to delete this profile?"}
                    cancelButtonText={"Close"}
                    cancelAlertDialog={() => this.showAlertDialogDelete(false)}
                    closeAlertDialog={() => this.showAlertDialogDelete(false)}
                    applyAlertDialog={() => this.deleteProfileHandler()}
                />
            }
            {
                this.state.showEdit &&
                <AlertDialog
                    maxWidth={"sm"}
                    open={this.state.showEdit}
                    showTitle={true}
                    title={"Edit Timeline"}
                    message={<EditTimeline/>}
                    cancelButtonText={"Close"}
                    hideApplyButton={true}
                    cancelAlertDialog={() => this.showEditTimelineDialog(false)}
                    closeAlertDialog={() => this.showEditTimelineDialog(false)}
                    applyAlertDialog={() => this.showEditTimelineDialog(false)}

                />
            }
                <div className={classes.mainrapper}>
                    <div className={classes.fieldswrapper}>
                        <p>View all Timeline Profiles</p>
                        <MUIDataTable 
                            title={"Timeline Profiles"} 
                            data={allProfiles} 
                            columns={columns} 
                            options={options}
                        />
                        <br></br>
                        {deleteTimelineResponseMsg ? 
                    <Alert severity="error">{timelineDeleteStatus}</Alert>
                    :null}
                    </div>
                </div>
            </> : <> {
                isAllowed === 'loading' ? <ErrorMessage message='loading' /> : <ErrorMessage message='You are not allowed to see this page' />
            }</>
        )
    }
}

export default withStyles(styles)(ViewAllTimelineProfiles);