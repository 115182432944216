import {loginReducer} from "../components/Login/loginReducer";
import {confirmationDialogReducer} from "../components/common/ReduxBased/confirmationDialog/confirmationDialogReducer";
import {loadingSpinnerReducer} from "../components/common/ReduxBased/LoadingSpinner/loadingSpinnerReducer";
import {userDashboardReducer} from "../components/OmniConnect/Dashboard/userDashboardReducer";
import {userbiddingReducer} from "../components/OmniConnect/Dashboard/BidingProfile/userBiddingReducer";
import {auctionEventReducer} from "../components/OmniConnect/Dashboard/Event/auctionEventReducer";
import {useritemReducer} from "../components/OmniConnect/Dashboard/ItemsController/userItemReducer";
import {userAccountTypeReducer} from "../components/OmniConnect/Dashboard/AccountType/userAccountTypeReducer";
import {userAccountUserReducer} from "../components/OmniConnect/Dashboard/UserAccount/userAccountUserReducer";
import {userAccountsReducer} from "../components/OmniConnect/Dashboard/Accounts/userAccountsReducer";
import {userPermissionsReducer} from "../components/OmniConnect/Dashboard/Permissions/userPermissionsReducer";
import {buyersReducer} from "../components/OmniConnect/Dashboard/Buyers/buyersReducer"
import {sellerReducer} from "../components/OmniConnect/Dashboard/SellerManagement/sellerReducer"
import {dashboardLandingReducer} from "../components/OmniConnect/Dashboard/Landing/dashboardLandingReducer";
import {userLanguagesReducer} from "../components/OmniConnect/Dashboard/Languages/userLanguagesReducer";
import {allReportsReducer} from "../components/OmniConnect/Dashboard/ReportsManagement/allReportsReducer";

import {combineReducers} from "redux";
import reduceReducers from "reduce-reducers";

const rootReducer = reduceReducers(
    combineReducers({
        LOGIN: loginReducer,
        ALERT_DIALOG: confirmationDialogReducer,
        LOADING_SPINNER: loadingSpinnerReducer,
        DASHBOARD: userDashboardReducer,
        BIDDING: userbiddingReducer,
        CONTROLLER: useritemReducer,
        EVENT: auctionEventReducer,
        ACCOUNT_TYPE : userAccountTypeReducer,
        ACCOUNT_USER : userAccountUserReducer,
        ACCOUNTS : userAccountsReducer,
        PERMISSIONS : userPermissionsReducer,
        BUYERS : buyersReducer,
        SELLER : sellerReducer,
        LANDING_DASHBOARD: dashboardLandingReducer,
        USER_LANGUAGES: userLanguagesReducer,
        REPORTS: allReportsReducer
    }),
);

export default rootReducer;
