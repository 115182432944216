export const styles = theme => ({
    mainrapper: {
        width: "100%",
    },
    fieldswrapper: {
        background: "white",
        padding: 38,
        margin: "0px 38px",
        "& p": {
            color: "#707070",
        },
        "& th": {
            width: "117px"
        },
    },
    createlink: {
        background: "#074880",
        color: "white",
        textDecoration: "none",
        padding: 8,
        marginBottom: 13,
        display: "block",
        width: 178,
        textAlign: "center"
    },
    actions: {
        display: "block",
        padding: 7,
        minWidth: 100,
        "& button": {
            display: "block",
            width: "100%",
            marginBottom: 11
        }
    },
    filterbox: {
        display: "flex",
        alignItems: "baseline",
        "& .fieldwrapper": {
            marginLeft: 35,
            width: 260,
        }
    }
});