export const PERMISSIONS = {
    data: {
        allAccountTypeProfiles: [],
        allFilterData: []
    },
    forms: {
        AccountTypeForm: {
            fields: {
                AccountType: {
                    label: "Account Type",
                    name: "AccountType",
                    placeholder: "Select Account Type",
                    value: [],
                    options: []
                },
                App: {
                    label: "App",
                    name: "App",
                    placeholder: "Select App",
                    value: [],
                    options: []
                },
                AllowDeny: {
                    label: "Select ACL / Permissions",
                    name: "AllowDeny",
                    placeholder: "Select ACL / Permissions",
                    value: [],
                    options: [
                        {value: 1, label: "All Allowed", title: "true"},
                        {value: 0, label: "All Deny", title: "false"}
                    ]
                },
            }
        },
        biddingIncrementForm: {
            fields: {
                ProfileId: {
                    label: "Bidding Profile Id",
                    name: "ProfileId",
                    placeholder: "Bidding Profile Id",
                    value: [],
                    options: []
                },
                EndBiddingPrice: {
                    label: "End Bidding Price",
                    name: "EndBiddingPrice",
                    placeholder: "End Bidding Price",
                    value: ""
                },
                FirstIncrement: {
                    label: "First Bidding Increment",
                    name: "FirstIncrement",
                    placeholder: "First Bidding Increment",
                    value: ""
                },
                SecondIncrement: {
                    label: "Second Bidding Increment",
                    name: "SecondIncrement",
                    placeholder: "Second Bidding Increment",
                    value: ""
                },
                StartBiddingPrice: {
                    label: "Start Bidding Price",
                    name: "StartBiddingPrice",
                    placeholder: "Start Bidding Price",
                    value: ""
                },
            }
        },
    },
    UISettings: {
        isAllowed: 'loading'
    }
}
export default PERMISSIONS;