import { connect } from "react-redux";
import EditViewBidding from "./component";

import {
  textFieldChangeHandler_I,
  saveUpdateViewResponseMsg,
} from "../../../actions";

import instance, { EVENT_URL } from "../../../../../../../api/config";
import {
  handleLoadingSpinnerClose,
  handleLoadingSpinnerOpenWithOptions,
} from "../../../../../../common/ReduxBased/LoadingSpinner/actions";
import ls from "local-storage";

const mapStateToProps = (state) => ({
  fields: state.BIDDING.forms.biddingIncrementForm.fields,
  editViewData: state.BIDDING.editViewData,
  updateViewBiddingResponseMsg: state.BIDDING.data.updateViewBiddingResponseMsg,
  biddingViewUpdateStatus: state.BIDDING.data.biddingViewUpdateStatus,
});

const mapDispatchToProps = (dispatch, props) => ({
  textFieldChangeHandler: (name, e) => {
    if (typeof e.value !== "string") {
      e.value = "";
    }
    dispatch(textFieldChangeHandler_I(name, e.value));
  },
  submitViewBiddingIncrements: (profID, ID, data) => {
    dispatch(
      handleLoadingSpinnerOpenWithOptions(true, "Saving Increments", "small")
    );

    const requestEditUrl =
      EVENT_URL +
      "bidding-increment-profile/bidding-increment/update/" +
      ID +
      "/" +
      profID;
    instance
      .put(requestEditUrl, data)
      .then((res) => {
        window.location.replace("/user/all-profilebidding");
        dispatch(handleLoadingSpinnerClose());
      })
      .catch((error) => {
        dispatch(
          saveUpdateViewResponseMsg(profID, error.response.data.description)
        );
        dispatch(handleLoadingSpinnerClose());
      });
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(EditViewBidding);
