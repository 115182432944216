import {
    DASHBOARD_LANDING_COUNT_HANDLER,
    DASHBOARD_LANDING_DATA_COUNT_HANDLER
} from "./actions";

export const dashboardLandingReducer = (state = [], action) => {
    switch (action.type) {

        case DASHBOARD_LANDING_COUNT_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    dashboardCount: action.payload
                }
            }
        }

        case DASHBOARD_LANDING_DATA_COUNT_HANDLER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    dashboardDataCount: action.payload
                }
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}