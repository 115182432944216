import {
    ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER,
    ACCOUNT_TYPE_PAGE_SELECT_CHANGE_HANDLER,
    PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES,
    ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER,
    BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER,
    ACCOUNT_TYPE_EDIT_TEXT_CHANGE_HANDLER
} from "./actions";

export const userAccountTypeReducer = (state = [], action) => {
    switch (action.type) {

        case ACCOUNT_TYPE_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountTypeForm: {
                        ...state.forms.AccountTypeForm,
                        fields: {
                            ...state.forms.AccountTypeForm.fields,
                            [action.key]: {
                                ...state.forms.AccountTypeForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case ACCOUNT_TYPE_PAGE_SELECT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountTypeForm: {
                        ...state.forms.AccountTypeForm,
                        fields: {
                            ...state.forms.AccountTypeForm.fields,
                            [action.key]: {
                                ...state.forms.AccountTypeForm.fields[action.key],
                                value: (action.payload === null) ? [] : [action.payload]
                            }
                        }
                    }
                }
            }
        }

        case PROFILE_SAVE_ALL_ACCOUNT_TYPE_PROFILES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    allAccountTypeProfiles: [...action.payload]
                }
            }
        }

        case ACCOUNT_TYPE_PAGE_SAVE_ID_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    AccountTypeForm: {
                        ...state.forms.AccountTypeForm,
                        fields: {
                            ...state.forms.AccountTypeForm.fields,
                            AccountType: {
                                ...state.forms.AccountTypeForm.fields.AccountType,
                                options: (action.payload === null) ? [] : [...action.payload]
                            }
                        }
                    }
                }
            }
        }

        case BIDDING_PROFILE_INCREMENT_PAGE_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                forms: {
                    ...state.forms,
                    biddingIncrementForm: {
                        ...state.forms.biddingIncrementForm,
                        fields: {
                            ...state.forms.biddingIncrementForm.fields,
                            [action.key]: {
                                ...state.forms.biddingIncrementForm.fields[action.key],
                                ...action.payload
                            }
                        }
                    }
                }
            };
        }

        case ACCOUNT_TYPE_EDIT_TEXT_CHANGE_HANDLER: {
            return {
                ...state,
                editData: {
                    ...state.editData,
                    editDataId: action.key,
                    editDataAccountType:action.payloadAccountType,
                    editDataAccountGroup:action.payloadAccountGroup
                }
            }
        }
        default: {
            return {
                ...state
            };
        }
    }
}